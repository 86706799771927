import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import Component from './Timestamp.jsx'

export default Node.create({
    name: 'Timestamp',
    group: 'block',
    content: 'inline*',
  
    parseHTML() {
      return [
        {
          tag: 'timestamp',
        },
      ]
    },
  
    // addKeyboardShortcuts() {
    //   return {
    //     'Mod-Enter': () => {
    //       return this.editor.chain().insertContentAt(this.editor.state.selection.head, { type: this.type.name }).focus().run()
    //     },
    //   }
    // },
  
    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes), 0]
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(Component)
    },
  })