import React, {Component, useState} from 'react';
import {Button, Alert, Col, Form, Row, Stack} from "react-bootstrap";
import {registerUserWithEmailAndPassword, signInWithFacebook, signInWithGoogle, createContact} from "../Services/Firebase";
import GoogleButton from 'react-google-button'
import { BsGoogle, BsArrowRight } from "react-icons/bs";
import {Mini} from "../Elements/Typography";
import {Logo} from "../Elements/Logo";
import { useToast } from '../Contexts/ToastProvider';
import { useParams, useSearchParams } from 'react-router-dom';

const SignUp = () => (
    <Row className="d-flex p-0 m-0 vh-100 bg-white justify-content-center">
        <Col lg={4} className="d-none d-md-block "/>
        <Col lg={4} className="flex-grow-1 d-flex justify-content-center">
            <Stack gap={4} className="mx-5 my-auto">
                <Logo className={"d-flex flex-row justify-content-start align-items-start text-decoration-none p-0"}/>
                <div>
                    <h3><b>Create your account</b></h3>
                    <Mini className={"text-muted"}>You'll be up and running in 2 minutes</Mini>
                </div>
                <Button className="tall-btn" onClick={() => { signInWithGoogle() }}>
                    <BsGoogle/>&ensp; Continue with Google
                </Button>
                <Mini className={"text-muted mx-auto"}>Or register with email</Mini>
                <EmailSignUp/>
                <Mini className={"text-muted mx-auto"}>Already have an account? <a href={"/Login"}>Login</a></Mini>
            </Stack>
        </Col>
        <Col lg={4} className="d-none d-md-block p-5"/>
    </Row>
);

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const EmailSignUp = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const emailSearchParam = searchParams.get('email');
    const firstNameSearchParam = searchParams.get('firstName');
    const lastNameSearchParam = searchParams.get('lastName');

    const [firstName, setFirstName] = useState(firstNameSearchParam || '');
    const [lastName, setLastName] = useState(lastNameSearchParam || '');

    const [email, setEmail] = useState(emailSearchParam || '');
    const [password, setPassword] =  useState('');
    const [show, setShow] = useState(false);
    const [errorMessage, onErrorMessage] =  useState('');

    const [subscribeEmail, setSubscribeEmail] = useState(false);
    const { UpdateToast } = useToast();

    const onSubmit = (e) => {
        e.preventDefault();

        if(firstName === '' || lastName == ''){
            Error('Please enter your full name')
            return;
        }

        const formData = {
            firstName : firstName,
            lastName : lastName,
            email : email,
            password : password,
            subscribeEmail : subscribeEmail
        };

        if(validateEmail(email)){
            if(subscribeEmail){
                UpdateToast(registerUserWithEmailAndPassword(formData), {loading:'Creating account...', success: 'Account created', error: 'Something went wrong'});
            }
        }
    };

    return(
        <Form>
            <Mini>Your name</Mini>
            <Stack gap={2} direction={"horizontal"}>
                <Form.Group  className={"w-100"} controlId="signUpFirstName">
                    <Form.Control type="text" value={firstName} placeholder="First name" onChange={e => setFirstName(e.target.value)}/>
                </Form.Group>
                <Form.Group  className={"w-100"} controlId="signUpLastName">
                    <Form.Control type="text" value={lastName} placeholder="Last name" onChange={e => setLastName(e.target.value)}/>
                </Form.Group>
            </Stack>
            <br/>
            <Form.Group controlId="loginEmail">
                <Mini>Email address</Mini>
                <Form.Control type="email" value={email} placeholder={"name@example.com"} onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginPassword">
                <Mini>Password</Mini>
                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Form.Group>
                <Form.Check 
                type={'checkbox'} 
                label={<Mini>Subscribe to Qanda's emails, you'll be the first to know about our latest news, resources, and promotions</Mini>} 
                onChange={() => setSubscribeEmail(!subscribeEmail)}
                className='d-flex flex-row justify-content-between align-items-center'/>
            </Form.Group>
            <Form.Group>
                {show ? (
                    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        {errorMessage}
                    </Alert>
                ):(<></>)}
            </Form.Group>
            <br/>
            <Form.Group className="d-flex align-items-center justify-content-center">
                <Button onClick={onSubmit} type="submit" className="tall-btn w-100">Create account &ensp; <BsArrowRight/></Button>
            </Form.Group>
        </Form>
    );
};

SignUp.propTypes = {};

SignUp.defaultProps = {};

export default SignUp;
