import {Link} from "react-router-dom";
import React from "react";
import {BsLayoutSidebar, BsBookshelf, BsBookmark, BsFolder, BsArrowLeftShort, BsArrowLeft, BsChatSquareText, BsJournalText, BsStars, BsPeople, BsTags} from "react-icons/bs";
import {UpgradeButton} from '../../Buttons';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { CiCoinInsert, CiCreditCard1, CiSettings, CiUsb, CiUser } from "react-icons/ci";
import { SiMiro } from "react-icons/si";
import { useAuth } from "../../../Contexts/AuthContext";
import { useUser } from "../../../Hooks/useUser";
import { useToast } from "../../../Contexts/ToastProvider";
import { openCustomerPortal } from "../../../Services/Firebase";
import "./SideBar.scss";
import { AvatarHeader } from "./Header";
// Get the direct path to the logo.svg
import logo from "./Logo.svg";

function Project(props){
    const { collapseSidebar, collapsed } = useProSidebar();

    React.useEffect(() => {
        if (props.startCollapsed) {
          // If startCollapsed is true, collapse the sidebar initially
          collapseSidebar();
        }
      }, [props.startCollapsed, collapseSidebar]);
      
    return(
        <Sidebar className="border-end fixed-top vh-100" breakPoint={'md'}>
            <div className="h-100 d-flex flex-column justify-content-between bg-white">
                <div>
                    <Menu closeOnClick 
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                          if (level === 0)
                          return {
                            color: '#212529',
                            margin: "0.25em .5em",
                            height: "2.5em",
                            borderRadius: "5px",
                            fontWeight: '500',
                            transition: 'background-color 0.2s', // Add a smooth transition for hover effect
                                '&:hover': {
                                backgroundColor: '#f2f6fc', // Set the hover background color
                                },
                          };
                        },
                      }}>
                        <div className={"d-flex flex-row justify-content-between align-items-end p-3 me-1 border-gray border-1 border-start-0 border-top-0 border-end-0"}>
                            {!collapsed?
                            <div className="d-flex align-items-end">
                                &ensp;
                                <Link to={'/Projects'}>
                                    <img
                                        src={logo}
                                        width="100%"
                                        height="20"
                                        alt="Qanda"
                                    />
                                </Link>
                            </div>:<></>}
                            <BsLayoutSidebar onClick={() => collapseSidebar()} className={"text-primary cursor-pointer mx-3"}/>
                        </div>
                        
                        {/* <MenuItem icon={<BsJournalText/>} component={<Link to={'/Guides/'+props.folderId} />}>
                            Guides
                        </MenuItem> */}
                        <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                                <MenuItem icon={<BsBookshelf/>} component={<Link to={'/Projects/'+props.folderId} />}>
                                    Shelf
                                </MenuItem>
                                <MenuItem icon={<BsTags />} component={<Link to={'/Projects/'+props.folderId+'/tags'} />}>
                                    Tags
                                </MenuItem>
                                <MenuItem icon={<BsStars/>} component={<Link to={'/Insights/'+props.folderId} />}>
                                    Insights
                                </MenuItem>
                                <MenuItem icon={<SiMiro/>} component={<Link to={'/Board/'+props.folderId} />}>
                                    Board
                                </MenuItem>
                            </div>
                        </div>

                    </Menu>
                </div>
                <div className="m-3">
                    {/* <UpgradeButton className={'w-100'} size={'md'} showText={!collapsed}/> */}
                    {/* <MenuItem icon={<BsPeople />} component={<Link to={'/Members/'+props.folderId} />}>
                        Members
                    </MenuItem> */}
                    {/* <hr/> */}
                    {/* <div className={"d-flex flex-row justify-content-center w-100"}> */}
                        {/* <Avatar/> */}
                    {/* </div> */}
                    {/* {!collapsed && <PlanUsage />} */}
                </div>
            </div>
        </Sidebar>
    )
}

export const ProjectSidebar = (props) => {
    return (
        <div className="d-flex w-100">
            <Project folderId={props.folderId} startCollapsed={props.collapse}/>
            <div className={'bg-light flex-grow-1 overflow-hidden'}>
                {props.children}
            </div>
        </div>
    )
};

function Settings(props){
    const { collapseSidebar, collapsed } = useProSidebar();
    const { isSubscribed, currentUser } = useAuth();
    const { UpdateToast } = useToast();

    const Checkout = async () => {
        UpdateToast(openCustomerPortal(), {loading: 'Loading payment portal', success: 'Successfully loaded payment portal', error: 'Error opening payment portal'});
    };

    return(
        <Sidebar className="border-end fixed-top vh-100" breakPoint={'md'}>
            <div className="h-100 d-flex flex-column justify-content-between bg-white">
                <div>
                    <Menu 
                    menuItemStyles={{
                        button: ({ level, active, disabled }) => {
                        // only apply styles on first level elements of the tree
                          if (level === 0)
                          return {
                            color: '#1E43FF',
                            // backgroundColor: '#f2f6fc',
                            margin: "0.25em .5em",
                            borderRadius: "5px",
                            fontWeight: '500',
                            transition: 'background-color 0.2s', // Add a smooth transition for hover effect
                                '&:hover': {
                                backgroundColor: '#f2f6fc', // Set the hover background color
                                },
                          };
                        },
                      }}>
                        <div className={"d-flex flex-row justify-content-between align-items-end m-3 me-1"}>
                            {!collapsed?
                            <div className="d-flex align-items-end">
                                &ensp;
                                <Link to={'/Projects'}>
                                    <div className={"d-flex align-items-center text-primary"}><BsArrowLeft className=" d-none d-lg-block"/>&ensp;
                                        <img
                                            src="../Logo.svg"
                                            height="20"
                                            alt="Qanda Logo"
                                        />
                                    </div>
                                </Link>
                            </div>:<></>}
                            <BsLayoutSidebar onClick={() => collapseSidebar()} className={"text-primary cursor-pointer mx-3 d-none d-lg-block"}/>
                        </div>

                        <MenuItem icon={<CiSettings/>} component={<Link to={'/Settings'} />}>
                            Settings
                        </MenuItem>
                        <MenuItem icon={<CiUser/>} component={<Link to={'/Profile'} />}>
                            Profile
                        </MenuItem>
                        <MenuItem icon={<CiCoinInsert/>} component={<Link to={'/Plans'} />}>
                            Plans
                        </MenuItem>
                            {isSubscribed?
                            <MenuItem icon={<CiCreditCard1/>} onClick={Checkout}>
                                Billing
                            </MenuItem>
                            :
                            null
                        }
                    </Menu>
                </div>
            </div>
        </Sidebar>
    )
}

export const SettingsSidebar = (props) => {
    const { toggleSidebar } = useProSidebar();

    return (
        <div className="d-flex" id="wrapper">
            <Settings folderId={props.folderId}/>
            <div id="page-content-wrapper" className={'vh-100 bg-light d-flex flex-column'}>
                <AvatarHeader disableSearch showToggleSidebar/>
                <div className={'flex-grow-1 overflow-auto'}>
                    {props.children}
                </div>
            </div>
        </div>
    )
};