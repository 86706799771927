import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFolder, BsThreeDotsVertical, BsFillPencilFill, BsTrash } from 'react-icons/bs';
import { Card, Dropdown } from "react-bootstrap";
import { usePublicUser } from "../../Hooks/usePublicUser";
import { Avatar } from "../Auth/Authentication";
import { RenameFolderModal, DeleteFolderModal } from "../../Elements/Modals/Modals";
import { FadingHeading, Mini } from "../Typography";
import './Cards.scss';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { timeAgo } from "../../Hooks/useDate";

export const Project = ({ Folder, IsGridView }) => {
    console.log(Folder)
    const cardRef = useRef(null);
    let navigate = useNavigate();
    const { User } = usePublicUser(Folder.userId);

    const [showRename, setShowRename] = useState(false);
    const renameModal = () => setShowRename(!showRename);
    
    const [showDelete, setShowDelete] = useState(false);
    const deleteModal = () => setShowDelete(!showDelete);
    
    const onOpen = (e) => {
        e.preventDefault();
        navigate(`/Projects/${Folder.id}`, { CurrentFolder: Folder });
    };

    return (
      <>
      {/* Render the custom dropdown */}
      <ContextMenuTrigger id={"file_context_menu_"+Folder.id}>

        <Card className={"border border-1 border-gray shadow shadow-sm cursor-pointer"} variant={"white"} ref={cardRef}>
          <div className="d-flex py-3 px-4 flex-row align-items-center justify-content-between" onClick={(e) => onOpen(e)}>
            <div className="d-flex flex-row gap-5 h-100 align-items-center">
              <h6 className="p-0 m-0">
                {Folder.name}
              </h6>
              <div className="small text-muted">Uploaded {timeAgo(Folder?.createdAt)}</div>
            </div>
            <Avatar className="me-3" user={User} displayName />
          </div>
        </Card>

        </ContextMenuTrigger>
        <RenameFolderModal renameModal={renameModal} showRename={showRename} Folder={Folder} />
        <DeleteFolderModal Folder={Folder} show={showDelete} setShow={deleteModal} />

        <ContextMenu id={"file_context_menu_"+Folder.id}>
            <Dropdown show>
                <Dropdown.Menu style={{ border: "1px solid lightgray" }}>
                    <Dropdown.Item onClick={() => { renameModal() }}>
                        <BsFillPencilFill />&ensp;Rename
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => { deleteModal() }}>
                        <BsTrash />&ensp;Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ContextMenu>
      </>
    );
};