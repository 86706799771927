import React, {useContext, useState} from "react";
import toast, { Toaster } from 'react-hot-toast';

const ToastContext = React.createContext();

export function useToast() {
    return useContext(ToastContext)
}

export function ToastProvider({ children }) {
    const [toastOffset, SetToasterOffset] = useState(0);
    const duration = 4000;

    const Notify = (message, icon = null) => {
        toast(message, {
            duration: duration,

            // Styling
            style: {},
            className: '',

            // Custom Icon
            icon: icon,

            // Change colors of success/error/loading icon
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    };

    const Success = (message) => {
        toast.success(message, {
            duration: duration,

            // Styling
            style: {
                borderLeft: '5px solid #61d345',
                borderRadius: '0px 8px 8px 0px'
            },
            className: '',
        });
    };

    const Error = (message) => {
        toast.error(message, {
            duration: duration,

            // Styling
            style: {
                borderLeft: '5px solid #ff4b4b',
                borderRadius: '0px 8px 8px 0px'
            },
            className: '',
        });
    };

    const Loading = (message) => {
        toast.loading(message, {
            // Styling
            // Styling
            style: {
                borderLeft: '5px solid #616161',
                borderRadius: '0px 8px 8px 0px'
            },
            className: '',
        });
    };

    const UpdateToast = (promise, messages = {loading: 'Loading', success: 'Got the data', error: 'Error when fetching'}) => {
        toast.promise(promise, {
            loading: messages.loading,
            success: messages.success,
            error: messages.error,
        });
    };

    const CustomToast = (children, duration) => {
        console.log("click")
        toast.custom(children, {
            duration: duration,
        });
    };

    const value = {
        Notify,
        Success,
        Error,
        Loading,
        UpdateToast,
        CustomToast,
        SetToasterOffset
    };

    return (
        <ToastContext.Provider value={value}>
            {children}
            <Toaster position="bottom-center" style={{bottom: toastOffset}}/>
        </ToastContext.Provider>
    )
}
