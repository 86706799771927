import {Container, Dropdown, Nav, Navbar, Stack} from "react-bootstrap";
import {Logo} from "../../Logo";
import {Login, SignUp, AvatarButton} from "../../Auth/Authentication";
import React from "react";
import './NavBar.scss';
import { Link } from "react-router-dom";
import './Header.scss';
import { SearchInsightsPopup } from "../../../Pages/Insights"
import { SecondaryButton } from "../../Buttons";
import { BsArrowUpRight, BsLayoutSidebar, BsQuestionLg } from "react-icons/bs";
import { useState } from "react";
import { useProSidebar } from 'react-pro-sidebar';

export function Header() {
    return (
        <Navbar collapseOnSelect expand="lg" variant="light" fixed={"top"} className={"shadow-sm header"}>
            <Container className={"d-flex justify-content-between align-items-end mb-1 h-100"}>
                <Navbar.Brand className={"align-items-center d-flex flex-row"}>
                    <Logo/>
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav d-flex align-items-center h-100">
                    <div className="px-4">
                        <a href="https://qanda.canny.io/feedback" className="header-link">
                            Roadmap
                        </a>
                    </div>

                    <Nav.Link className="px-4">
                        <Link to="/Pricing" className="header-link">
                            Pricing
                        </Link>
                    </Nav.Link>

                    <Nav.Link className="px-4">
                        <Link to="/Contact" className="header-link">
                            Contact
                        </Link>
                    </Nav.Link>
                </Navbar.Collapse>
                <Stack gap={3} direction={"horizontal"} className="mt-1">
                    {/*<Button className={"btn-primary rounded-pill"} href={"#signUp"}>Join Waitlist&nbsp;<BsArrowRight/></Button>*/}

                    <Login/>
                    <SignUp/>
                </Stack>
            </Container>
        </Navbar>
    );
}

export const AvatarHeader = ({showSearch, disableSearch, showToggleSidebar}) => {
    const [show, setShow] = useState(false);
    const { toggleSidebar } = useProSidebar();

    const toggleShow = () => {
        setShow(!show);
    };

    return(
        <div className="d-flex flex-row justify-content-end align-items-center p-2 gap-2 bg-white border-gray border-start-0 border-end-0 border-top-0" style={{zIndex: 1020}}>
            {showToggleSidebar? 
                <SecondaryButton className="h-100 d-md-none"  onClick={() => toggleSidebar()}>
                    <BsLayoutSidebar className={"text-primary"}/>
                </SecondaryButton>
             : <></>}

            <div className={"w-100"}>
                {/* Search bar for whole folder */}
                {showSearch || !disableSearch? <SearchInsightsPopup/> : <></>}
            </div>
            <div className="d-none d-lg-block h-100">
            <div className="dropdown-center h-100">
                    <SecondaryButton className="h-100" onClick={() => toggleShow()}>
                        <BsQuestionLg/>
                    </SecondaryButton>
                    <Dropdown show={show}>
                        <Dropdown.Menu className="mt-1" style={{ border: "1px solid lightgray" }}>
                            <Dropdown.Item 
                                className="d-flex flex-row align-items-center justify-content-between"
                                href="https://qanda.canny.io/feedback">
                                Product roadmap&ensp;<BsArrowUpRight size={12}/>
                            </Dropdown.Item>
                            {/* <Dropdown.Item 
                                className="d-flex flex-row align-items-center justify-content-between"
                                target="_blank">
                                Request feature&ensp;<BsArrowUpRight size={12}/>
                            </Dropdown.Item> */}
                            <Dropdown.Item 
                                className="d-flex flex-row align-items-center justify-content-between"
                                target="_blank" 
                                href="https://join.slack.com/t/qandadesign/shared_invite/zt-1msw04j9y-8XdnOfI7ZqxtV32lurd4Qw">
                                Slack community&ensp;<BsArrowUpRight size={12}/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <AvatarButton/>
        </div>
    )
}