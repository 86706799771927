import { useReducer, useEffect } from "react";
import { database, functions } from "../Services/Firebase";
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useToast } from "../Contexts/ToastProvider";
import { useLocation, useParams } from "react-router-dom";
import { useFolder } from "./useFolder";
import { useAuth } from "../Contexts/AuthContext";

const ACTIONS = {
  SET_MEMBERS: "set-members",
  ADD_MEMBER: "add-member",
  REMOVE_MEMBER: "remove-member",
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_MEMBERS:
      return {
        members: payload.members,
      };
    case ACTIONS.ADD_MEMBER:
      return {
        members: [...state.members, payload.member],
      };
    case ACTIONS.REMOVE_MEMBER:
      return {
        members: state.members.filter((member) => member.id !== payload.memberId),
      };
    default:
      return state;
  }
}

export function useProjectMembers(projectId) {
  const { folderId } = useParams();
  const { currentUser } = useAuth();
  const { location = {} } = useLocation();
  const { CurrentFolder } = useFolder(folderId);
  const { Error } = useToast();

  const [state, dispatch] = useReducer(reducer, {
    members: [],
  });

  useEffect(() => {
    let projectRef = database.folders.doc(projectId).collection('members');

    if (!projectId) {
      dispatch({ type: ACTIONS.SET_MEMBERS, payload: { members: [] } });
      return;
    }

    const unsubscribeMembers = projectRef.onSnapshot(
      (querySnapshot) => {
        const members = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        dispatch({ type: ACTIONS.SET_MEMBERS, payload: { members } });
      },
      (error) => {
        console.error("Error fetching project members:", error);
      }
    );

    return () => {
      unsubscribeMembers();
    };
  }, [projectId]);

  const addMember = async (email) => {
    try {
      const public_user_from_email = httpsCallable(functions, 'public_user_from_email');
      const publicUser = await public_user_from_email({ email: email })
        .then((public_user) => public_user.data)
        .catch((error) => {
          // If not found show toast
          Error(error.message);
        });

      const { userId } = publicUser; // Assuming userId is a property of the public user document

      // Reject if the user is the same as the owner of the folder
      if(CurrentFolder.userId == userId){
        Error("You can't add yourself as a member");
        return;
      }
      
      // Reject is the userId is already in the members array
      if (state.members.find((member) => member.id === userId)) {
        Error("User is already a member");
        return;
      }

      // Add the user to the members)
      await database.folders.doc(projectId).collection('members').doc(userId).set({ userId: userId, role: "editor", addedBy: currentUser.uid });

      // dispatch({ type: ACTIONS.ADD_MEMBER, payload: { memberId } });
    } catch (error) {
      console.error("Error adding project member:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const removeMember = async (memberId) => {
    try {
      const projectRef = database.folders.doc(projectId).collection('members');
      const memberDoc = await getDoc(projectRef.doc(memberId));

      if (!memberDoc.exists()) {
        // If the user doesn't exist, reject the operation
        throw new Error("User not found in the project.");
      }

      // User exists, proceed with removing the member
      await deleteDoc(memberDoc.ref);

      // dispatch({ type: ACTIONS.REMOVE_MEMBER, payload: { memberId } });
    } catch (error) {
      console.error("Error removing project member:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  return { members: state.members, addMember, removeMember };
}
