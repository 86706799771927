import React, {useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import Loading from "../Elements/LoadingSpinner";
import {useFile} from "../Hooks/useFile";
import {useFolder} from "../Hooks/useFolder";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import PdfViewer from "../Elements/PdfViewer";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { useAuth } from "../Contexts/AuthContext";
import { TextEditorProvider } from "../Contexts/TextEditorContext";
import TextEditor from "../Elements/Input/TextEditor/Editor";
import { BsFile, BsFileEarmarkPdf, BsFolder, BsHouse } from "react-icons/bs";
import Breadcrumbs from "../Elements/Navigation/Breadcrumbs/Breadcrumbs";

function FileView() {
    const { fileId } = useParams();
    const location = useLocation();
    const [loadingFile, setLoadingFile] =  useState(true);
    const { CurrentFile } = useFile(fileId, null, setLoadingFile);
    const { CurrentFolder } = useFolder(CurrentFile.folderId);
    const { currentUser } = useAuth();

    const breadcrumbItems = [
        { text: 'Home', link: '/Projects', icon: <BsHouse /> },
        { text: CurrentFolder?.name, link: `/Projects/${CurrentFolder?.id}`, icon: <BsFolder /> },
        { text: CurrentFile?.name, icon: <BsFileEarmarkPdf /> },
    ];

    const FileRenderer = () => {
        if (!loadingFile && CurrentFile?.type === 'application/pdf' && CurrentFile?.url) {
            // Display the PDF viewer when the file type is PDF
            return (
                <>
                    <div className={"border-start-0 border-end-0 border-top-0 border-gray top-0 bg-white"}>
                        {/* Stuff such as names, titles and buttons */}
                        <Breadcrumbs items={breadcrumbItems} />
                    </div>
                    <div className={"flex-grow-1 h-100 d-flex flex-column"}>
                        <PdfViewer pdfUrl={CurrentFile.url} />
                    </div>
                </>
            )
        }
        if (!loadingFile && CurrentFile?.type === 'text/html') {
            return(
                <TextEditorProvider>
                    <TextEditor CurrentFile={CurrentFile}/>
                </TextEditorProvider>
            )
        }
        return null;
    }


    return (
    <ProjectSidebar folderId={CurrentFile.folderId}>
        <Loading loading={loadingFile} animation={"grow"}>
            {/*<PrimaryButton onClick={downloadFile}>Download</PrimaryButton>*/}
            {/*TODO: Add overview of projects when there are folders and link is /projects*/}
            {/*Header*/}
            <div className={"w-100 bg-white d-flex flex-column vh-100"}>
                <AvatarHeader showSearch/>
                {FileRenderer()}
            </div>
        </Loading>
    </ProjectSidebar>
    );
}

export default FileView;
