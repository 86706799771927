import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

import Insight from './Insight.jsx'

export default Node.create({
    name: 'insight',
    group: 'block',
    content: 'inline*',
  
    selectable: true,
    draggable: true,
    isolating: true,
    
    parseHTML() {
      return [
        {
          tag: 'insight',
        },
      ]
    },
  
    renderHTML({ HTMLAttributes }) {
      return ['insight', mergeAttributes(HTMLAttributes), 0]
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(Insight)
    },

    addCommands() {
      return {
        setInsight: (insertAt, text) => ({ commands }) => {
          return commands.insertContentAt(insertAt, [{
            type: 'insight',
            content: [
              {
                type: 'text',
                text: text,
              },
            ],
          },
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: ' ',
              },
            ],
          }]);
        },
      };
    },
  })