import { useEffect, useReducer } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { database } from "../Services/Firebase";
import { doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useAuth } from "../Contexts/AuthContext";
import { useToast } from "../Contexts/ToastProvider";

const ACTIONS = {
  SELECT_GUIDE: "select-guide",
  SELECT_FOLDER: "select-folder",
  // ... other actions
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SELECT_GUIDE:
      return {
        guideId: payload.guideId,
        Guide: payload.Guide,
        // ... other state properties
      };
    case ACTIONS.SELECT_FOLDER:
      return {
        folderId: payload.folderId,
        Guides: payload.guides,
        // ... other state properties
      };
    // ... other cases
    default:
      return state;
  }
}

export function useGuide(folderId = null, guideId = null) {
  const [state, dispatch] = useReducer(reducer, {
    Guide: null,
    Guides: [],
    // ... other initial state properties
  });

    const auth = useAuth(); // Get the authenticated user context
    const userId = auth.currentUser.uid;
    const { Error } = useToast();
    const navigate = useNavigate();

    async function getGuidesInFolderFromFirestore(folderId) {
        try {
        const guidesSnapshot = await database.guides
            .where("userId", "==", userId)
            .where("folderId", "==", folderId)
            .get();

        const guidesList = guidesSnapshot.docs.map(database.formatDoc)

        return guidesList;
        } catch (error) {
        Error("Error getting guides from Firestore");
        throw error;
        }
    }

    async function getGuideFromFirestore(guideId) {
        try {
            const guideRef = doc(database.guides, guideId);
            const guideDoc = await getDoc(guideRef);
        if (guideDoc.exists()) {
            return guideDoc.data();
        } else {
            throw new Error("Guide not found");
        }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    useEffect(() => {
        const loadGuides = async () => {
            if (folderId) {
              try {
                const guidesList = await getGuidesInFolderFromFirestore(folderId);
                dispatch({
                  type: ACTIONS.SELECT_FOLDER,
                  payload: { folderId: folderId, guides: guidesList },
                });
              } catch (error) {
                console.error("Error loading guides:", error);
              }
            } else if (guideId) {
              try {
                const loadedGuide = await getGuideFromFirestore(guideId);
                dispatch({
                  type: ACTIONS.SELECT_GUIDE,
                  payload: { Guide: {...loadedGuide, id: guideId } },
                });
              } catch (error) {
                console.error("Error loading guide:", error);
              }
            }
          };
      
          loadGuides();

        
    }, [guideId, folderId]);

  const createGuideWithLevels = async (folderId) => {
    try {
        const newGuide = {
            name: "New Guide",
            userId: userId, // Get the user's UID
            createdAt: database.getCurrentTimestamp(),
            folderId: folderId, // Add the folderId to the new guide
            content: [
            {
                question: "Interview question 1",
                indent: 0,
                metadata: {
                },
            },
            {
                question: "Subquestion 1",
                indent: 1,
                metadata: {
                },
            }, 
            { 
                question: "Tertiary question 1",
                indent: 2,
                metadata: {
                },
            }
            ]
          };

        // Save newGuide to Firestore
        const docRef = await database.guides.add(newGuide);

        // Navigate to the new guide using history.push()
        navigate(`/Guide/${docRef.id}`); // Replace with your route structure
    } catch (error) {
      Error("Error while creating guide");
    }
  };

  const updateGuideContent = async (guideId, newContent) => {
    try {
      let timeoutId;
  
      const saveAfterTimeout = () => {
        timeoutId = setTimeout(async () => {
          try {
            const guideRef = doc(database.guides, guideId);
            console.log("Save")
            await setDoc(guideRef, { content: newContent }, { merge: true });
          } catch (error) {
            console.error("Error updating guide content:", error);
            Error("Error updating guide content");
          }
        }, 3000); // Set the autosave delay to 3 seconds
      };
  
      // Clear existing timeout if any
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      console.error("TODO: This savefunction is just postponing but not autosaving as expected")
      saveAfterTimeout();
    } catch (error) {
      console.error("Error setting autosave timeout:", error);
    }
  };
  


  return {
    ...state,
    createGuideWithLevels,
    updateGuideContent,
  };
}