import {Container, Row, Col} from 'react-bootstrap';
import Feature from './Features';
import { CiCoins1, CiCompass1, CiDiscount1, CiMedal, CiMemoPad, CiPenpot, CiVial } from 'react-icons/ci';
import { SiMiro } from 'react-icons/si';
import Indicator from '../../Elements/Indicator';
import { BsStars } from 'react-icons/bs';

const InfoSection = ({ }) => {    return (
        <section className={"min-vh-100"}>
        <Container className='py-5 g-5'>
            <Row className={"g-3 pt-5"}>
                <Col lg={6}>
                    <Row className='px-5 pb-5'><img
                        src=
                            "../Images/LandingPage/Interview.png"

                        alt={"Transcriber"}
                        className={"rounded shadow p-0 unselectable"}
                    />
                    </Row>
                </Col>
                <Col lg={6} className={"px-5 d-flex flex-column justify-content-center"}>
                    <div>
                        <div className="text-purple d-flex flex-row align-items-center pb-2">
                            <BsStars/>
                            &ensp;
                            <div>
                                A-grade transcription
                            </div>
                        </div>
                        <h1 className='d-inline text-black'><b>Never transcribe again</b></h1>&emsp;<div className='d-inline'><Indicator>Upcoming</Indicator> </div>
                        
                        <div className='text-gray pt-2'>
                            <p>
                            Get accurate and reliable transcriptions for all your audio content, without the hassle of manual transcription. Our automated transcription service supports over 72 languages and is completely free.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Container className="py-3">
                <Row className="g-3 pb-5">
                    <Feature
                        icon={<CiCompass1 size={32} color="primary" />}
                        title="Multilingual Transcription"
                        description="Transcribe content in over 72 languages accurately and effortlessly with our automated service."
                    />
                    <Feature
                        icon={<CiMemoPad size={32} color="primary" />}
                        title="Flawless Transcriptions"
                        description="Experience flawless and reliable transcriptions that capture every detail of your audio content."
                    />                       
                    <Feature
                        icon={<CiCoins1 size={32} color="primary" />}
                        title="Free Transcriptions"
                        description="Enjoy the convenience of our automated transcription service at no cost, making it accessible for all your needs."
                    />  
                </Row>
            </Container>
            <Row className={"g-3 pt-5"}>
                <Col lg={6} className={"px-5 d-flex flex-column justify-content-center"}>
                    <div>
                        <div className="text-purple d-flex flex-row align-items-center pb-2">
                            <BsStars/>
                            &ensp;
                            <div>
                                Powered by GPT4
                            </div>
                        </div>
                        <h1 className='d-inline text-black'><b>Insights in seconds</b></h1>&emsp;<div className='d-inline'></div>
                        
                        <div className='text-gray pt-2'>
                            <p>
                            You can take your user experience research into practice while we analyze. Our platform provides instant insights into user behavior, allowing you to make informed decisions and iterate efficiently.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={6}>
                    <Row className='px-5 pb-5'><img
                        src=
                            "../Images/LandingPage/Insights.png"

                        alt={"Interviews"}
                        className={"rounded shadow p-0 unselectable"}
                    />
                    </Row>
                </Col>
            </Row>
            <Container className="py-3">
                <Row className="g-3 pb-5">
                    <Feature
                        icon={<CiMedal size={32} color="primary" />}
                        title="Instant UX Insights"
                        description="Gain valuable insights into user behavior and preferences within seconds, empowering informed decisions."
                    />
                    <Feature
                        icon={<CiDiscount1 size={32} color="primary" />}
                        title="Efficient User Testing"
                        description="Save valuable time and resources using our rapid and dependable user testing tools, enhancing your testing workflow."
                    />                       
                    <Feature
                        icon={<CiVial size={32} color="primary" />}
                        title="Behavior Analysis"
                        description="Understand user actions, preferences, and pain points to make well-informed decisions that prioritize user satisfaction."
                    />  
                </Row>
            </Container>



            <Row className={"p-5 g-5"}>
                <Col lg={6}>
                    <Row>
                        <img
                        src=
                            "../Images/LandingPage/Miro.png"

                        alt={"Insights"}
                        className={"rounded shadow p-0 unselectable"}
                        />
                    </Row>
                </Col>
                <Col lg={6} className={"px-5 d-flex flex-column justify-content-center"}>
                    <div>
                        <div className="text-purple d-flex flex-row align-items-center pb-2">
                            <BsStars/>
                            &ensp;
                            <div>
                                AI-generated insights
                            </div>
                        </div>
                        <h1 className='d-inline text-black'><b>Transform research</b></h1>&emsp;<div className='d-inline'><Indicator>Upcoming</Indicator> </div>

                        <div className='text-gray pt-2'>
                            <p>
                            Empower your creative journey with seamless integration, rapid interview guides, and comprehensive answers for design excellence.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Container className="py-3">
                <Row className="g-3 pb-5">
                    <Feature
                        icon={<SiMiro size={32} color="primary" />}
                        title="Integrated in your workflow"
                        description="Elevate teamwork and design synergy by seamlessly integrating our tools with Miro's collaborative platform."
                    />
                    <Feature
                        icon={<CiMemoPad size={32} color="primary" />}
                        title="Fast Interview Guide Creation"
                        description="Propel your design process with rapid interview guide creation, ensuring efficient and targeted research."
                        />                       
                    <Feature
                        icon={<CiPenpot size={32} color="primary" />}
                        title="Comprehensive Design Answers"
                        description="Access comprehensive insights for impactful designs, ensuring every aspect resonates with your audience's needs."
                        />  
                </Row>
            </Container>
        </Container>
        </section>
    );
};

export default InfoSection;
