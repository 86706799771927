import React from "react";
import "./Authentication.css";
import {BsArrowRight, BsArrowUpRight} from 'react-icons/bs';
import Dropdown from "react-bootstrap/Dropdown";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

//Auth
import { useAuth } from "../../Contexts/AuthContext"
import { openCustomerPortal, signOut} from "../../Services/Firebase";
import {bytesToGB, useStorage} from "../../Hooks/useStorage";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useToast} from "../../Contexts/ToastProvider";
import {Mini} from "../Typography";
import { useUser } from "../../Hooks/useUser";

export function SignUp({props, size, variant}) {
    // a custom hook to keep track of user's auth status
    return (
        <Button size={size}
                variant={variant}
                href="/SignUp"
                {...props}>Sign Up&nbsp;<BsArrowRight/></Button>
    );
}

export function Login() {
    return (
        <Button href="/Login" variant={"light"}>Login</Button>
    );
}

export function StorageUsage() {
    const { totalSize, loading } = useStorage();
    const { plan } = useAuth();

    const maxStorage = plan.metadata.maxStorage;

    // Only return the button if user is not subscribed
    if (loading) {
        return null;
    }

    return(
        <div className="aside-collapse">
            <div className="d-flex justify-content-between pb-2">
                <Mini>{bytesToGB(totalSize)+'GB'}</Mini>
                <Mini>{maxStorage+'GB'}</Mini>
            </div>
            <ProgressBar now={(bytesToGB(totalSize)/maxStorage)*100} />
        </div>
    )
}

export function AvatarButton(props) {
    const { isSubscribed, currentUser } = useAuth();
    const { UpdateToast } = useToast();
    const { User } = useUser(currentUser.uid);

    const Checkout = async () => {
        UpdateToast(openCustomerPortal(), {loading: 'Loading payment portal', success: 'Successfully loaded payment portal', error: 'Error opening payment portal'});
    };

    return (
        <Dropdown id="authButton" >
            <div className={"d-flex flex-row align-items-center "+props.className} type="button"
                 data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="false">
                <Avatar user={User} displayName={false}/>
            </div>
            <div className="dropdown-menu dropdown-menu-right mt-1 shadow shadow-sm mb-1" aria-labelledby="authButton">
                {/* <Dropdown.Item href="/Projects">Projects</Dropdown.Item> */}
                <Dropdown.Item to={{
                                pathname: `/Settings`
                                }}
                               state={{ CurrentFolder: null }}
                               as={Link}>
                    My settings
                </Dropdown.Item>
                <Dropdown.Divider/>
                <div className="d-lg-none">
                    <Dropdown.Item 
                        className="d-flex flex-row align-items-center justify-content-between"
                        href="https://qanda.canny.io/feedback">
                        Product roadmap&ensp;<BsArrowUpRight size={12}/>
                    </Dropdown.Item>
                    {/* <Dropdown.Item 
                        className="d-flex flex-row align-items-center justify-content-between"
                        target="_blank">
                        Request feature&ensp;<BsArrowUpRight size={12}/>
                    </Dropdown.Item> */}
                    <Dropdown.Item 
                        className="d-flex flex-row align-items-center justify-content-between"
                        target="_blank" 
                        href="https://join.slack.com/t/qandadesign/shared_invite/zt-1msw04j9y-8XdnOfI7ZqxtV32lurd4Qw">
                        Slack community&ensp;<BsArrowUpRight size={12}/>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                </div>
                <Dropdown.Item onClick={signOut}>Sign out</Dropdown.Item>
            </div>
        </Dropdown>
    );
}

export function Avatar(props) {
    if (!props.user) return null;
  
    // Define the default size as 16px or use the size from props
    const size = props.size || '36px';
  
    let name = props.user.displayname || props.user.name || props.user.email || '';
  
    return (
      <div className={`d-flex flex-row align-items-center ${props.className}`}>
        <div
          className={`avatar rounded-circle user-select-none bg-light border-1 border-gray d-flex justify-content-center`}
          style={{ width: size, height: size }}
        >
          {props.user.photoURL ? (
            <img className="h-100 w-100 rounded-circle bg-light" src={props.user.photoURL} alt="User avatar" />
          ) : (
            <div className={`avatarName text-dark`} style={{ lineHeight: size }}>{name.charAt(0)}</div>
          )}
        </div>
        {props.displayName ? <div className="px-2"><div>{name}</div></div>: null}
      </div>
    );
  }
  