import {Container, Row, Col, Form, Modal} from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import {BsFillCheckCircleFill} from "react-icons/bs";
import Indicator from "../Elements/Indicator";
import React, {useEffect, useState} from "react";
import {SignUp} from "../Elements/Auth/Authentication";
import {Mini} from "../Elements/Typography";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import {createCheckout, getProducts} from "../Services/Firebase";
import {useAuth} from "../Contexts/AuthContext";
import Badge from "react-bootstrap/Badge";
import {useToast} from "../Contexts/ToastProvider";
import {freePlan} from "../freePlan";

export function PricingCards(props) {
    const { Error } =  useToast();
    const [plans, setPlans] = useState([]);

    const [isYearly, setIsYearly] = useState(0);

    const handleSwitchChange = () => {
        setIsYearly(isYearly === 0 ? 1 : 0);
    };

    useEffect(() => {
        // Load the products when the component mounts
        getProducts().then((res) => {
            setPlans(res);
        }).catch((error) => {
            Error(error);
        });
    }, []); // Only run once, on mount

    return (
        <Stack gap={3} className={'p-md-5 p-3 text-center bg-white d-flex justify-content-center '+props.className}>
            <div>
                <h1>Select your plan</h1>
                <div>Leverage large projects within a glimpse. From interview to insight within seconds.</div>
            </div>
            <div className={'d-flex justify-content-center align-items-baseline'}>
                <Mini>Monthly</Mini>
                &ensp;
                <Form.Check
                    type="switch"
                    id="monthly-yearly-switch"
                    checked={isYearly}
                    onChange={handleSwitchChange}
                />
                &nbsp;
                <Mini>
                    Yearly
                    &nbsp;
                    <Badge className={'rounded-pill'} bg={'primary'}>Save 20%</Badge>
                </Mini>
            </div>
            <Container>
                <Row className='d-flex flex-row align-items-center'>
                    <PricingCard plan={freePlan} priceNum={isYearly} buttonVariant={'outline-primary'} showButton={false}/>
                    {plans.map((plan, index) => (
                        <PricingCard 
                            key={plan.name} 
                            plan={plan} 
                            priceNum={isYearly}
                            className={index === 0 ? "border-3 border border-primary rounded shadow-sm pt-5" : ""}
                            buttonVariant={index !== 0 ? "outline-primary" : "primary"}
                        />
                    ))}
                </Row>
            </Container>
        </Stack>
    );
}

export function PricingCard({plan, priceNum, buttonVariant = 'primary', className, showButton = true}) {
    const { currentUser } = useAuth();
    const { UpdateToast } = useToast();

    const Checkout = async () => {
        UpdateToast(createCheckout(plan.prices[priceNum].id), {loading: 'Loading payment portal', success: 'Successfully loaded payment portal', error: 'Error opening payment portal'});
    };

    const ActionButton = () => {
        if(currentUser && showButton){
            return(<Button variant={buttonVariant} onClick={() => Checkout(plan.prices[priceNum].id)}>
                Choose plan
            </Button>)
        }

        if(currentUser && !showButton){
            return null
        }

        if(!currentUser && !showButton){
            return(<SignUp variant={buttonVariant}/>)
        }
    }
    return (
        <Col lg={4} className={`rounded-3 text-center py-3`}>
            <Stack gap={3} className={`card bg-white p-3 ${className}`}>
                    <div className={'w-100 py-5'}>
                        <Image alt={plan.name} src={plan.images[0]} className={'w-25'}/>
                    </div>
                    <h4 className="my-0 ">{plan.name}</h4>
                    <div className={'d-flex flex-row align-items-baseline justify-content-center'}>
                        <h1>
                            {(plan.prices[priceNum].unit_amount / 100).toFixed(0)}
                        </h1>
                        <Mini>
                            {plan.prices[priceNum].currency}
                            /
                            {plan.prices[priceNum].recurring.interval}
                        </Mini>
                    </div>
                    <div className={'p-3'}>{plan.description}</div>
                        <ActionButton/>
                </Stack>
                {/*<div className="card-body d-flex flex-column justify-content-between">*/}
                {/*    <ul className="list-unstyled mt-3 mb-5 text-start mx-3">*/}
                {/*        <li><BsFillCheckCircleFill/>&ensp;Unlimited projects</li>*/}
                {/*        <br/>*/}
                {/*        <li><BsFillCheckCircleFill/>&ensp;Unlimited transcriptions</li>*/}
                {/*        /!*<li><BsFillCheckCircleFill/>&ensp;5 transcription hours<small>/mo</small></li>*!/*/}
                {/*        /!*<li>2 GB of storage</li>*!/*/}
                {/*    </ul>*/}
                {/*    <SignUp type="button" size={'lg'} variant="outline-primary" className="w-100">Sign up</SignUp>*/}
                {/*</div>*/}
            </Col>
    );
}
