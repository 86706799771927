import * as Y from 'yjs'
import LiveblocksProvider from "@liveblocks/yjs";
import { useEffect, useState } from 'react';
import TipTap from './TipTap';
import { useRoom } from './liveblocks.config.ts';
import AvatarStack from './AvatarStack/AvatarStack.js';

export default () => {
  const room = useRoom();
  const [doc, setDoc] = useState();
  const [provider, setProvider] = useState();

  // Set up Liveblocks Yjs provider
  useEffect(() => {
    const yDoc = new Y.Doc();
    const yProvider = new LiveblocksProvider(room, yDoc);
    setDoc(yDoc);
    setProvider(yProvider);

    return () => {
      yDoc?.destroy();
      yProvider?.destroy();
    };
  }, [room]);

  if (!doc || !provider) {
    return null;
  }

  return(
      <div className={"flex-grow-1 w-100 d-flex flex-column bg-light"}>
        <TipTap doc={doc} provider={provider}/>
      </div>
    ) 
};