// import './index.scss';
import './App.css';
import React, {useEffect, useState} from "react";
import {Shelf, InsightsShelf} from "./Pages/FilesOverview";
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import {FileManagerRoute, PrivateRoutes, PublicOnlyRoutes} from "./PrivateRoute";
import SettingsForm from "./Pages/Settingsform";
import {AuthProvider} from "./Contexts/AuthContext";
import {PageNotFound} from "./Pages/PageNotFound";
import FileView from "./Pages/FileView";
import {ToastProvider} from "./Contexts/ToastProvider";
import { ProSidebarProvider } from 'react-pro-sidebar';
import Contact from './Pages/Landing Page/Subpages/Contact';
import Plans from './Pages/Plans';
import ProfileForm from './Pages/Profileform';
import { GuidesShelf } from './Pages/GuidesShelf';
import GuideView from './Pages/GuideView';
import { MiroBoard } from './Pages/Miro';
import { Members } from './Pages/Members';
import TagView, { OverlayContent } from './Pages/TagView';
import { OverlayPortal } from './Elements/Modals/Overlay';
import { ProjectsOverview } from './Pages/ProjectOverview';

function App() {
  return (
      <div className="App">
        <ProSidebarProvider>
            <AuthProvider>
                <ToastProvider>
                    <Router>
                        
                                    <Routes>
                                        <Route element={<PublicOnlyRoutes/>}>
                                            <Route path="/" element={<SignIn />} />
                                            <Route path='/SignUp' element={<SignUp/>} />
                                            <Route path='/Login' element={<SignIn/>} />
                                            <Route path='/Contact' element={<Contact/>} />
                                        </Route>
                                        <Route path="/SignUp" element={<SignUp/>}/>

                                        <Route path="/Login" element={<SignIn />} />

                                        <Route element={<PrivateRoutes/>}>
                                            <Route path="/" element={<Shelf/>}/>
                                            <Route path='/Settings' element={<SettingsForm/>} />
                                            <Route path='/Profile' element={<ProfileForm/>} />
                                            <Route path='/Plans' element={<Plans/>} />
                                            {/*TODO: Redirect to /projects if you type in /projects/some random folder id
                                                        Right now any file saved in a random folder name is saved to the root
                                                        folder, which is not supposed to be possible.*/}
                                            <Route exact path="/Projects/:folderId" element={<Shelf/>} />
                                            <Route path="/Projects/:folderId/members" element={<Members/>} />

                                            <Route path="/Guides/:folderId" element={<GuidesShelf/>} />
                                            <Route path="/Insights/:folderId" element={<InsightsShelf/>} />
                                            <Route path="/Board/:folderId" element={<MiroBoard/>} />

                                            <Route exact path="/Projects" element={<ProjectsOverview/>} />
                                            <Route exact path="/Guide/:guideId" element={<GuideView/>} />
                                            <Route exact path="/Projects/:folderId/file/:fileId" element={<FileView/>} />
                                            <Route exact path="/Projects/:folderId/tags/:tagId" element={<TagView/>} />
                                            <Route exact path="/Projects/:folderId/tags" element={<TagView/>} />

                                            {/* <Route exact path="/Projects/:folderId/tags/:tagId" element={<TagView/>} /> */}

                                            {/* <Route exact path="/Guides/:folderId" element={<CreateProject/>} /> */}
                                            {/* <Route exact path="/Guide/:guideId" element={<CreateProject/>} /> */}
                                            {/* <Route exact path="/TipTap/:fileId" element={<Tiptap/>} /> */}
                                            {/* <Route exact path="/SubtitleEditor" element={<SubtitleEditor/>} /> */}
                                        </Route>
                                        {/* 👇️ only match this when no other routes match */}
                                        <Route path="*" element={<PageNotFound />} />
                                    </Routes>
                                
                    </Router>
                </ToastProvider>
            </AuthProvider>
          </ProSidebarProvider>
      </div>
  );
}

export default App;
