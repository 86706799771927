import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import AudioNode from './Audio.jsx';

export default Node.create({
  name: 'transcript',
  group: 'block',
  content: 'inline*',

  selectable: true,
  isolating: true,

  addAttributes() {
    return {
      src: {
        // Set the src attribute according to the value of the `src` attribute
        parseHTML: element => element.getAttribute('src'),
      },
      uid: {
        // Set the src attribute according to the value of the `src` attribute
        parseHTML: element => element.getAttribute('uid'),
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: element => {
          // Get a specific attribute
          element.getAttribute('src')
          element.getAttribute('uid')
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AudioNode);
  },

  addCommands() {
    return {
      setAudio: (attrs) => ({ commands }) => {
        return commands.insertContent([{
          type: 'transcript',
          attrs,
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: ' ',
            },
          ],
        }]);
      },
    };
  },
});
