import { useReducer, useEffect } from "react"
import { useAuth } from "../Contexts/AuthContext"
import { database } from "../Services/Firebase"
import firebase from 'firebase/compat/app';

const ACTIONS = {
    SELECT_FOLDER: "select-folder",
    UPDATE_FOLDER: "update-folder",
    SET_CHILD_FOLDERS: "set-child-folders",
    SET_CHILD_FILES: "set-child-files",
};

export const ROOT_FOLDER = { name: "Root", id: null, path: [] };

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.SELECT_FOLDER:
            return {
                folderId: payload.folderId,
                CurrentFolder: payload.CurrentFolder,
                Files: [],
                Folders: [],
            };
        case ACTIONS.UPDATE_FOLDER:
            return {
                ...state,
                CurrentFolder: payload.CurrentFolder,
            };
        case ACTIONS.SET_CHILD_FOLDERS:
            return {
                ...state,
                Folders: payload.Folders,
            };
        case ACTIONS.SET_CHILD_FILES:
            return {
                ...state,
                Files: payload.Files,
            };
        default:
            return state
    }
}

export function useFolder(folderId = null, CurrentFolder = null, setLoading = () => {}) {
    const [state, dispatch] = useReducer(reducer, {
        folderId,
        CurrentFolder,
        Folders: [],
        Files: [],
    });
    
    const { currentUser } = useAuth();
    
    useEffect(() => {
        dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, CurrentFolder } })
    }, [folderId, CurrentFolder]);

    useEffect(() => {
        if (folderId == null || currentUser == null) {
            return dispatch({
                type: ACTIONS.UPDATE_FOLDER,
                payload: { CurrentFolder: ROOT_FOLDER },
            })
        }

        const unsubscribeFolder = database.folders
        .doc(folderId)
        .onSnapshot(
            snapshot => {
                dispatch({
                    type: ACTIONS.UPDATE_FOLDER,
                    payload: { CurrentFolder: database.formatDoc(snapshot) },
                });
            },
            error => {
                // Handle permission error
                console.error("Error fetching folder snapshot:", error);
                // Check if the error is due to permission denial
                if (error.code === "permission-denied") {
                    window.location.assign("/no-permission");
                }
            }
        );

        return () => {
            unsubscribeFolder();
        }
    }, [folderId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (setLoading) setLoading(true);
    
                if (currentUser == null) {
                    setLoading(false)
                    return dispatch({
                        type: ACTIONS.UPDATE_FOLDER,
                        payload: { CurrentFolder: ROOT_FOLDER },
                    });
                }
    
                const ownedFoldersQuery = database.folders
                    .where("parentId", "==", folderId)
                    .where("userId", "==", currentUser.uid);
    
                const memberFoldersQuery = database.members
                    .where('userId', "==", currentUser.uid);
    
                const [ownedSnapshot, memberSnapshot] = await Promise.all([
                    ownedFoldersQuery.get(),
                    memberFoldersQuery.get()
                ]);
    
                const ownedDocs = ownedSnapshot.docs;
                const memberRefs = memberSnapshot.docs.map(doc => doc.ref.parent);
                
                // Fetch the grandparent documents for the members' query
                const memberParentDocsPromises = memberRefs.map(ref => ref.parent.get());
    
                const memberParentDocs = await Promise.all(memberParentDocsPromises);
    
                const combinedDocs = ownedDocs.concat(memberParentDocs);
    
                if (setLoading) setLoading(false);
    
                dispatch({
                    type: ACTIONS.SET_CHILD_FOLDERS,
                    payload: { Folders: combinedDocs.map(database.formatDoc) },
                });
            } catch (error) {
                console.error("Error fetching folders:", error);
            }
        };
    
        const unsubscribeMembers = database.members
            .where('userId', "==", currentUser?.uid)
            .onSnapshot(memberSnapshot => {
                // Update the Folders array whenever there is a change in the members collection
                fetchData();
            });
    
        // Cleanup the listener when the component unmounts
        return () => {
            unsubscribeMembers();
        };
    }, [folderId, currentUser]);
    

    // TODO: This function is called too many times
    useEffect(() => {
        if (currentUser == null || folderId == null) {
            return dispatch({
                type: ACTIONS.UPDATE_FOLDER,
                payload: { CurrentFolder: ROOT_FOLDER },
            })
        }

        var memberRef = database.folders.doc(folderId).collection('members').doc(currentUser.uid);
        // Check if the user is a member or is the owner of the folder, then read the files
        memberRef.get().then((doc) => {
            if (doc.exists) {
                // console.log('is a member')
                return(database.files
                    .where("folderId", "==", folderId).onSnapshot(snapshot => {
                        dispatch({
                            type: ACTIONS.SET_CHILD_FILES,
                            payload: { Files: snapshot.docs.map(database.formatDoc) },
                        })
                    }))
            } else {
                // console.log('is not a member')
                // Check if you are then the owner of the folder, otherwise navigate to a no permission page
                if(CurrentFolder && CurrentFolder?.userId != currentUser.uid) window.location.assign('/no-permission');

                // console.log('is the owner')
                return (database.files
                        .where("folderId", "==", folderId)
                        .where("userId", "==", currentUser.uid)
                        // .orderBy("createdAt")

                        .onSnapshot(snapshot => {
                            dispatch({
                                type: ACTIONS.SET_CHILD_FILES,
                                payload: { Files: snapshot.docs.map(database.formatDoc) },
                            })
                        }))
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [CurrentFolder, folderId, currentUser]);

    return state
}
