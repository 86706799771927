import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useFile } from '../../../../Hooks/useFile';
import useTags from '../../../../Hooks/useTags';
import './TagComponent.scss';
export default props => {
  const { folderId, fileId } = useParams();
  const [tag, setTag] = useState(null);
  const { getTag } = useTags(folderId);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (props?.node.attrs?.tagId) {
        try {
          const tag = await getTag(props.node.attrs?.tagId, fileId);
          setTag(tag);
        } catch (error) {
          // Handle errors from getTag function
          console.error("Error fetching tag:", error);
        }
      }
    };
  
    fetchData(); // Invoke the async function
  
  }, [props?.node.attrs, fileId]); // Make sure to include fileId in the dependency array if it's used inside the useEffect
  
  // Assuming tagColor is a variable holding the color value
  const tagColor = tag?.tagData?.color || '#ffffff'; 

  // Calculate the rgba value with 20% transparency
  const backgroundColor = `rgba(${parseInt(tagColor.slice(-6, -4), 16)}, ${parseInt(tagColor.slice(-4, -2), 16)}, ${parseInt(tagColor.slice(-2), 16)}, 0.2)`;
    // Define the style object
  const nodeViewStyle = {
    backgroundColor: backgroundColor,
    borderBottom: `2px solid ${tagColor}`,
    borderRadius: '2px',
  };


  return (
    <NodeViewWrapper className="content inline-text text-nowrap d-inline-block" contentEditable={false} >
      <div className='d-inline-block text-nowrap inline-text d-flex flex-row tag' style={nodeViewStyle} >
        <NodeViewContent contentEditable={true} />
        <div  className='user-select-none tag-name text-white rounded px-1' 
              style={{backgroundColor: tagColor}} 
              onClick={() => navigate('/Projects/' + folderId + '/tags/'+ tag.id)}
              contentEditable={false}>
          {tag?.tagData?.name}
        </div>
      </div>
    </NodeViewWrapper>
  )
}