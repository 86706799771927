import {Button, Dropdown, Form} from 'react-bootstrap';
import React, {Component, useEffect, useState} from "react";
import {BsCloudUpload, BsFillShareFill, BsFillUnlockFill, BsMarkdown, BsPlusLg, BsShare, BsShareFill} from 'react-icons/bs';
import {Modal} from "react-bootstrap";
import {PricingCards} from "../Pages/PricingCards";
import {useAuth} from "../Contexts/AuthContext";
import {FadingText, Cursor} from "../Elements/Typography";
import { useFile } from '../Hooks/useFile';
import Loading from './LoadingSpinner';
import { database } from '../Services/Firebase';
import { UploadModal } from './UploadButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useFolder } from '../Hooks/useFolder';
import { useFileManager } from '../Contexts/FileStorageContext';
import "./Buttons.scss";

export class PrimaryButton extends Component {
    render() {
        return (
            <Button className={"btn-yellow rounded-pill px-3"} onClick={this.props.onClick} type={this.props.type} href={this.props.href} target={this.props.target}>
                {this.props.children}
            </Button>
        );
    }
}

export function UpgradeButton ({size, showText = true, showWhenSubscribed = false, ...props}) {
    const [modal, setModal] = useState(false);
    const handleShow = () => setModal(!modal);
    const { plan, isSubscribed, loading } = useAuth();

    // Only return the button if user is not subscribed

    if(!showWhenSubscribed){
        if (isSubscribed && !loading) {
            return null;
        }
    }

    if (loading) {
        return null;
    }

    return (
        <>
            <Button variant={'outline-primary'} className={"d-flex flex-row align-items-center justify-content-center no-wrap "+props.className} onClick={handleShow} size={size}>
                <div className='d-none d-lg-block'>
                    <BsFillUnlockFill />&ensp;
                </div>{ showText? 'Upgrade': ''}
            </Button>
            <Modal show={modal} onHide={() => setModal(false)} centered size={'lg'}>
                <PricingCards/>
            </Modal>
        </>
    );
}

export function SourceButton(props) {
    const { selected } = props;
    
    return (
        <Button
            variant={selected ? "primary" : "outline-primary"}
            {...props}
        >
            <div className="d-flex d-inline align-items-center">
                {props.children}
            </div>
        </Button>
    );
  }

export function SecondaryButton(props) {
    return (
        <Button
            variant="light"
            className={"rounded border border-gray border-1 d-flex flex-row align-items-center justify-content-center "+props.className}
            onClick={props.onClick}
        >
        {props.children}
      </Button>
    );
}

export function UploadButton(props) {
    // const { openUploader } = useUploader();
    // const { CustomToast } =  useToast();
    const { folderId } = useParams();
    const [loading, setLoading] =  useState(false);
    const { Files } = useFolder(folderId, setLoading);
    const { plan } = useAuth();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if(Files.length >= plan.metadata.maxTranscripts) {
        return (
            <UpgradeButton showWhenSubscribed />
        );
    }

    return (
        <>
            <Button {...props} onClick={handleShow} className="d-flex flex-row justify-content-center align-items-center no-wrap">
                <BsCloudUpload/>
                <div className="d-none d-lg-block">
                    &ensp;Upload file
                </div>
            </Button>
            
            <Modal show={show} onHide={handleClose} {...props} size="md" aria-labelledby="contained-modal-title-vcenter"  centered>
                <UploadModal setShow={setShow}/>
            </Modal>
        </>
    );
}

export function AddData () {
// const { openUploader } = useUploader();
    // const { CustomToast } =  useToast();
    const { folderId } = useParams();
    const [loading, setLoading] =  useState(true);
    const { CurrentFolder, Files } = useFolder(folderId, null, setLoading);
    const { plan } = useAuth();
    const{ CreateEmptyFile } = useFileManager();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const CreateMarkdown = () => {
        CreateEmptyFile(CurrentFolder, Files).then((data) => {
            navigate(`/Projects/${CurrentFolder.id}/File/${data.id}`)
        })
    }

    if(Files.length >= plan.metadata.maxTranscripts) {
        return (
            <UpgradeButton showWhenSubscribed />
        );
    }
    
    return(
        <>
            <Dropdown>
                <Button variant="primary" className="d-flex flex-row align-items-center" type="button" id="dropdown-basic" data-toggle="dropdown"> 
                    <BsPlusLg/>
                    <div class="d-none d-lg-block">
                        &ensp;Add data...
                    </div>
                </Button>

                <div class="dropdown-menu dropdown-menu-right mt-1" aria-labelledby="dropdown-basic">
                    <Dropdown.Item onClick={CreateMarkdown} className="d-flex flex-row align-items-center">
                        <BsMarkdown/>
                        <div>
                            &ensp;Create empty
                        </div>
                    </Dropdown.Item>
                    
                    <Dropdown.Item>
                        <div className="d-flex flex-row align-items-center" onClick={handleShow}>
                            <BsCloudUpload/>
                            <div>
                                &ensp;Upload file
                            </div>
                        </div>
                    </Dropdown.Item>
                </div>
            </Dropdown>
            
            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter"  centered>
                <UploadModal setShow={setShow}/>
            </Modal>
        </>
    )
}

export const Share = ({folderId}) =>{
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    return(
        <>
            <SecondaryButton onClick={() => navigate('/Projects/'+folderId+'/members')} className="d-flex flex-row align-items-center">
                <BsShare/>
                <div class="d-none d-lg-block">
                    &ensp;Share
                </div>
            </SecondaryButton>
        </>
    )
}