import React, { useState, useEffect, useCallback } from "react";
import "../ProgressIndicator.css";
import {useFileManager} from "../Contexts/FileStorageContext";
import {useParams, useLocation, Link, useNavigate, Navigate} from "react-router-dom";
import {ROOT_FOLDER, useFolder} from "../Hooks/useFolder";
import Loading from "../Elements/LoadingSpinner";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { useBoard } from "../Contexts/BoardContext";
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { Button, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useProjectMembers } from "../Hooks/useProjectMembers ";
import { usePublicUser } from "../Hooks/usePublicUser";
import { Avatar } from "../Elements/Auth/Authentication";
import { SecondaryButton } from "../Elements/Buttons";
import { useAuth } from "../Contexts/AuthContext";

export const Members = () => {
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const { CurrentFolder } = useFolder(folderId);
    const { currentUser } = useAuth();
    const { members, addMember } = useProjectMembers(folderId);
    const [loading, setLoading] =  useState(false);
    const [showModal, setShowModal] =  useState(false);

    // Takes in one email, will later be an array
    // Add the member to firebase and send an email
    const inviteMember = (e, email) => {
        e.preventDefault();
        addMember(email);
    }

    return (
        <ProjectSidebar folderId={folderId}>
            <Loading loading={loading} animation={"grow"}>
                <div className={"w-100 vh-100 bg-white d-flex flex-column"}>
                    <AvatarHeader showToggleSidebar/>
                    <div className={"border-start-0 border-top-0 border-end-0 border-gray position-sticky top-0 bg-dropdown pe-3"}  style={{zIndex: 10}}>
                        {/* Stuff such as names, titles and buttons */}
                        <div className={"d-flex flex-row justify-content-between align-items-center w-100 "}>
                            <h5 className={"d-flex flex-row align-items-center h-100 p-3"}>
                                Members
                            </h5>
                            <Button onClick={() => setShowModal(true)}>
                                +&ensp;Invite...
                            </Button>
                        </div>
                    </div>
                    <div className={"w-100 flex-grow-1 bg-light overflow-auto"}>
                        {/* <Tabs activeTab="1" className="px-3 mt-2" ulClassName="" activityClassName="bg-primary" onClick={(event, tab) => console.log(event, tab)} >
                            <Tab title="Project Members">
                                // Map the members
                                <div className={"d-flex flex-column"}>
                                        <Member memberId={CurrentFolder?.userId} showRemove={false}/>
                                        {members.map((member) =>
                                            (
                                                // Only allow remove if you are the owner of the folder
                                                <Member key={member.id} memberId={member.id} showRemove={CurrentFolder?.userId == currentUser.uid} />
                                            )
                                        )}
                                </div>
                            </Tab>
                            <Tab title="Invites">
                                Tab content for Profile
                            </Tab>
                        </Tabs> */}
                        <div className={"d-flex flex-column px-3 py-2"}>
                            <Member memberId={CurrentFolder?.userId} showRemove={false}/>
                            {members.map((member) =>
                                (
                                    // Only allow remove if you are the owner of the folder
                                    <Member key={member.id} memberId={member.id} showRemove={CurrentFolder?.userId == currentUser.uid} />
                                )
                            )}
                    </div>
                    </div>
                </div>
                <InviteMemberModal show={showModal} onHide={() => setShowModal(false)} onSubmit={inviteMember}/> 
            </Loading>
        </ProjectSidebar>
    );
};

const Member = ({memberId, showRemove}) => {
    const { User } = usePublicUser(memberId)
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const { removeMember } = useProjectMembers(folderId);

    const onRemove = async () => {
        await removeMember(memberId);
    }

    return(
        <div className="border-gray border-start-0 border-top-0 border-end-0 d-flex flex-row justify-content-between align-items-center py-2">
            <div className="d-flex flex-row align-items-center gap-3">
                <Avatar user={User} size={'42px'} className={'pe-none'}/> 
                <div className="d-flex flex-column">
                    <b>
                        { User?.name }
                    </b>
                    <div className="text-dark">
                        { User?.email }
                    </div>
                </div>
            </div>
            {showRemove? <SecondaryButton variant={'danger'} onClick={onRemove}>Remove</SecondaryButton>:<></>}
        </div>
    )
}

const InviteMemberModal = ({show, onHide, onSubmit}) => {
    const [memberInput, setMemberInput] = useState("");

    return(
        <Modal show={show} onHide={onHide} size="md" centered>
            <Form className={"p-4"} onSubmit={(e) => onSubmit(e, memberInput)}>
                <Form.Group>
                    <Modal.Title>Invite members</Modal.Title>
                    <br/>
                    <div className={'d-flex flex-column justify-content-between align-items-start'}>
                        <b>Invite by email</b>
                        <div className="d-flex flex-row align-items-center gap-2 my-2 w-100" >
                            <Form.Control type={'email'} placeholder={'Email'} onChange={(e) => setMemberInput(e.target.value)} value={memberInput}/>
                            <Button type={'submit'} disabled={!memberInput} onClick={(e) => {setMemberInput(""); onSubmit(e, memberInput)}}>Invite</Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>        
    )
}