import React, {Component, useEffect} from "react";
import {Modal, Button, Col, Card} from "react-bootstrap";
import { BsCloudUpload} from 'react-icons/bs';
import { useState } from "react";
import Form from "react-bootstrap/Form";
import {useFileManager} from "../Contexts/FileStorageContext";
import {ROOT_FOLDER, useFolder} from "../Hooks/useFolder"
import {useAuth} from "../Contexts/AuthContext";
import {useLocation, useParams} from "react-router-dom";
import {SecondaryButton, UpgradeButton} from "./Buttons";
import { BsFillCloudUploadFill, BsXCircleFill, BsFillFileEarmarkMusicFill, BsFillFileEarmarkTextFill, BsMusicNoteList, BsFileEarmarkRichtext, BsCameraVideo, BsCircle, BsX } from 'react-icons/bs';
import "./Input/AudioUploader";
import {Stack} from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { CiMusicNote1, CiVideoOn, CiFileOn } from 'react-icons/ci';
import { BarLoader } from "react-spinners";
import { Mini } from "./Typography";
import { useToast } from "../Contexts/ToastProvider";
// import { useUploader } from "../Contexts/UploadContext";

// const fileTypes = [".mp3, .wav"];
// const textTypes = [".srt"];
// const fileTypes = ["mp3", "wav", "m4a"];
const fileTypes = ["pdf"];

// const fileTypes = audioTypes.concat(textTypes);


export function UploadModal (props) {
    const { UploadFile } = useFileManager();

    // State to store uploaded file
    const [files, setFiles] = useState([]);
    const hasFiles = files.length > 0;

    // progress
    const [percent, setPercent] = useState([]);
    // console.log(percent);
    
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const { CurrentFolder, Files } = useFolder(folderId, location.folder);


    function handleUpload(e) {
        e.preventDefault();
        UploadFile(files, CurrentFolder, Files, setPercent);
    }


    if (percent.length > 0 && percent.every(p => p === 100)) {
        props.setShow(false);
        setFiles([]);
        setPercent([]);
    }

    const onChange = (newFiles) => {
        setFiles([...newFiles]);
    };

    const removeFile = (index) => {
        let filteredArr = files.filter((file, num) => num !== index);
        setFiles(filteredArr);
    };

    return (
        <Form className={"p-4"}>
                <Modal.Title>Upload Documents</Modal.Title>
                {!hasFiles ?
                    <>
                        <div className="mt-3">
                            <FileUploader
                                multiple={true}
                                handleChange={onChange}
                                name="file"
                                types={fileTypes}
                            >
                                <Stack gap={2} className={"p-4 file-upload w-100 rounded border-gray border-dotted d-flex justify-content-center text-center"}>
                                    <input type="file" accept={fileTypes} onChange={onChange}/>

                                    <h1><BsFillCloudUploadFill/></h1>
                                    <div>Drag and Drop files here</div>
                                    <div>or</div>
                                    <b className={"text-primary"}>Browse files</b>
                                </Stack>
                            </FileUploader>
                        </div>
                        <div className={"pt-2 text-muted"}>Accepted file types: {fileTypes.map(item => `.${item}, `)}</div>
                    </>
                    :
                    <div className={"mt-3"}>
                        {files.map((file, index) =>
                            <File
                            removeFile={removeFile}
                            file={file}
                            index={index}
                            key={index}
                            percentCompleted={percent[index]} // pass percent for current file as prop
                            />                            
                        )}
                    </div>
                    }
                <div className={"pt-3"}>
                    <Button variant={'light'} className={"w-100 rounded border border-gray border1 d-flex flex-row align-items-center justify-content-center"} onClick={handleUpload}>Add files to project</Button>
                </div>
        </Form>
    )
}

export function File(props) {
    const file = props.file;
    const index = props.index;
    const removeFile = props.removeFile;

    const fileType = file.type;
    let loading = false;

    if(props.percentCompleted > 0) loading = true;
    if(props.percentCompleted == 100) loading = false;

    const FileIcon = () => {
        if (fileType.includes('audio')) {
            return <CiMusicNote1 />;
        } else if (fileType.includes('video')) {
            return <CiVideoOn />;
        } else {
            return <CiFileOn />;
        }
    }

    const formatFileSize = (bytes) => {
        if (bytes < 1024) {
            return bytes + ' bytes';
        } else if (bytes < 1048576) {
            return (bytes / 1024).toFixed(2) + 'kb';
        } else if (bytes < 1073741824) {
            return (bytes / 1048576).toFixed(2) + 'mb';
        } else {
            return (bytes / 1073741824).toFixed(2) + 'gb';
        }
    }

    return (
        <div key={file.name} 
            className={"p-3 my-3 rounded border border-1 border-gray shadow-sm d-flex flex-column justify-content-between"}>
            <div className="d-flex flex-row justify-content-between align-items-baseline pb-3">
                <Stack direction={"horizontal"} gap={3} className="w-100">
                    {FileIcon()}
                    <Stack direction={"vertical"} gap={0} className="w-100">
                        <div>{file.name}</div>
                        <div className="d-flex flex-row justify-content-between w-100 align-items-end">
                            <div className="text-secondary">{formatFileSize(file.size)}</div>
                            {loading?<Mini>{ Number(props.percentCompleted).toFixed(0)}%</Mini>:<></>}
                        </div>
                    </Stack>
                    
                </Stack>
                <BsX onClick={() => removeFile(index)} className={"text-muted cursor-pointer"}/>
            </div>
            <BarLoader
                    color={"#1E43FF"}
                    loading={loading}
                    size={150}
                    className="w-100"                              
                    aria-label="Uploading"
                    data-testid="Uploading"
                />
        </div>
    );
}