import React, {useState} from "react";
import {Link, Outlet, Route, Routes, useLocation, useParams} from "react-router-dom";
import Loading from "../Elements/LoadingSpinner";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { Cluster, CreateCluster, UnsortedCluster } from "../Elements/Cards/Cluster";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './TagView.scss';
import {
  RoomProvider,
  useOthers,
  useUpdateMyPresence,
  useStorage,
  useMutation,
  useRoom
} from "../Elements/Input/ClusterEditor/liveblocks.config.ts";
import "@liveblocks/react";
import { LiveList, LiveObject } from "@liveblocks/client";
import { ClientSideSuspense } from "@liveblocks/react";
import useTagGroups from "../Hooks/useTagGroup.js";
import useTags from "../Hooks/useTags.js";
import { clusterColors, getRandomElement } from "../colors.js";
import { Modal } from "react-bootstrap";
import { OverlayPortal } from "../Elements/Modals/Overlay.js";
import { useFolder } from "../Hooks/useFolder.js";
import { BsFolder, BsHouse } from "react-icons/bs";
import Breadcrumbs from "../Elements/Navigation/Breadcrumbs/Breadcrumbs.js";

function WhoIsHere() {
    const userCount = useOthers((others) => others.length);
  
    return (
      <div className="who_is_here">There are {userCount} other users online</div>
    );
  }
  
  function SomeoneIsTyping() {
    const someoneIsTyping = useOthers((others) =>
      others.some((other) => other.presence.isTyping)
    );
  
    return (
      <div className="someone_is_typing">
        {someoneIsTyping ? "Someone is typing..." : ""}
      </div>
    );
  }

function TagView() {
    const { folderId } = useParams();
    const { location = {} } = useLocation();

    const [loading, setLoading] =  useState(true);
    const { CurrentFolder } = useFolder(folderId, null, setLoading);

    const breadcrumbItems = [
        { text: 'Home', link: '/Projects', icon: <BsHouse /> },
        { text: CurrentFolder?.name, icon: <BsFolder /> },
    ];

    return (
    <ProjectSidebar folderId={folderId}>
        <Loading loading={loading} animation={"grow"}>
            {/*<PrimaryButton onClick={downloadFile}>Download</PrimaryButton>*/}
            {/*TODO: Add overview of projects when there are folders and link is /projects*/}
            {/*Header*/}
            <div className={"w-100 bg-white d-flex flex-column vh-100"}>
                <AvatarHeader showSearch/>
                <div className={"border-start-0 border-top-0 border-end-0 border-gray position-sticky top-0 bg-dropdown py-3 px-4 d-flex flex-row justify-content-between align-items-center"}  style={{zIndex: 10}}>
                    {/* Stuff such as names, titles and buttons */}
                    <Breadcrumbs items={breadcrumbItems} />

                    <div class="d-flex flex-row gap-1 gap-md-3" style={{minHeight: '38px'}}>
                        {/* <Share /> */}
                    </div>
                </div>
                {Tags()}
            </div>
        </Loading>
    </ProjectSidebar>
    );
}

const Tags = () => {
    const { folderId } = useParams();
    const location = useLocation();

    return (
        <>
            <div className={"flex-grow-1 w-100 bg-light position-relative"}>
                {/* <Cluster title={'Unsorted'}/> */}
                <TagRoom/>
                <div id="overlay-root" />
            </div>
        </>
    )
}

const TagRoom = () => {
    const { folderId } = useParams();

    return(
            <RoomProvider
                id={folderId}
                initialPresence={{
                    isTyping: false,
                }}
                initialStorage={{ clusters: new LiveList(
                    [
                        new LiveObject({
                            'id': 'Unsorted'
                        })
                    ])
                }}
                >
                <ClientSideSuspense fallback={<div>Loading...</div>}>
                    {() => <TagGroupList />}
                </ClientSideSuspense>
            </RoomProvider>
    )
}

const TagGroupList = () => {
    const { folderId } = useParams();

    const [draft, setDraft] = useState("");
    const updateMyPresence = useUpdateMyPresence();
    const clusters = useStorage((root) => root.clusters);
    const room = useRoom((room) => room.id);
    const { createTagGroup } = useTagGroups(room.id);
    const { setTagGroupId } = useTags(folderId)

    const createClusterGroup = async () => {
        const tagGroupData = {
            name: 'New cluster',
            color: getRandomElement(clusterColors),
        }
        const tagGroupId = await createTagGroup(tagGroupData);
        return tagGroupId;
    }

    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;

        // const sourceClusterId = result.source.droppableId;
        const destinationClusterId = result.destination.droppableId;
        const tagId = result.draggableId;
        if(result.destination.droppableId === 'Create'){
            // Create a new cluster with the dropped tag and update firestore
            const tagGroupId = await createClusterGroup();
            setTagGroupId(result.draggableId, tagGroupId);
        }
        else if(result.destination.droppableId === 'Unsorted'){
            // Set to unsorted once again
            setTagGroupId(result.draggableId, null);
        }
        else{
            // Update the tag tagGroupId in firestore
            setTagGroupId(tagId, destinationClusterId);
        }
    }
    return (
        <div className="h-100 w-100" style={{overflowX: "auto"}}>
        {/* <WhoIsHere /> */}
        {/* <input
            type="text"
            placeholder="What needs to be done?"
            value={draft}
            onChange={(e) => {
            setDraft(e.target.value);
            updateMyPresence({ isTyping: true });
            }}
            onKeyDown={(e) => {
            if (draft && e.key === "Enter") {
                updateMyPresence({ isTyping: false });
                addTodo(draft);
                setDraft("");
            }
            }}
            onBlur={() => updateMyPresence({ isTyping: false })}
        /> */}
        {/* <SomeoneIsTyping /> */}
        <div className="h-100 d-flex flex-row gap-3 p-3">
        <DragDropContext onDragEnd={handleOnDragEnd}>
            {clusters?.map((cluster, index) => {
                if(cluster.id === 'Unsorted') return(
                    <UnsortedCluster />
                )
                return (
                    <Cluster key={index} clusterId={cluster.id}/>
                );
            })}
            <CreateCluster onCreate={createClusterGroup} />
        </DragDropContext>
        </div>
    </div>
)}


export default TagView;
