import { useEffect, useState } from "react";
import Loading from "../Elements/LoadingSpinner";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { Accordion, Col, Row, Stack } from "react-bootstrap";
import { SecondaryButton } from "../Elements/Buttons";
import { SiMiro } from "react-icons/si";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import { useLocation, useParams } from "react-router-dom";
import { useFolder } from "../Hooks/useFolder";
import { useFileManager } from "../Contexts/FileStorageContext";
import { useBoard } from "../Contexts/BoardContext";
import { SavedInsight, SavedInsights } from "./Insights";
import { useInsights } from "../Hooks/useInsights";

export const MiroBoard = () => {
  const [loading, setLoading] = useState(true);
  const { folderId } = useParams();
  const location = useLocation();
  const { CurrentFolder } = useFolder(folderId, null, setLoading);
  const { LinkBoard } = useBoard();

  const [miroLink, setMiroLink] = useState(null);

  useEffect(() => {
    // Set the iframe element in state
    if (CurrentFolder && CurrentFolder.miro?.miroLink) setMiroLink(CurrentFolder.miro.miroLink);
  }, [CurrentFolder]);

  let miroBoard = null;

  if (miroLink) {
    miroBoard = (
    <div className="d-flex flex-row w-100 h-100">
        {/* <InsightList/> */}
        <iframe
            className="h-100 w-100 m-0 p-0 border-0"
            src={miroLink}
            frameBorder="0"
            scrolling="no"
            allow="clipboard-read; clipboard-write"
            allowFullScreen
        />
    </div>
    );
  } else {
    miroBoard = (
      <Row className="h-100 d-flex flex-column align-items-center m-0">
        <Col className={"d-flex flex-column align-items-center h-100 justify-content-center"}>
          <h1>Collaborate or brainstorm</h1>
          <h5>Create a new board or select one</h5>
          <br />
          <SecondaryButton className={"bg-white"} onClick={() => LinkBoard(folderId)}>
            <SiMiro />&nbsp; Link board
          </SecondaryButton>
        </Col>
      </Row>
    );
  }

  return (
    <ProjectSidebar folderId={folderId} collapse>
      <Loading loading={loading} animation={"grow"}>
        <div className={"w-100 bg-white d-flex flex-column vh-100"}>
          <AvatarHeader />
          <div className={"my-auto w-100 flex-grow-1 bg-light overflow-auto border-gray border-start-0 border-end-0 border-bottom-0"}>
            <div className="w-100 h-100 d-flex justify-content-center">
              <div className={"flex-grow-1 overflow-hidden"}>{miroBoard}</div>
            </div>
          </div>
        </div>
      </Loading>
    </ProjectSidebar>
  );
};

const InsightList = () => {
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const { CurrentFolder } = useFolder(folderId);

    const [loading, setLoading] =  useState(false);
    const { insights } = useInsights(CurrentFolder, null, true);

    return (
        <Loading loading={loading}>
            <div className="p-3 h-100 overflow-auto border-primary border-start-0 borden-top-0 border-bottom-0 shadow-sm" style={{width: '20%'}}>
                <Stack gap={2}>
                    {insights.map((insight, index) =>(
                        <Accordion defaultActiveKey={-1}>
                            <Insight insight={insight} index={index} key={index}/>
                            <SavedInsight insight={insight} index={index} key={index}/>
                        </Accordion>
                    ))}
                </Stack>
            </div>
        </Loading>
    )
}

const Insight = ({props, insight}) =>{
    console.log(insight)

    return(
        <div {...props} >insight</div>
    )
}