import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage"
import { getAuth, updateProfile } from "firebase/auth";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey:             process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:         process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL:        process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId:          process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:  process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId:              process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId:      process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

export const firestore = app.firestore();
export const functions = getFunctions(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);

export const auth = firebase.auth();
export const user = auth.currentUser;

const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const database = {
    users: firestore.collection("Users"),
    folders: firestore.collection("Folders"),
    tags: firestore.collection("Tags"),
    members: firestore.collectionGroup("members"),
    insights: firestore.collection("Insights"),
    products: firestore.collection('Products'),
    files: firestore.collection("Files"),
    queries: firestore.collection("Queries"),
    guides: firestore.collection("Guides"),
    formatDoc: doc => {
        return { id: doc.id, ...doc.data() }
    },
    getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

export const signInWithFacebook = () => {
    console.log('Signing in...');
    facebookProvider.setCustomParameters({
        'display': 'popup'
    });
    auth.signInWithPopup(facebookProvider).then((res) => {
        // console.log(res.user);
        console.log('Signed in with Facebook');
        if(res.additionalUserInfo.isNewUser){
            createUserAndRedirect("Facebook");
        }else{
            window.location.href='../Projects';
        }
        console.log(auth.currentUser);
    }).catch((error) => {
        alert(error.message);
    })
};

const twitterProvider = new firebase.auth.TwitterAuthProvider();

export const signInWithTwitter = () => {
    console.log('Signing in...');
    auth.signInWithPopup(twitterProvider).then((res) => {
        // console.log(res.user);
        console.log('Signed in with Twitter');
        if(res.additionalUserInfo.isNewUser){
            createUserAndRedirect("Twitter");
        }else{
            window.location.href='../Projects';
        }
        console.log(auth.currentUser);
    }).catch((error) => {
        alert(error.message);
    })
};

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    console.log('Signing in...');
    auth.signInWithPopup(googleProvider).then((res) => {
        // console.log("Is first time user: ", res.additionalUserInfo.isNewUser);
        //If this is the first time the user is logging in
        if(res.additionalUserInfo.isNewUser){
            createUserAndRedirect("Google");
        }else{
            // updateUserAndRedirect();
            window.location.href='../Projects';
        }
        // console.log(res);
        // console.log('Signed in with Google');
        // console.log(auth.currentUser);
    }).catch((error) => {
        console.log(error.message);
    })
};

//TODO: Create root folder per user
const createUserAndRedirect = (origin, redirect = '../Projects') => {
    const data = {
        currentUser: auth.currentUser.uid,
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
        createdAt: database.getCurrentTimestamp(),
        origin: origin
    };

    database.users.doc(auth.currentUser.uid).set(data).then(() =>{
        const firstName = auth.currentUser.displayName.split(' ')[0];
        const lastName = auth.currentUser.displayName.split(' ')[1];

        const fields = {
            FirsName: firstName,
            LastName: lastName
        };

        createContact(data.email, fields).then( (res) => {
            //TODO: Redirect with react router dom
            window.location.href= redirect;
        });
    });
};

// const updateUserAndRedirect = (redirect = '../Projects') => {
//     console.log(auth.currentUser)

//     const data = {
//         currentUser: auth.currentUser.uid,
//         name: auth.currentUser.displayName,
//         email: auth.currentUser.email,
//     };
    
//     database.users.doc(auth.currentUser.uid).update(data).then(() => {
//         //TODO: Redirect with react router dom
//         window.location.href= redirect;
//     }).catch((error) => {
//         console.error('Error updating user data:', error);
//       });
// };

export async function createContact(email) {
    return new Promise((resolve, reject) => {
        const listId = 'd861fea4-c0bd-11ed-828b-91855b18dddb'
        const link = process.env.REACT_APP_NODE_API_URL+'addContact?email_address='+email+'&list_id='+listId;
        
        console.log(link)
        fetch(link, {
            method: 'GET',
            mode: 'cors',
            headers: {
                "Access-Control-Allow-Headers" : "Content-Type",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
            }
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Unable to subscribe. Please try again later.');
                }
                resolve('Thank you for subscribing!');
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const signOut = () => {
    console.log('Signing Out');
    firebase.auth().signOut()
        .then(function() {
            // Sign-out successful.
            console.log('Sign-out successful');
            console.log(auth.currentUser);
            window.location.href='../'

        })
        .catch(function(error) {
            return(<div>error.message</div>);
        });
};

export const registerUserWithEmailAndPassword = (formData) => {
    return new Promise((resolve, reject) => {
      const auth = getAuth();
  
      createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential) => {
          console.log("Created profile");
  
          updateProfile(auth.currentUser, {
            displayName: formData.firstName + ' ' + formData.lastName,
          })
            .then(() => {
                createContact(formData.email)
                createUserAndRedirect("Email");
                // console.log("Name updated!", auth.currentUser.displayName);
                resolve("User registered and profile updated successfully");
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        })
        .catch((error) => {
            console.log(error)
            // Check if the error is due to an existing user with the same email address
            if (error.code === 'auth/email-already-in-use') {
                reject("Email address is already in use. Please choose a different email.");
            } else {
                reject(error);
            }
        });
    });
  };

export const signInWithEmailAndPasswordPersistent = (email, password) => {
    console.log('Persistent sign in with email');
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            signInWithEmailAndPassword(email, password);
        })
        .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
        });
};

export const signInWithEmailAndPassword = (email, password, errorCallback) => {
    console.log('Signing in with email');
    auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        // Signed in
        window.location.href = '../Projects';
    })
        .catch((error) => {
            if(errorCallback){ errorCallback(error.message); }else {alert(error.message);}
        });
};

export const forgotPassword = (email, sendEmailCallback) => {
    console.log('Forgot password, sending email to '+email);
    auth.sendPasswordResetEmail(email).then((userCredential) => {
        // Send an email
        sendEmailCallback('Email sent to '+email);
        window.location.href = '../Projects';
    })
        .catch((error) => {
            alert(error.message);
        });
};

export const verifyPassword = (email, password, responseCallback) => {
    console.log('Verifying password for '+email);
    auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
        // Signed in
        responseCallback('success');
    })
        .catch((error) => {
            responseCallback(error.message);
        });
};

export const updateEmail = (currentUser, oldEmail, oldPassword, password, errorCallback) => {
    console.log('Updating password for '+oldEmail+' to '+password);
    auth.signInWithEmailAndPassword(oldEmail, oldPassword).then((userCredential) => {
        // Signed in
        auth.currentUser.updatePassword(password).then((userCredential) => {
            // Updated Email
            window.location.href='/Projects';
        })
        .catch((error) => {
            if(errorCallback){ errorCallback(error.message); }else {alert(error.message);}
        });
    })
        .catch((error) => {
            if(errorCallback){ errorCallback(error.message); }else {alert(error.message);}
        });
};

export const getProducts = () => {
    return new Promise((resolve, reject) => {
        let plansWithPrices = [];

        database.products
            .where('active', '==', true)
            .get()
            .then(async function (querySnapshot) {
                for (const doc of querySnapshot.docs) {
                    let plan = doc.data();
                    const priceSnap = await doc.ref.collection('prices').get();
                    let prices = [];
                    priceSnap.docs.forEach((doc) => {
                        const priceData = doc.data();
                        const priceWithId = { id: doc.id, ...priceData };
                        prices = [...prices, priceWithId];
                    });
                    // Sort prices by interval, with 'month' before 'year'
                    prices.sort((a, b) => {
                        if (a.interval === b.interval) {
                            return 0;
                        } else if (a.interval === 'month') {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                    plan.prices = prices;
                    plansWithPrices = [...plansWithPrices, plan];
                }
                resolve(plansWithPrices);
            })
            .catch((error) => reject(error));
    });
};

export const createCheckout = async (price) => {
    console.log(price)

    const docRef = await database.users
        .doc(auth.currentUser.uid)
        .collection('checkout_sessions')
        .add({
            price: price,
            allow_promotion_codes: true,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
    });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        console.log(snap.data())
        if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            console.error(`An error occured: ${error.message}`);
        }
        if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
        }
    });
};

export const openCustomerPortal = async () => {
    const functionLocation = "us-central1"; // us-central1, for example
        const functionRef = firebase
            .app()
            .functions(functionLocation)
            .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        const { data } = await functionRef({ returnUrl: window.location.origin });
        window.location.assign(data.url);
};
