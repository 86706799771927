import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'

export default () => {
  return (
    <NodeViewWrapper>
      <div className='p-1 rounded border-gray text-primary d-inline-block timestamp'>
        <NodeViewContent className="content small" />
      </div>
    </NodeViewWrapper>
  )
}