// src/Tiptap.jsx
import { useState } from 'react'
import { EditorProvider, FloatingMenu, BubbleMenu, useCurrentEditor, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Heading } from '@tiptap/extension-heading'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import React, { useEffect, useRef } from 'react'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import Blockquote from '@tiptap/extension-blockquote'
import Link from '@tiptap/extension-link'
import Timestamp from './Extensions/TimestampExtension.js'
import Insight from './Extensions/InsightExtension.js'
import Audio from './Extensions/AudioExtension.js';
import {Tag} from './Extensions/TagExtension.tsx';

import { MenuBar, PopoverMenu } from './Menu'
import './styles.scss'

import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs'

import { useAuth } from '../../../Contexts/AuthContext.js'
import {useLocation, useParams} from "react-router-dom";
import { v4 } from 'uuid'
import { SecondaryButton } from '../../Buttons.js'
import useFirestoreTags from '../../../Hooks/useTags.js'
import useTags from '../../../Hooks/useTags.js'
import TagMenu from './TagMenu.js'
import { useFile } from '../../../Hooks/useFile.js'

const colors = ['#00b2eb', '#fd8010', '#f63d68', '#22ba5e', '#7b3aeb']
const getRandomElement = list => list[Math.floor(Math.random() * list.length)]
const getRandomColor = () => getRandomElement(colors)

const getNewTag = (content) => {
  return {
    id: `a${v4()}a`,
    content,
    replies: [],
    createdAt: new Date()
  }
}

export const TipTap = ({ doc, provider }) => {
  const { currentUser } = useAuth();
  const { fileId } = useParams();
  const location = useLocation();
  const { CurrentFile } = useFile(fileId, null);

  const CustomDocument = Document.extend({
    content: 'heading block*',
  })

  const editor = useEditor({
    extensions: [
      CustomDocument,
      StarterKit.configure({
        history: false,
      }),
      // Register the Yjs document with Tiptap
      Collaboration.configure({
        document: doc,
      }),
      // Register the collaboration cursor extension
      CollaborationCursor.configure({
        provider: provider,
        user: {
          name: currentUser.displayName,
          color: getRandomColor(),
          id: currentUser.uid,
          user: currentUser,
        },
      }),
      Underline.configure({
        HTMLAttributes: {
          class: 'underline',
        },
      }),
      Blockquote.configure({
        HTMLAttributes: {
          class: 'blockquote',
        },
      }),
      Link.configure({
        autolink: false,
        validate: href => /^https?:\/\//.test(href),
        HTMLAttributes: {
          class: 'hyperlink',
        },
      }),
      Highlight,
      Typography,
      Timestamp,
      Insight,
      Audio,
      Tag.configure(
        {
          HTMLAttributes: {
            class: 'tag'
          },
          onTagActivated: (tagId) => {
            setActiveTagId(tagId)
  
            // if (tagId) setTimeout(() => focusTagWithActiveId(tagId))
          }
        }
      ),
      Heading.configure({
        levels: [1, 2, 3],
      }),
  ]
  });
  const {tags, createTag, deleteTag, addLinkedIdToTag, removeLinkedIdFromTag} = useTags(CurrentFile?.folderId);
  const [activeTagId, setActiveTagId] = useState(null)

  const tagsSectionRef = useRef(null)

  const focusTagWithActiveId = (id) => {
    if (!tagsSectionRef.current) return

    const tagInput = tagsSectionRef.current.querySelector<HTMLInputElement>(`input#${id}`)

    if (!tagInput) return

    tagInput.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    })
  }

  useEffect(
    () => {
      if (!activeTagId) return

      focusTagWithActiveId(activeTagId)
    }
    , [activeTagId]
  )

  const setTag = (tagName) => {
    const newTag = getNewTag('')
    if(tagName.length == 0){
      console.log('No tag name')
      return
    }

    return createTag([newTag.id], { name: tagName, color: getRandomColor() })
      .then((newTagId) => {
        const newTag = getNewTag('');
  
        console.log('creating tag with id', newTagId);
  
        editor?.commands.setTag(newTagId);
        setActiveTagId(newTagId);
  
        setTimeout(() => focusTagWithActiveId());
      })
      .catch((error) => {
        // Handle errors from createTag or other operations
        console.error('Error setting tag:', error);
      });
  };

  const unsetTag = () => {
    console.log('unsetting tag with id', activeTagId)
    if (!activeTagId){
      console.log('No active tag')
      return
    }

    // Remove the tag
    console.log('unsetTag')
    editor?.commands.unsetTag(activeTagId)
    // console.log(activeTagId)
    deleteTag(activeTagId)
    // // Remove the tag from the list
    setActiveTagId(null)
  }

  const onEnableTag = async (tagId) => {
    const newTag = getNewTag('')

    return addLinkedIdToTag(tagId, newTag.id)
      .then((tagId) => {
        editor?.commands.setTag(newTag.id);
        setActiveTagId(newTag.id);
  
        // setTimeout(() => focusTagWithActiveId());
      })
      .catch((error) => {
        // Handle errors from createTag or other operations
        console.error('Error setting tag:', error);
      });
  }

  const onDisableTag = (tagId) => {
    console.log(tagId)
    console.log(activeTagId)
    return removeLinkedIdFromTag(tagId, activeTagId).then((tagId) => {
      // Remove the tag
      console.log('unsetTag')
      editor?.commands.unsetTag(tagId)
      console.log(tagId)
      deleteTag(tagId)
      // Remove the tag from the list
      setActiveTagId(null)

      // setTimeout(() => focusTagWithActiveId());
    })
      .catch((error) => {
        // Handle errors from createTag or other operations
        console.error('Error setting tag:', error);
      });
  }

  return (
    <div className="editor flex-grow-1 d-flex flex-column">
      {editor && <MenuBar editor={editor} />}
      <div className='d-flex flex-row'>
      <EditorContent editor={editor} />
      {
        editor && (
          <>
            {/* Disable this for now since there is no reason to show all tags if they are not rendered through tiptap
            We will render a tag next to the document through the actual tag component */}
            {/* <section className='flex flex-col gap-2 p-2 border-gray border-1' ref={tagsSectionRef}>
              {
                tags.length ? (
                  tags.map(tag => (
                    <div
                      key={tag.id}
                      className={`flex flex-col gap-4 p-2 border rounded-lg border-slate-400 ${tag.id === activeTagId ? 'border-blue-400 border-2' : ''} box-border`}
                    >
                      <span className='flex items-end gap-2'>
                        <span>
                          {tag.tagData?.name}
                        </span>
                      </span>

                      <input
                        value={tag.content || ''}
                        disabled={!tag.linkedIds.includes(activeTagId)}
                        id={tag.id}
                        onInput={
                          (event) => {
                            const value = event.target .value

                            (tags.map(tag => {
                              if (tag.id === activeTagId) {
                                return {
                                  ...tag,
                                  content: value
                                }
                              }

                              return tag
                            }))
                          }
                        }
                        onKeyDown={
                          (event) => {
                            if (event.key !== 'Enter') return

                            setActiveTagId(null)
                          }
                        }
                      />

                      {
                        tag.linkedIds.includes(activeTagId) && (
                          <>
                            <SecondaryButton
                              className=''
                              onClick={() => {
                                setActiveTagId(null)
                                editor.commands.focus()
                              }}
                            >
                              Save
                            </SecondaryButton>
                            <SecondaryButton
                              onClick={() => {
                                unsetTag()
                              }}
                            >
                              Unset
                            </SecondaryButton>
                          </>
                        )
                      }
                    </div>
                  ))
                ) : (
                  <span className='pt-8 text-center text-slate-400'>
                    No tags yet
                  </span>
                )
              }
            </section> */}

            <TagMenu editor={editor} activeTagId={activeTagId} onSetTag={(tagName) => setTag(tagName)} onUnsetTag={() => unsetTag()} onEnableTag={onEnableTag} onDisableTag={onDisableTag}/>
          </>
        )
      }
      </div>
    </div>
  );
};

export default TipTap