import React, { useMemo } from "react";
import { Avatar } from "../Avatar/Avatar.js";
import { useSelf, useOthers, useMyPresence } from "../liveblocks.config.ts";
import styles from "./AvatarStack.module.scss";

const IMAGE_SIZE = 48;

export default function AvatarStack() {
  const users = useOthers();
  const currentUser = useSelf();
  const hasMoreUsers = users.length > 3;

  return (
    <div>
      <div className="d-flex px-3">
        {users.slice(0, 3).map(({ connectionId, presence }) => {
          return (
            <Avatar key={connectionId} presence={presence}/>
          );
        })}

        {hasMoreUsers && <div data-tooltip={users.length - 3 + " Others"} className={styles.more} style={{ height: IMAGE_SIZE, width: IMAGE_SIZE }}>+{users.length - 3}</div>}

        {currentUser && (
          <Avatar presence={currentUser.presence} name={"You"}/>
        )}
      </div>
    </div>
  );
}