import React, { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useFileManager } from "./FileStorageContext";

const BoardContext = React.createContext();

export function useBoard() {
    return useContext(BoardContext)
}

export function BoardProvider({ children }) {
    const { UpdateProject } = useFileManager();
    const navigate = useNavigate();

    const LinkBoard = (folderId, doNavigate = false) => {
        window.miroBoardsPicker.open({
          clientId: '3458764560237355660',
          action: 'select',
          success: (data) => {
            const miro = `https://miro.com/app/live-embed/${data?.id}?embedAutoplay=true`;
            UpdateProject(folderId, {miro: {miroLink: miro, miroBoardId: data?.id}})
            if(doNavigate) navigate(`/Board/${folderId}`); // Replace '/desired-page' with the actual route you want to navigate to
          }
        })
    }

    const CreateCard = (board_id) => {
        
    }

    const value = {
        LinkBoard,
        CreateCard
    };

    return (
        <BoardContext.Provider value={value}>
            {children}
        </BoardContext.Provider>
    )
}
