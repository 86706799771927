import { Stack, Col, Row, Container } from "react-bootstrap";
import { CreateFolderModal } from "../Elements/Modals/Modals";
import { SecondaryButton } from "../Elements/Buttons";
import { Project } from "../Elements/Cards/Project";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { BsFolder, BsHouse, BsPlus } from "react-icons/bs";
import useFolders from "../Hooks/useFolders";
import { useAuth } from "../Contexts/AuthContext";
import { useToast } from "../Contexts/ToastProvider";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Breadcrumbs from "../Elements/Navigation/Breadcrumbs/Breadcrumbs";

export const ProjectsOverview = () => {
    const { folders, allFolders } = useFolders();

    const { plan, currentUser } = useAuth();
    const { Error } = useToast();
    const navigate = useNavigate();
    
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show)

    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const handleCloseCreateFolderModal = () => setShowCreateFolderModal(false);

    const handleCreateFolder = () => {
        if(folders.length >= plan.metadata.maxStorage){
            Error("Change plan to create more projects");
            navigate('/Plans')
            return;
        }
        setShowCreateFolderModal(true);
    }

    const breadcrumbItems = [
        { text: 'Home', link: '/Projects', icon: <BsHouse /> },
        // { text: 'Projects', icon: <BsFolder /> },
    ];

    return(
        <>
            {/* <ContextMenuTrigger id="projects_overview_context_menu"> */}

            <div className={"w-100 bg-white d-flex flex-column vh-100"}>
                {/* <div className="d-flex flex-row justify-content-end align-items-center border-bottom border-1 border-gray p-2 "> */}
                <AvatarHeader disableSearch/>
                <div className={"border-start-0 border-top-0 border-end-0 border-gray position-sticky top-0 bg-dropdown py-3 px-4 d-flex flex-row justify-content-between align-items-center"}  style={{zIndex: 10}}>
                    {/* Stuff such as names, titles and buttons */}
                    <Breadcrumbs items={breadcrumbItems} />

                    <div class="d-flex flex-row gap-1 gap-md-3 h-100" style={{minHeight: '38px'}}>
                        {/* <Share /> */}
                        <SecondaryButton onClick={handleCreateFolder}>
                            <BsPlus size={20}/><div className="d-none d-md-block">&nbsp;Create project</div>
                        </SecondaryButton>

                        <CreateFolderModal show={showCreateFolderModal} onHide={handleCloseCreateFolderModal} />
                    </div>
                </div>
                <div className={"my-auto w-100 flex-grow-1 bg-light overflow-auto"}>
                    <div className="w-100 h-100 d-flex justify-content-center">
                        {/*Content*/}
                        <div className={"flex-grow-1"}>
                        {/* <div className="border-1 border border-gray border-end-0 border-start-0 border-top-0 px-5 py-2 bg-light d-flex flex-row justify-content-between">
                            <div>
                                Name
                            </div>
                            <div>
                                Uploaded
                            </div>
                            <div>
                                Last modified
                            </div>
                            <div>
                                Owner
                            </div>
                        </div> */}
                        {/*TODO: Add overview of projects when there are folders and link is /projects*/}
                        {allFolders.length > 0 ? (
                            //There are folders and give an overview
                            <Stack gap={2} className="m-2">
                                {allFolders?.map((folder) =>
                                    (
                                        <Project Folder={folder} key={folder.id}/>
                                    )
                                    // (<File file={file} onDelete={} key={file.name}>{file.name}</File>)
                                )}
                            </Stack>
                            ) : (
                            //No folders and show screen to create folder
                            <CreateProject/>
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export const CreateProject = () => {
    //TODO: This can go into one .js together with button from navbar
    //This is rendered on /projects and if there are no projects
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <>
            <CreateFolderModal show={show} onHide={handleClose} />

            <Row className="h-100 d-flex flex-column align-items-center m-0">
                <Col className={"d-flex flex-column align-items-center h-100 justify-content-center"}>
                    <h1>Create a new project</h1>
                    <h5>Your interviews can be uploaded there</h5>
                    <br/>
                    <SecondaryButton className={"bg-white"} onClick={handleShow}><BsPlus/>&nbsp; Create Project</SecondaryButton>
                </Col>
            </Row>
        </>
    );
};