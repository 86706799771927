import { collection, query, where, getDocs } from "firebase/firestore";
import { ref, listAll, getMetadata } from "firebase/storage";
import { useAuth} from "../Contexts/AuthContext";
import { useEffect, useState } from "react";
import {storage, database} from "../Services/Firebase";

export function useStorage() {
    const { currentUser } = useAuth();
    const [totalSize, setTotalSize] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (currentUser) {
            setLoading(true);
            async function getTotalSize() {

                const q = database.files.where("userId", "==", currentUser.uid);
                const querySnapshot = await getDocs(q);

                let totalSize = 0;

                for (const doc of querySnapshot.docs) {
                    const fileRef = ref(storage, doc.data().url);
                    const metadata = await getMetadata(fileRef);

                    totalSize += metadata.size;
                }
                setTotalSize(totalSize);
                setLoading(false)
            }

            getTotalSize();
            setLoading(false)
        }
    }, [currentUser]);

    return { totalSize, loading };
}

export function bytesToGB(bytes) {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2);
}
