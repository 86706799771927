import {Card} from 'react-bootstrap';
import React, {Component, useEffect, useState} from "react";
import { Dropdown } from 'react-bootstrap';
import "react-toggle/style.css" // for ES6 modules
import 'react-circular-progressbar/dist/styles.css';
import {FadingHeading} from "../Typography";
import './Cards.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { BsJournalText } from 'react-icons/bs';

export function Guide ({Guide}) {
    let navigate = useNavigate();
    let formattedDate;
    // let formattedTime;

    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    if(Guide.createdAt){
        const date = new Date(Guide.createdAt.seconds * 1000);
        // formattedTime = date.toLocaleTimeString("default");

        const dateObj = new Date();
        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        formattedDate = `${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
    }

    function getOrdinalSuffix(day) {
        const suffixes = ["th", "st", "nd", "rd"];
        const relevantDigits = (day < 30) ? day % 20 : day % 30;
        const suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
        return suffix;
    }

    const onOpen = (e) => {
        e.preventDefault();
        navigate(`/Guide/${Guide.id}`)
    };

    return (
    <>
        <ContextMenuTrigger id={"Guide_context_menu_"+Guide.id}>
        <Card className={"grid-card border-gray shadow-sm"}>
            <Card.Body className={'d-flex flex-column justify-content-between'} onClick={(e) => onOpen(e)}>
                <div className={"d-flex flex-row align-items-baseline justify-content-between"}>
                    <div className={"d-flex flex-column w-100"}>
                        <div className={'d-flex justify-content-start w-100 align-items-center'}>
                            <BsJournalText size={18}/>&ensp;
                            <FadingHeading maxLength={18} text={Guide.name.replace(/\..+$/, "")}></FadingHeading>
                        </div>
                        <div>{formattedDate}</div>
                    </div>
                </div>
                <div className={"d-flex justify-content-between"}>
                    <div className={'w-100 d-flex justify-content-end'}>
                    </div>
                </div>
            </Card.Body>
        </Card>
        </ContextMenuTrigger>

        <ContextMenu id={"Guide_context_menu_"+Guide.id} style={{ zIndex:"1080" }}>
            <Dropdown show>
                <Dropdown.Menu style={{ border: "1px solid lightgray" }}>
                    {/* <Dropdown.Item onClick={() => {renameModal()}}>
                        <BsFillPencilFill/>&ensp;Rename
                    </Dropdown.Item>
                    <Dropdown.Item  onClick={() => {errorModal()}}>
                        <BsTrash/>&ensp;Delete
                    </Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        </ContextMenu>
    </>
    );
}

export default Guide;
