import {Link} from "react-router-dom";
import React, {Component} from "react";
// Get the direct path to the logo.svg
import logo from "./Logo.svg";

export class Logo extends Component {
    render() {
        return (
            <Link to={{pathname: `/`}}>
                <img
                    src={logo}
                    width="auto"
                    height="24"
                    alt="Qanda Logo"
                />
            </Link>
        );
    }
}
