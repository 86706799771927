import { Navigate, Outlet } from 'react-router-dom'
import React from "react"
import { useAuth } from "./Contexts/AuthContext"
import Spinner from 'react-bootstrap/Spinner';
import {SideBar} from "./Elements/Navigation/Navbar/SideBar";
import { UploadProvider } from './Contexts/UploadContext';
import { BoardProvider } from './Contexts/BoardContext';
import { FileProvider } from './Contexts/FileStorageContext';

export const PrivateRoutes = () => {
    // a custom hook to keep track of user's auth status
    const { currentUser, loading } = useAuth();

    return (
        <>
            {
                // display a spinner while auth status being checked
                loading

                    ?   <div className="d-flex align-items-center">
                            <Spinner animation="grow" variant="warning" className={"mx-auto"}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    : currentUser
                    // if user is logged in, grant the access to the route
                    ?
                    <UploadProvider>
                        <FileProvider>
                            <BoardProvider>
                                <div className="d-flex" id="wrapper">
                                    <div id="page-content-wrapper" className={'bg-light'}>
                                        <Outlet />
                                    </div>
                                </div>
                            </BoardProvider>
                        </FileProvider>
                    </UploadProvider>
                    // else render an unauthorised component
                    // stating the reason why it cannot access the route
                    : <Navigate to={'/'} />
            }
        </>
    )
};

export const PublicOnlyRoutes = () => {
    // a custom hook to keep track of user's auth status
    const { currentUser, loading } = useAuth();

    return (
        <>
            {
                // display a spinner while auth status being checked
                loading
                    ? <div className="d-flex align-items-center">
                        <Spinner animation="grow" variant="warning" className={"mx-auto"}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        </div>
                    : !currentUser
                    // if user is logged in, grant the access to the route
                    // note: in this example component is Bar
                    ?
                    <Outlet/>
                    // else render an unauthorised component
                    // stating the reason why it cannot access the route
                    :
                    <Navigate to={'/Projects'} />
            }
        </>
    )
};