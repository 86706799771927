import { useReducer, useEffect } from "react";
import { database } from "../Services/Firebase";
import {ROOT_FOLDER} from "./useFolder";
import {useAuth} from "../Contexts/AuthContext";
import { getDocs } from "firebase/firestore";

const ACTIONS = {
    SET_INSIGHTS: "set-insights",
    UPDATE_INSIGHTS: "update-insights",
};

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.SET_INSIGHTS:
            return {
                insights: payload.insights
            };
        case ACTIONS.UPDATE_INSIGHTS:
            return {
                ...state,
                insights: payload.insights,
            };
        default:
            return state;
    }
}

//TODO: Add folderId to insights through backend
export function useInsights(CurrentFolder = null, limit = null, saved = null) {
    const [state, dispatch] = useReducer(reducer, {
        insights: []
    });

    const { currentUser } = useAuth();

    useEffect(() => {
        const folderId = CurrentFolder ? CurrentFolder.id : null;

        let db = database.queries;
        let query;

        if (currentUser == null || folderId == null) {
            dispatch({ type: ACTIONS.SET_INSIGHTS, payload: { insights: [] } });
            return;
        }

        var memberRef = database.folders.doc(folderId).collection('members').doc(currentUser.uid);

        // Check if the user is a member or is the owner of the folder, then read the files
        memberRef.get().then((doc) => {
            if (doc.exists) {
                console.log('is a member')
                if (folderId !== null) {
                    query = db.where("folderId", "==", folderId).orderBy("createdAt", "desc");
                }
        
                if (folderId !== null & limit !== null) {
                    query = db.where("folderId", "==", folderId).orderBy("createdAt", "desc").limit(limit);
                }
        
                if (folderId !== null & limit !== null && saved !== null) {
                    query = db.where("folderId", "==", folderId).where("saved", "==", saved).orderBy("createdAt", "desc").limit(limit);
                }
        
                if (folderId !== null & limit === null && saved !== null) {
                    query = db.where("folderId", "==", folderId).where("saved", "==", saved).orderBy("createdAt", "desc");
                }
                return query.onSnapshot(snapshot => {
                    dispatch({
                        type: ACTIONS.SET_INSIGHTS,
                        payload: { insights: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) }
                    });
                });
            } else {
                // console.log('is not a member')
                // Check if you are then the owner of the folder, otherwise navigate to a no permission page
                if(CurrentFolder && CurrentFolder?.userId != currentUser.uid) window.location.assign('/no-permission');

                if (folderId !== null) {
                    query = db.where("userId", "==", currentUser.uid)
                    .where("folderId", "==", folderId).orderBy("createdAt", "desc");
                }
        
                if (folderId !== null & limit !== null) {
                    query = db.where("userId", "==", currentUser.uid)
                                .where("folderId", "==", folderId).orderBy("createdAt", "desc").limit(limit);
                }
        
                if (folderId !== null & limit !== null && saved !== null) {
                    query = db.where("userId", "==", currentUser.uid)
                                .where("folderId", "==", folderId).where("saved", "==", saved).orderBy("createdAt", "desc").limit(limit);
                }
        
                if (folderId !== null & limit === null && saved !== null) {
                    query = db.where("userId", "==", currentUser.uid)
                                .where("folderId", "==", folderId).where("saved", "==", saved).orderBy("createdAt", "desc");
                }
        
                return query.onSnapshot(snapshot => {
                    dispatch({
                        type: ACTIONS.SET_INSIGHTS,
                        payload: { insights: snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) }
                    });
                });
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }, [CurrentFolder]);

    return state;
}

export const SaveInsight = async (QueryId)=>{
    // Get the current value of saved from the database
    const querySnapshot = await database.queries.doc(QueryId).get();
    const saved = querySnapshot.get('saved');
    // Update the saved field with the opposite boolean value
    await database.queries.doc(QueryId).set({ saved: !saved }, { merge: true });

    // await database.queries.doc(QueryId).set({ saved: true }, { merge: true });
}

export const AddFeedback = async (QueryId, feedback)=>{
    await database.queries.doc(QueryId).set({feedback: feedback}, { merge: true });
}