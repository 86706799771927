// BreadcrumbComponent.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

export default ({ items }) => {
  return (
    <div className='d-flex flex-row justify-content-start align-items-center m-0 p-0'>
      {items.map((item, index) => (
        <div 
          className={`breadcrumb d-flex flex-row justify-content-start align-items-center m-0 p-0 gap-2 ${index == items.length - 1 ? 'active' : ''}`}
          key={index} >
          {item.icon && item.icon}
          {item.link ? (
            <Link to={item.link}>{item.text}</Link>
          ) : (
            item.text
          )}
          {/* If item is not the last */}
          {index < items.length - 1 && (
            <span className='mx-3'>/</span>
          )}
        </div>
      ))}
    </div>
  );
};