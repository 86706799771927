import Dropdown from 'react-bootstrap/Dropdown';
import {useState} from "react";

const Models = {
    "tiny": "tiny",
    "base": "base",
    "small": "small",
    "medium": "medium",
    "large": "large",
};

function ModelInput({disabled, setModel}) {
    const [model, setMod] = useState('tiny');

    const setNewModel=(newModel)=>{
        setMod(newModel);
        setModel(newModel);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle disabled={disabled} className={'text-capitalize'} variant="light" id="dropdown-basic">
                {model}
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" style={{ maxHeight: "50vh", overflowY:"auto"}}>
                {Object.keys(Models).map((key, index) => (
                    <Dropdown.Item className={'text-capitalize'} key={Models[key]} onClick={() => {setNewModel(key)}}>
                        {Models[key]}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ModelInput;
