import React, {useState} from "react";
import {LogEvent} from "../../Services/Amplitude";
import {Alert, Modal} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Toggle from "react-toggle";
import ModelInput from "../Input/ModelInput";
import SpeakerInput from "../Input/SpeakerInput";
import Button from "react-bootstrap/Button";
import {BsSoundwave, BsFillPencilFill} from 'react-icons/bs';
import LanguageInput from "../Input/LanguageInput";
import { useFileManager } from "../../Contexts/FileStorageContext";
import { ROOT_FOLDER, useFolder } from "../../Hooks/useFolder";

export default function TranscribeModal ({transcribeModal, showTranscribe, File, UpdateFile, CurrentFolder, setErrorMessage}) {
    const [speakers, setSpeakers] = useState([]);
    // const [model, setModel] = useState('tiny');
    const [language, setLanguage] = useState('en-US');
    const [multilingual, setMultilingual] = useState(false);
    const multilingualToggle = () => setMultilingual(!multilingual);

    const OnTranscribe = async (e) => {
        e.preventDefault();
        transcribeModal();
        setSpeakers([]);
        
        UpdateFile(File, CurrentFolder, {transcription: {
                status : ""
            }});

        const transcriptionOptions = {
            speakers: speakers,
            language: language,
            multilingual: multilingual,
            // model: model
        };

        // await fetch(process.env.REACT_APP_API_URL+'api/v1/transcribe', {
        await fetch(process.env.REACT_APP_API_URL+'api/v1/createTranscription', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                file: File,
                options: transcriptionOptions,
            }),
        }).then((res) => {
            LogEvent('Transcription', transcriptionOptions);
            if(!res.ok) setErrorMessage('Error: Transcription failed');
        }).catch((err)=> {
            UpdateFile(File, CurrentFolder,
                {transcription: {
                        status : ""
                    }})
        });
    };

    return (
        <Modal show={showTranscribe} onHide={transcribeModal} size="sm" centered>
            <Form className={"p-4"} onSubmit={OnTranscribe}>
                <Form.Group>
                    <Modal.Title>Transcribe</Modal.Title>
                    <br/>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <div>Multilingual</div>
                        <Toggle onChange={multilingualToggle} />
                    </div>
                    {/* <br/> */}
                    {/* <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <div>Model</div>
                        <ModelInput setModel={setModel}>model</ModelInput>
                    </div> */}
                    <br/>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <div>Language</div>
                        {multilingual ?
                            <LanguageInput setLang={setLanguage} disabled>lang</LanguageInput>
                            :
                            <LanguageInput setLang={setLanguage}>lang</LanguageInput>
                        }
                    </div>
                    <br/>
                    <div>Speakers</div>
                    {/*<NumericInput className="form-control" min={1} max={5} value={numberOfSpeakers} onChange={setNumberOfSpeakers}/>*/}
                    <SpeakerInput max={5} onSpeakerChange={setSpeakers}/>
                    <br/>
                    <div className={"d-flex justify-content-end"}>
                        <div className={"d-flex justify-content-center"}>
                            {speakers < 1 ?
                                <Button variant={"dark"} type="submit" className={"rounded-pill"} disabled><BsSoundwave/>&ensp;Transcribe</Button>
                                :
                                <Button variant={"dark"} type="submit" className={"rounded-pill"}><BsSoundwave/>&ensp;Transcribe</Button>
                            }
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    );
}

export function RenameFileModal ({renameModal, showRename, File, UpdateFile, CurrentFolder}) {
    const [newName, setNewName] = useState('');

    const OnRename = (e) =>{
        e.preventDefault();
        renameModal();
        UpdateFile(File, CurrentFolder, {name: newName})
    };

    return (
        <Modal show={showRename} onHide={renameModal} size="md" centered>
            <Form className={"p-4"} onSubmit={OnRename}>
                <Form.Group >
                    <Modal.Title>Rename</Modal.Title>
                    <br/>
                    <Form.Control placeholder={File.name} onChange={e => setNewName(e.target.value)}/>
                    <br/>
                    <div className={"d-flex justify-content-end"}>
                        <div className={"d-flex justify-content-end"}>
                            <Button variant={"primary"} type="submit"><BsFillPencilFill/>&ensp;Rename</Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    );
}

export function RenameFolderModal ({renameModal, showRename, Folder}) {
    const [newName, setNewName] = useState('');

    const { UpdateProject } = useFileManager();

    const OnRename = (e) =>{
        e.preventDefault();
        renameModal();
        UpdateProject(Folder, {name: newName});
    };

    return (
        <Modal show={showRename} onHide={renameModal} size="md" centered>
            <Form className={"p-4"} onSubmit={OnRename}>
                <Form.Group >
                    <Modal.Title>Rename</Modal.Title>
                    <br/>
                    <Form.Control placeholder={Folder.name} onChange={e => setNewName(e.target.value)}/>
                    <br/>
                    <div className={"d-flex justify-content-end"}>
                        <div className={"d-flex justify-content-end"}>
                            <Button variant={"primary"} type="submit"><BsFillPencilFill/>&ensp;Rename</Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    );
}

export function DeleteFolderModal({Folder, show, setShow}){
    const { DeleteProject } = useFileManager();
    const [checkName, setCheckName] = useState('');

    const Delete = (Folder) =>{
        DeleteProject(Folder);
        setShow(false);
    };

    return(
        <Modal show={show} onHide={setShow} size="md" centered>
            <Form className={"p-4"} onSubmit={() => {Delete(Folder)}}>
                <Modal.Title>Are you absolutely sure?</Modal.Title>
                <br/>

                <Alert variant={"warning"}>
                    Unexpected bad things will happen if you don’t read this!
                </Alert>

                <Form.Group className="py-4" controlId="formProjectTitle">
                    <Form.Label>
                        <b>Warning:</b>
                        <br/>
                        This action cannot be undone.
                        This will permanently delete the {Folder.name} project, all associated files.
                    </Form.Label>
                    <br/>
                    <br/>
                    <Form.Label>Please type "<b>{Folder.name}</b>" to confirm</Form.Label>
                    <Form.Control onChange={e => setCheckName(e.target.value)} type="label" placeholder="Enter project title" />
                </Form.Group>
                <div className={"d-flex justify-content-end"}>
                    { checkName === Folder.name ?
                        <Button variant={"danger"} onClick={() => {Delete(Folder)}} active={true}>Delete Project</Button>
                        :
                        <Button variant={"danger"} disabled>Delete Project</Button>
                    }
                </div>
            </Form>
        </Modal>
    )
}

export function CreateFolderModal({ show, onHide }) {
    const [folderName, setFolderName] = useState('');
    const { CreateProject } = useFileManager();

    const { Folders, CurrentFolder } = useFolder(ROOT_FOLDER.id, ROOT_FOLDER);

    const handleUpload = async (e) => {
        e.preventDefault();
        CreateProject(folderName, CurrentFolder);
        setFolderName("");
        onHide(false);
    };

    return (
    <Modal show={show} onHide={() => onHide(false)} size="md" centered>
        <Form className={"p-4"}>
            <Form.Group>
                <Modal.Title>Create project</Modal.Title>
                <br/>
                <Form.Control onChange={e => setFolderName(e.target.value)} type="label" placeholder="Enter project title" />
                <br/>
                <div className={"d-flex justify-content-end"}>
                    <div className={"d-flex justify-content-end"}>
                        { folderName !== "" && folderName.length > 3?
                            <Button type={"Submit"} role={"submit"} onClick={handleUpload}>Create Project</Button>
                            :
                            <Button variant={"primary"} disabled>Create Project</Button>
                        }
                    </div>
                </div>
            </Form.Group>
        </Form>
    </Modal>
    )
}

export const CreateProjectModal = ({ show, handleClose }) => {
    const [folderName, setFolderName] = useState('');
    const { CreateProject } = useFileManager();

    const { CurrentFolder, Folders } = useFolder(ROOT_FOLDER.id, ROOT_FOLDER);

    const handleUpload = async (e) => {
        e.preventDefault();
        CreateProject(folderName, CurrentFolder); // You may need to pass 'CurrentFolder' as a prop to this component
        console.log("Creating Project", folderName);
        setFolderName("");
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter"  centered>
            <Form className={"p-4"}>
                <Form.Group controlId="formProjectTitle">
                    <Modal.Title>Create project</Modal.Title>
                    <br/>
                    <Form.Control onChange={e => setFolderName(e.target.value)} type="label" placeholder="Enter project title" />
                    <br/>
                    <div className={"d-flex justify-content-end"}>
                        <div className={"d-flex justify-content-end"}>
                            { folderName !== "" && folderName.length > 3?
                                <Button type={"Submit"} onClick={handleUpload}>Create Project</Button>
                                :
                                <Button variant={"primary"} disabled>Create Project</Button>
                            }
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    );
};