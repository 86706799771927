import React, { useContext, useState, useEffect } from "react"
import {
    storage,
    database
} from "../Services/Firebase";

import {useAuth} from "./AuthContext";
import {ROOT_FOLDER, useFolder} from "../Hooks/useFolder";
import {ref, uploadBytesResumable, getDownloadURL, deleteObject, getMetadata} from "firebase/storage";
import {doc, deleteDoc} from "firebase/firestore";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import { saveAs } from 'file-saver';
import {useToast} from "./ToastProvider";
import useFolders from "../Hooks/useFolders";

const FileContext = React.createContext();

export function useFileManager() {
    return useContext(FileContext)
}

export function FileProvider({ children }) {
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const { plan, currentUser } = useAuth();
    const { CurrentFolder, Folders } = useFolder(ROOT_FOLDER.id, ROOT_FOLDER);
    const { Error } = useToast();
    const { folders } = useFolders();
    const navigate = useNavigate();

    const DeleteFile = async (file, CurrentFolder) => {
        try {
            const doc = await database.files.doc(file.id).get();

            // Delete the Firestore document
            await doc.ref.delete();
        } catch (error) {
            // Handle errors
            console.error("Error deleting file:", error);
        }
    };

    //Delete folder & file document and files from storage
    const DeleteProject = async (folderToDelete) => {
        if (folderToDelete === ROOT_FOLDER) return;

        //Delete Folder document from firestore
        database.folders.doc(folderToDelete.id).delete().then((res) => {
            console.log("Deleted folder", folderToDelete.id);
            navigate("/Projects");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };

    const CreateProject = async (folderName, CurrentFolder) => {
        if(folders.length >= plan.metadata.maxStorage){
            Error("Change plan to create more projects");
            return;
        }

        if (CurrentFolder == null) return;
        if (folderName == null) return;

        console.log("Creating folder: ", folderName);

        const path = [...CurrentFolder.path];
        if (CurrentFolder !== ROOT_FOLDER) {
            path.push({ name: CurrentFolder.name, id: CurrentFolder.id })
        }

        const newFolder = await database.folders.add({
            name: folderName,
            parentId: CurrentFolder.id,
            userId: currentUser.uid,
            path: path,
            createdAt: database.getCurrentTimestamp(),
        });
        // navigate(`/Projects/${(newFolder.id)}`);
    };

    const CreateEmptyFile = (CurrentFolder, Files) => {
        return new Promise((resolve, reject) => {
            // If the user is out of bounds with the max transcripts
            if (Files.length + 1 > plan.metadata.maxTranscripts) {
                reject("Please upgrade to upload more files");
                return;
            }
    
            if (CurrentFolder == null) {
                reject("CurrentFolder or file is null");
                return;
            }
    
            // Create a Firestore document with file details
            const fileDocumentRef = database.files.doc();
            const fileDocumentData = {
                name: "New file",
                type: "text/html", // Add the file type here
                createdAt: database.getCurrentTimestamp(),
                folderId: CurrentFolder.id,
                userId: CurrentFolder.userId,
                url: ""
            };
    
            // Add the document reference along with the data to the database
            fileDocumentRef
            .set(fileDocumentData)
            .then(() => {
                // Include the ID in the resolved data
                const fileData = { ...fileDocumentData, id: fileDocumentRef.id };
                resolve(fileData); // Resolve the promise with the data including the ID
            })
            .catch((error) => {
                reject(error); // Reject the promise if there is an error
            });
        });
    };
    

    const UpdateProject = async (folderOrId, data) => {
        if (typeof folderOrId === 'object') {
          // If folderOrId is an object, assume it's a folder and use its ID
          await database.folders.doc(folderOrId.id).set(data, { merge: true });
        } else if (typeof folderOrId === 'string') {
          // If folderOrId is a string, assume it's a folder ID and use it directly
          await database.folders.doc(folderOrId).set(data, { merge: true });
        } else {
          // Handle any other cases or provide an error message
          console.error('Invalid folderOrId type. Expected object or string.');
        }
      };

    const UploadFile = (filesToUpload, CurrentFolder, Files, setPercent) => {
        // If the user is out of bounds with the max transcripts
        if(Files.length + filesToUpload.length > plan.metadata.maxTranscripts) {
            Error("Please upgrade to upload more files")
            return;
        }

        const Upload = (file, CurrentFolder, setPercent) => {
            if (CurrentFolder == null || file == null) return;

            // console.log("file: ", file);
            const id = uuidV4();
            setUploadingFiles(prevUploadingFiles => [
                ...prevUploadingFiles,
                { id: id, name: file.name, progress: 0, error: false },
            ]);
            // Create a Firestore document with file details
            const fileDocumentRef = database.files.doc();
            const fileDocumentData = {
                url: "", // Placeholder for the URL, which will be updated later
                name: file.name,
                type: file.type, // Add the file type here
                createdAt: database.getCurrentTimestamp(),
                folderId: CurrentFolder.id,
                userId: CurrentFolder.userId,
            };
            // Add the document reference along with the data to the database
            fileDocumentRef.set(fileDocumentData)
            .then(() => {
                console.log("Document successfully added!");
                // Now the document reference is added with the data to your database

                const filePath = `/files/${currentUser.uid}/${CurrentFolder.id}/${fileDocumentRef.id}/${file.name}`;
            console.log('Uploading to', filePath);

            const storageRef = ref(storage, filePath);
            console.log(storageRef)
            // progress can be paused and resumed. It also exposes progress updates.
            // Receives the storage reference and the file to upload.
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = snapshot.bytesTransferred / snapshot.totalBytes;
                    setPercent(progress * 100);
                    // console.log(progress)

                    setUploadingFiles(prevUploadingFiles => {
                        return prevUploadingFiles.map(uploadFile => {
                            if (uploadFile.id === id) {
                                return { ...uploadFile, progress: progress }
                            }
                            return uploadFile
                        })
                    })
                },
                () => {
                    setUploadingFiles(prevUploadingFiles => {
                        return prevUploadingFiles.map(uploadFile => {
                            if (uploadFile.id === id) {
                                return { ...uploadFile, error: true }
                            }
                            return uploadFile
                        })
                    })
                },
                () => {
                    setUploadingFiles(prevUploadingFiles => {
                        return prevUploadingFiles.filter(uploadFile => {
                            return uploadFile.id !== id
                        })
                    });
                    console.log(uploadTask.snapshot);

                    getDownloadURL(uploadTask.snapshot.ref).then(url => {
                        console.log(url);
                        database.files
                            .where("name", "==", file.name)
                            .where("userId", "==", CurrentFolder.userId)
                            .where("folderId", "==", CurrentFolder.id)
                            .get()
                            .then(existingFiles => {
                                // Allows for multiple files to be uploaded at
                                const existingFile = existingFiles.docs[0];
                                if (existingFile) {
                                    existingFile.ref.update({ url: url })
                                } else {
                                    database.files.add({
                                        url: url,
                                        name: file.name,
                                        createdAt: database.getCurrentTimestamp(),
                                        folderId: CurrentFolder.id,
                                        userId: CurrentFolder.userId,
                                    })
                                }
                            })

                        // Update the Firestore document with the generated URL
                        fileDocumentRef.update({ url: url })
                        .then(() => {
                            console.log("Document successfully updated!");
                        })
                        .catch(error => {
                            console.error("Error updating document: ", error);
                        });
                    })
                })
            })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
            
        }

        const initialFileProgress = filesToUpload.map(() => 0); // Create an array of 0s with the same length as files
        setPercent(initialFileProgress); // Initialize file progress

        for (let i = 0; i < filesToUpload.length; i++) {
          const file = filesToUpload[i];
          const onUploadProgress = (percentCompleted) => {
            // console.log(file, percentCompleted)
            setPercent(prevFileProgress => {
              const newFileProgress = [...prevFileProgress];
              newFileProgress[i] = percentCompleted;
              return newFileProgress;
            });
          };
          Upload(file, CurrentFolder, onUploadProgress);
        }
    };

    const UpdateFile = (file, CurrentFolder, data) => {
        console.log("Updating File")
        database.files
            .where("name", "==", file.name)
            .where("userId", "==", currentUser.uid)
            .where("folderId", "==", CurrentFolder.id)
            .get()
            .then(existingFiles => {
                const existingFile = existingFiles.docs[0];
                if (existingFile) {
                    existingFile.ref.update(data)
                }
            });
    };

    const DownloadFile = (downloadUrl) => {
        const httpsReference = ref(storage, downloadUrl);

        getDownloadURL(httpsReference)
            .then((url) => {
                getMetadata(httpsReference).then(function(metadata) {
                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        //You can use the blob to append to images, see
                        //https://firebase.google.com/docs/storage/web/download-files#web-version-9_1
                        const blob = xhr.response;
                        const file = new File([blob], metadata.name);
                        saveAs(file);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                }).catch(function(error) {
                    // Handle any errors
                });

            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.log(error.code)
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
            });
    };
    
    const DeleteTranscription = async (file) =>{
         // console.log(CurrentFolder);

         database.files.doc(file.id)
         .collection("transcription_sessions")
         .where("status", "==", "transcribing")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.set({status: "cancelled"}, {merge: true})
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

         database.files.doc(file.id)
            .collection('transcriptions')
            .orderBy("createdAt", "desc") // Order the documents by "createdAt" field in descending order.
                .limit(1) // Limit the results to the latest document.
                .get()
                .then((transcriptionRef) => {
                    if (transcriptionRef.empty) {
                        return;
                    }
                    console.log(transcriptionRef.docs[0].data())
                    const httpsReference = ref(storage, transcriptionRef.docs[0].data().downloadUrl);  
 
                    deleteObject(httpsReference).then(()=>{
                        // File deleted successfully
                        console.log("File deleted successfully: ", transcriptionRef.docs[0].data());
                        transcriptionRef.docs[0].ref.delete();
                    }).catch((error) => {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        // console.log(error.code)
                        switch (error.code) {
                            case 'storage/object-not-found':
                                // File doesn't exist
                                Error("File doesn't exist");
                                break;
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                Error("User doesn't have permission to access the object");
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                Error("User canceled the upload");
                                break;
        
                            // ...
        
                            case 'storage/unknown':
                                // Unknown error occurred, inspect the server response
                                Error("Unknown error occurred");
                                break;
                        }
                 });
         });
    }

    const value = {
        UpdateFile,
        DownloadFile,
        CreateProject,
        CreateEmptyFile,
        UploadFile,
        UpdateProject,
        DeleteProject,
        DeleteFile,
        DeleteTranscription
    };

    return (
        <FileContext.Provider value={value}>
            {children}
        </FileContext.Provider>
    )
}
