import { useEffect, useState } from "react"
import { SecondaryButton } from "../../Buttons"
import { BubbleMenu } from '@tiptap/react'
import { Form } from "react-bootstrap"
import { Mini } from "../../Typography"
import useTags from "../../../Hooks/useTags"
import { useParams } from "react-router-dom"
import './TagMenu.scss'
const TagMenu = ({editor, activeTagId, onSetTag, onUnsetTag, onEnableTag, onDisableTag}) => {
    const { folderId, fileId } = useParams();
    const [tagName, setTagName] = useState('');
    const { tags } = useTags(folderId, 'all');

    useEffect(
        () => {
          if (!activeTagId) return
    
            console.log(activeTagId)
        }
        , [activeTagId]
      )

    if(editor == null) return

    const onChange = (e) => {
        setTagName(e.target.value);
    }

    const submitTag = (e) => {
        e.preventDefault();
        onSetTag(tagName);
        setTagName('');
    }

    return(
        <BubbleMenu keepInBounds editor={editor} className='border rounded border-gray bg-white shadow-sm move-on-appear ' style={{minWidth: '32vw', maxHeight: '32vh'}}>
            <Form onSubmit={(e) => submitTag(e)}>
                <Form.Group className="px-3 pt-3">
                    <Form.Control 
                        className="form-control-sm"
                        onChange={onChange}
                        value={tagName}
                        placeholder="Find or create tag"
                        // You can only set a tag when the editor allow you to
                        // disabled={!editor.can().setTag()}
                    />
                </Form.Group>
                <hr/>
                <Form.Group className="px-3 pb-3">
                    <div className="d-flex flex-column gap-2 overflow-auto px-2"  style={{ maxHeight: '25vh'}}>
                        <Form.Text className="text-dark">
                            Tags
                        </Form.Text>
                        {/* Map tags, filter on input tagName */}
                        {/* Map tags, filter on input tagName */}
                        {tags
                        .filter(tag => tag.tagData.name.toLowerCase().includes(tagName.toLowerCase()))
                        .map(tag => (
                            <TagInput
                            key={tag.id}
                            tag={tag}
                            onEnableTag={onEnableTag}
                            onDisableTag={onDisableTag}
                            enabled={tag.linkedIds.includes(activeTagId)}
                            />
                        ))}
                    </div>
                </Form.Group>
            </Form>
        </BubbleMenu>
    )
}

const TagInput = ({tag, enabled, onEnableTag, onDisableTag}) => {
    // const textColor = getContrastColor(tag.tagData.color) || 'black';

    const onEnable = (e) => {
        if(e.target.checked) {
            onEnableTag(tag.id)
        } else {
            onDisableTag(tag.id)
        }
    }

    return (
        <div className="d-flex flex-row w-100 justify-content-between align-items-center gap-2">
            <div className="p-1 rounded" style={{ backgroundColor: tag?.tagData?.color, color: 'white', minWidth: '72px', fontWeight: '600'}}>
                <Mini>{tag?.tagData?.name}</Mini>
            </div>
            <Form>
                <Form.Check // prettier-ignore
                    type="switch"
                    onChange={onEnable}
                    checked={enabled}
                />
            </Form>
        </div>
    )
}

const getContrastColor = (hexColor) => {
    if(!hexColor) return null;

    // Convert hex color to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
  
    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
    // Choose black or white text based on luminance
    return luminance > 0.5 ? 'black' : 'white';
  };

export default TagMenu