import React, { useState, useEffect } from 'react';
import { Alert, Button, Stack } from 'react-bootstrap';
import { SecondaryButton } from './Buttons';

const Dismissable = (props) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const hasDismissed = localStorage.getItem('hasDismissed ' + props.identifier);
        if (!hasDismissed) {
        setShowAlert(true);
        }
    }, [props.identifier]);

    const handleDismiss = () => {
        localStorage.setItem('hasDismissed ' + props.identifier, 'true');
        setShowAlert(false);
    };
    
    return (
    <>
        {showAlert && (
        <Alert variant={"primary-dark"} onClose={handleDismiss} className={'border border-gray border-1 '+props.className}>
            {props.children}
            <SecondaryButton className="mt-2" onClick={handleDismiss}>
                Dismiss
            </SecondaryButton>
        </Alert>
        )}
    </>
    );
};

export default Dismissable;