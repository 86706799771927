import React, { useState, useEffect, useCallback } from "react";
import "../ProgressIndicator.css";
import {Stack, Dropdown, Container, Col, Row, Modal, Tabs, Card} from "react-bootstrap";
import File from "../Elements/Cards/File";
import {AddData, Share, UploadButton, UploadWrap} from "../Elements/Buttons";
import {useFileManager} from "../Contexts/FileStorageContext";
import {useParams, useLocation, Link, useNavigate, Navigate} from "react-router-dom";
import {ROOT_FOLDER, useFolder} from "../Hooks/useFolder";
import Loading from "../Elements/LoadingSpinner";
import {Insights, SavedInsights, UnsavedInsights} from "./Insights";
import Placeholder from 'react-bootstrap/Placeholder';
import Dismissable from "../Elements/Dismissable";
import { SecondaryButton } from "../Elements/Buttons";
import './FilesOverview.scss';
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import { AvatarHeader } from "../Elements/Navigation/Navbar/Header";
import { useBoard } from "../Contexts/BoardContext";
import Breadcrumbs from "../Elements/Navigation/Breadcrumbs/Breadcrumbs";
import { BsFolder, BsFolder2Open, BsGear, BsHouse } from "react-icons/bs";

export const Shelf = () => {
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const [loading, setLoading] =  useState(true);
    const { CurrentFolder, Files } = useFolder(folderId, null, setLoading);
    const { UpdateProject } = useFileManager();
    const [showSettings, setShowSettings] = useState(false);
    const toggleSettings = () => setShowSettings(!showSettings);
    const { LinkBoard } = useBoard();

    const breadcrumbItems = [
        { text: 'Home', link: '/Projects', icon: <BsHouse /> },
        { text: CurrentFolder?.name, icon: <BsFolder /> },
    ];

    return (
        <ProjectSidebar folderId={folderId}>
            <Loading loading={loading} animation={"grow"}>
                <div className={"w-100 vh-100 bg-white d-flex flex-column"}>
                    <AvatarHeader showToggleSidebar/>
                    <div className={"border-start-0 border-top-0 border-end-0 border-gray position-sticky top-0 bg-dropdown py-3 px-4 d-flex flex-row justify-content-between align-items-center"}  style={{zIndex: 10}}>
                        {/* Stuff such as names, titles and buttons */}
                        <Breadcrumbs items={breadcrumbItems} />
                        <div class="d-flex flex-row gap-1 gap-md-2 h-100">
                            {/* <Share /> */}
                            <Share folderId={CurrentFolder?.id}/>
                            <SecondaryButton className="no-wrap" onClick={toggleSettings}>
                                <BsGear/>
                            </SecondaryButton>
                            <AddData />
                        </div>
                    </div>
                    <div className={"w-100 flex-grow-1 bg-light overflow-auto p-2"}>
                        <Dismissable identifier="files">
                            Keep your research files organized and accessible by storing them in this section. After uploading you will be able to search through it.
                        </Dismissable>
                        <FileList Files={Files}/>
                    </div>
                </div>

                <Modal show={showSettings} onHide={toggleSettings} size="md" centered>
                    <div className="p-4">
                        <Modal.Title>Settings</Modal.Title>
                        <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
                            Link another Miro board
                            <SecondaryButton onClick={() => LinkBoard(folderId, true)}>Link</SecondaryButton>
                        </div>
                    </div>
                </Modal>
            </Loading>
        </ProjectSidebar>
    );
};

export const InsightsShelf = () => {
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const [loading, setLoading] =  useState(true);
    const { CurrentFolder } = useFolder(folderId, null, setLoading);
    const [showSettings, setShowSettings] = useState(false);
    const toggleSettings = () => setShowSettings(!showSettings);
    const { LinkBoard } = useBoard();

    const breadcrumbItems = [
        { text: 'Home', link: '/Projects', icon: <BsHouse /> },
        { text: CurrentFolder?.name, icon: <BsFolder /> },
    ];

    return (
        <ProjectSidebar folderId={folderId}>
            <Loading loading={loading}>
                <div className={"w-100 vh-100 bg-white d-flex flex-column"}>
                    <AvatarHeader showSearch showToggleSidebar/>
                    <div className={"border-start-0 border-top-0 border-end-0 border-gray position-sticky top-0 bg-dropdown py-3 px-4 d-flex flex-row justify-content-between align-items-center"}  style={{zIndex: 10}}>
                        {/* Stuff such as names, titles and buttons */}
                        <Breadcrumbs items={breadcrumbItems} />

                        <div class="d-flex flex-row gap-1 gap-md-3 h-100" style={{minHeight: '38px'}}>
                            {/* <Share /> */}
                            <SecondaryButton className="no-wrap" onClick={toggleSettings}>
                                <BsGear/>
                            </SecondaryButton>
                        </div>
                    </div>
                    <div className={"d-flex flex-column w-100 flex-grow-1 bg-light overflow-auto"}>
                        <div className="flex-grow-1">
                            <SavedInsights  CurrentFolder={CurrentFolder}/>
                            <UnsavedInsights CurrentFolder={CurrentFolder}/>
                        </div>
                    </div>
                </div>
                <Modal show={showSettings} onHide={toggleSettings} size="md" centered>
                    <div className="p-4">
                        <Modal.Title>Settings</Modal.Title>
                        <div className="mt-3 d-flex flex-row justify-content-between align-items-center">
                            Link another Miro board
                            <SecondaryButton onClick={() => LinkBoard(folderId, true)}>Link</SecondaryButton>
                        </div>

                    </div>
                </Modal>
            </Loading>
        </ProjectSidebar>
    );
};

const FileList = ({Files}) => {
    const [isGridView, setIsGridView] = useState(false);

    const handleLayoutChange = (isGrid) => {
      setIsGridView(isGrid);
    };

    return(
    <div className={"d-flex flex-column h-100 gap-2"}>
        {Files.length > 0 ? (
            <Row className="m-0 p-0">
                {Files.map((file) =>
                    (
                    <>
                    <Col className={"p-0"} key={file.id} xs={12} sm={isGridView ? 6 : 12} md={isGridView ? 4 : 12}>
                        {/* Your item rendering code here */}
                        <File File={file} IsGridView={isGridView}>{file.name}</File>
                    </Col>
                    </>
                    )
                )}
            </Row>
            ) :
            (
                
            //No files here
            <Row className="h-100 d-flex flex-column justify-content-center align-items-center m-0">
                <Col className={"d-flex flex-column align-items-center h-100 justify-content-center align-items-center"}>
                    <h3>No data</h3>
                    <div className={"text-muted"}>Get started by adding data first</div>
                    <br/>
                    <AddData />
                </Col>
            </Row>
            )
        }
    </div>
    )
};