import React, {useContext, useEffect, useRef} from "react";
import { useFileManager } from "./FileStorageContext";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFolder } from "../Hooks/useFolder";
import { FileUploader } from "react-drag-drop-files";
import { Button, Form, Modal, Stack } from "react-bootstrap";
import { BsFillCloudUploadFill, BsX } from "react-icons/bs";
import { CiFileOn, CiMusicNote1, CiVideoOn } from "react-icons/ci";
import { Mini } from "../Elements/Typography";
import { BarLoader } from "react-spinners";
import toast, { Toaster } from 'react-hot-toast';
import { useToast } from "./ToastProvider";

const UploadContext = React.createContext();
const fileTypes = ["mp3", "wav", "m4a"];

export function useUploader() {
    return useContext(UploadContext)
}

export function UploadProvider({ children }) {
    const [show, setShowUploader] = useState(false);
    const { Success, SetToasterOffset } = useToast();
    const uploaderRef = useRef(null);

    const showUploader = () => {
        setShowUploader(true)
        const uploaderHeight = uploaderRef.current.offsetHeight;
        SetToasterOffset(uploaderHeight);
        console.log(uploaderHeight)
        // setShowUploader(value);
    }

    const closeUploader = () => {
        setShowUploader(false)
        const uploaderHeight = uploaderRef.current.offsetHeight;
        SetToasterOffset(uploaderHeight);
        console.log(uploaderHeight)
        // setShowUploader(value);
    }

    const value = {
        showUploader,
    };


    return (
        <UploadContext.Provider value={value}>
            {children}
            {/* <ForwardedUploader ref={uploaderRef} show={show} closeUploader={closeUploader}/> */}
        </UploadContext.Provider>
    )
}

function Uploader (props, ref) {
    // State to store uploaded file
    const [files, setFiles] = useState([]);
    const hasFiles = files.length > 0;

    // progress
    const [percent, setPercent] = useState([]);
    
    const { folderId } = useParams();
    const { CurrentFolder, Files } = useFolder(folderId);

    useEffect(() => {console.log(props.showUploader)}, [props.showUploader]);

    function handleUpload(e) {
        e.preventDefault();
        props.UploadFile(files, CurrentFolder, Files, setPercent);

        // const initialFileProgress = files.map(() => 0); // Create an array of 0s with the same length as files
        // setPercent(initialFileProgress); // Initialize file progress
      
        // for (let i = 0; i < files.length; i++) {
        //   const file = files[i];
        //   const onUploadProgress = (percentCompleted) => {
        //     setPercent(prevFileProgress => {
        //       const newFileProgress = [...prevFileProgress];
        //       newFileProgress[i] = percentCompleted;
        //       return newFileProgress;
        //     });
        //   };
        //   UploadFile(file, CurrentFolder, onUploadProgress);
        // }
    }


    if (percent.length > 0 && percent.every(p => p === 100)) {
        props.setShow(false);
        setFiles([]);
        setPercent([]);
    }

    const onChange = (newFiles) => {
        setFiles([...newFiles]);
    };

    const removeFile = (index) => {
        let filteredArr = files.filter((file, num) => num !== index);
        setFiles(filteredArr);
    };

    if(props.show === false) return (<></>);

    return (
        <div ref={ref} {...props} className="position-absolute bottom-0 end-0 px-4">
            <div className="p-4 bg-white border-1 border-gray border-solid shadow-md border-gray rounded-top">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h4>Upload documents</h4>
                <BsX className="cursor-pointer" onClick={props.CloseUploader}/>
            </div>
            <Form>
                    {/*<Form.Group className="" controlId="formProjectTitle">*/}
                    {/*    <Form.Label className={"text-muted"}>Title</Form.Label>*/}
                    {/*    <Form.Control type="label" placeholder="Enter project title"/>*/}
                    {/*</Form.Group>*/}
                    {!hasFiles ?
                        <>
                            <div className="mt-3">
                                <FileUploader
                                    multiple={true}
                                    handleChange={onChange}
                                    name="file"
                                    types={fileTypes}
                                >
                                    <Stack gap={2} className={"p-4 file-upload w-100 rounded border-gray border-dotted d-flex justify-content-center text-center"}>
                                        <input type="file" accept={fileTypes} onChange={onChange}/>

                                        <h1><BsFillCloudUploadFill/></h1>
                                        <div>Drag and Drop files here</div>
                                        <div>or</div>
                                        <b className={"text-primary"}>Browse files</b>
                                    </Stack>
                                </FileUploader>
                            </div>
                            <div className={"pt-2 text-muted"}>Accepted file types: {fileTypes.map(item => `.${item}, `)}</div>
                        </>
                        :
                        <div className={"mt-3"}>
                            {files.map((file, index) =>
                                <File
                                removeFile={removeFile}
                                file={file}
                                index={index}
                                percentCompleted={percent[index]} // pass percent for current file as prop
                                />                            
                            )}
                        </div>
                        }
                    <div className={"pt-3"}>
                        <Button variant={'light'} className={"w-100 rounded border border-gray border1 d-flex flex-row align-items-center justify-content-center"} onClick={handleUpload}>Add files to project</Button>
                    </div>
            </Form>
            </div>
        </div>
    )
}

const ForwardedUploader = React.forwardRef(Uploader);

export function File(props) {
    const file = props.file;
    const index = props.index;
    const removeFile = props.removeFile ? props.removeFile : undefined;

    const fileType = file.type;
    let loading = false;

    if(props.percentCompleted > 0) loading = true;
    if(props.percentCompleted == 100) loading = false;

    const FileIcon = () => {
        if (fileType.includes('audio')) {
            return <CiMusicNote1 />;
        } else if (fileType.includes('video')) {
            return <CiVideoOn />;
        } else {
            return <CiFileOn />;
        }
    }

    const formatFileSize = (bytes) => {
        if (bytes < 1024) {
            return bytes + ' bytes';
        } else if (bytes < 1048576) {
            return (bytes / 1024).toFixed(2) + 'kb';
        } else if (bytes < 1073741824) {
            return (bytes / 1048576).toFixed(2) + 'mb';
        } else {
            return (bytes / 1073741824).toFixed(2) + 'gb';
        }
    }

    return (
        <div key={file.name} 
            className={"p-3 my-3 rounded border border-1 border-gray shadow-sm d-flex flex-column justify-content-between"}>
            <div className="d-flex flex-row justify-content-between align-items-baseline pb-3">
                <Stack direction={"horizontal"} gap={3} className="w-100">
                    {FileIcon()}
                    <Stack direction={"vertical"} gap={0} className="w-100">
                        <div>{file.name}</div>
                        <div className="d-flex flex-row justify-content-between w-100 align-items-end">
                            <div className="text-secondary">{formatFileSize(file.size)}</div>
                            {loading?<Mini>{ Number(props.percentCompleted).toFixed(0)}%</Mini>:<></>}
                        </div>
                    </Stack>
                    
                </Stack>
                <BsX onClick={() => removeFile(index)} className={"text-muted cursor-pointer"}/>
            </div>
            <BarLoader
                    color={"#1E43FF"}
                    loading={loading}
                    size={150}
                    className="w-100"                              
                    aria-label="Uploading"
                    data-testid="Uploading"
                />
        </div>
    );
}