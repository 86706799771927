import { Badge, ProgressBar } from "react-bootstrap"
import { UpgradeButton } from "../Buttons"
import { useAuth } from "../../Contexts/AuthContext";
import { useFolder } from "../../Hooks/useFolder";
import { useParams } from "react-router-dom";
import { useUser } from "../../Hooks/useUser";
import "./PlanUsage.scss";
export default () => {
    // const { folderId } = useParams();
    const { currentUser, plan } = useAuth();
    // const { Files } = useFolder(folderId);
    const { User } = useUser(currentUser?.uid);

    if (!plan) return null;

    const used_transcription_minutes = () => {
        console.log(currentUser)
        return User?.usage_data?.used_transcription_minutes ? User?.usage_data?.used_transcription_minutes : 0
    }

    return(
        <div className="w-100 border-1 border-gray rounded p-3 d-flex flex-column gap-2">
            <div className="w-100 d-flex flex-row justify-content-between align-items-start">
                <h6>Plan</h6>
                <b className="px-2 small rounded" style={{"backgroundColor": "#DFD7D7"}}>{plan.name}</b>
            </div>
            {/* <div className="d-flex flex-column text-muted">
                <div>Files uploaded</div>
                <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                    <div className="small">
                        Total
                    </div>
                    <div className="small">
                        {Files.length}/{plan.metadata.maxTranscripts}
                    </div>
                </div>
                <ProgressBar style={{height: "4px"}} now={Files.length} min={0} max={plan.metadata.maxTranscripts}/>
            </div> */}
            <div className="d-flex flex-column text-muted">
                <div>Transcription minutes</div>
                <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                    <div className="small">
                        Min/mo
                    </div>
                    <div className="small">
                        {used_transcription_minutes()}/{plan.metadata.transcription_hours*60}
                    </div>
                </div>
                <ProgressBar style={{height: "4px"}} now={used_transcription_minutes()} min={0} max={plan.metadata.transcription_hours*60}/>
            </div>
            <UpgradeButton className="w-100 mt-1" />
        </div>
    )
}