import React from "react";

import { BsPlus, BsTrash } from "react-icons/bs";
import useTags from "../../Hooks/useTags";
import { useNavigate, useParams } from "react-router-dom";
import useTagGroups from "../../Hooks/useTagGroup";
import { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Tag } from "./Tag";
import _ from 'lodash';

import "./Cards.scss";
import { OverlayPortal } from "../Modals/Overlay";
import { EditableText } from "../Typography";
import ColorPicker from "../Input/ColorPicker";
import { collaborationColors } from "../../colors";
import { useToast } from "../../Contexts/ToastProvider";

const dimension = '275px';
const colorIconSize = '24px';
export const Cluster = ({clusterId}) =>{
    const { folderId } = useParams();
    const { getTagGroup, updateTagGroup, deleteTagGroup } = useTagGroups(folderId)
    const [cluster, setCluster] = useState(null);
    const [newClusterData, setNewClusterData] = useState(null);
    const { tags } = useTags(folderId, clusterId);
    const { Success, Error } = useToast();

    const [showOverlay, setShowOverlay] = useState(clusterId !== undefined && clusterId === clusterId.id ? true : false);
    const navigate = useNavigate();

    // Save the overlay data
    const saveOverlay = () => {
        console.log(newClusterData)
        // If the tag is not the same as it was before, save it to the DB
        if (!_.isEqual(newClusterData.data, cluster.data)) {
            // Call the updateTag function
            updateTagGroup(cluster.id, newClusterData)
            .then(() => {
                Success(`Tag successfully updated.`);
            })
            .catch((error) => {
                console.error('Error updating tag:', error);
                Error('Error while updating tag, please try again later');
            });
        }
    };

    useEffect(() => {
        if (clusterId) {
          // Use the callback function to update state when the document changes
          const unsubscribe = getTagGroup(clusterId, (tagGroup) => {
            if (tagGroup) {
              setCluster(tagGroup);
              setNewClusterData(tagGroup);
              console.log('Got cluster group from db');
            } else {
              // Handle the case where the document does not exist
              setCluster(null);
              setNewClusterData(null);
              console.log('Cluster not found');
            }
          });
    
          // Cleanup function to unsubscribe from the listener when the component unmounts
          return () => unsubscribe();
        }
      }, [clusterId]);
    
    // do not re-render if the students list reference has not changed
    const InnerList = React.memo(function InnerList(props) {
        if(props.tags) return props.tags?.map((tag, index )=> <Tag tag={tag} key={tag.id} index={index}/>);
    });

    if(cluster === null) return null

    return (
    <div>
        {/* The droppable zone is the whole vertical */}
        <Droppable droppableId={clusterId}>
            {(provided, snapshot) => (
                <div
                innerRef={provided.innerRef}
                // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparant' }}
                {...provided.droppableProps}>
                    <div style={{width: dimension, minHeight: dimension}} 
                    className='d-flex flex-column border-gray border-1 rounded p-3 shadow-sm bg-white'>
                        <div className="d-flex flex-row gap-2 align-items-start" 
                        
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            navigate('/Projects/' + folderId + '/tags/' + clusterId)
                            setShowOverlay(!showOverlay)
                        }}>
                            <div className="rounded" style={{width: colorIconSize, height: colorIconSize, backgroundColor: cluster.data?.color}} />
                            <h5 style={{lineHeight: colorIconSize}}>{cluster.data?.name}</h5>
                        </div>
                        {/* <div>{clusterId}</div> */}

                        <div className="d-flex flex-column gap-2 flex-grow-1"
                        ref={provided.innerRef}>
                            {/* Map tags where clusterId is assigned to this cluster */}
                            <InnerList tags={tags}/>
                        </div>
                    </div>
                {provided.placeholder}
            </div>
            
        )}
        </Droppable>
            {/* Overlay */}
            <OverlayPortal 
            show={showOverlay} 
            onClose={() => {
                setShowOverlay(false)
                navigate('/Projects/' + folderId + '/tags')
                // Call onSave before closing
                saveOverlay();
            }}>
            <ClusterEditor cluster={cluster} onChange={setNewClusterData} 
            onDelete={() => {
              deleteTagGroup(cluster.id); 
              setShowOverlay(false)
            }} 
            tags={tags}/>
        </OverlayPortal>
    </div>
    )
}

export const CreateCluster = ({onCreate}) => {
    return(
    // The droppable zone is the whole vertical
    <Droppable droppableId={'Create'}>
    {(provided, snapshot) => (
        <div
        innerRef={provided.innerRef}
        // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparant' }}
        {...provided.droppableProps}
        >
        <div role='button' className={"d-flex flex-column bg-white border-gray border-1 rounded p-3 me-3 shadow-sm text-primary"} 
            style={{width: dimension, maxHeight: dimension}}
            onClick={onCreate}
            >
            <h5 className="d-flex flex-row align-items-center"><BsPlus />&ensp;<span className="text-underline">New cluster</span></h5>
            <div className="d-flex flex-column gap-2" ref={provided.innerRef}>
                <div className="text-center rounded border-primary py-2 px-3" style={{borderStyle: 'dashed', borderWidth: '2px'}}>
                Drop a card here to create a new cluster
                </div>
            </div>
        </div>
        </div>
            )}
    </Droppable>
    )
}

export const UnsortedCluster = () => {
    const { folderId } = useParams();
    const { tags } = useTags(folderId, null);
    return (
        // The droppable zone is the whole vertical
        <Droppable droppableId={'Unsorted'}>
            {(provided, snapshot) => (
                <div
                innerRef={provided.innerRef}
                // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'transparant' }}
                {...provided.droppableProps}
                >
                    <div className={"d-flex flex-column bg-white border-gray border-1 rounded shadow-sm"} 
                        style={{width: dimension, minHeight: dimension, maxHeight: '100%'}}
                        >
                        <h5 className="d-flex flex-row align-items-center px-3 pt-3"><div>Unsorted</div></h5>
                        <div className="d-flex flex-column gap-2 px-3 pb-3 flex-grow-1"
                            ref={provided.innerRef}>
                            {/* Tags */}
                            {tags.map((tag, index) => <Tag tag={tag} key={tag.id} index={index}/>)}
                        </div>
                    </div>
                    {provided.placeholder}
                </div>
        )}
        </Droppable>
    )
}

const ClusterEditor = ({ cluster, onChange, onDelete, tags = [] }) => {
    const { folderId, clusterId } = useParams();
    const [newCluster, setNewCluster] = useState(cluster);
    const [description, setDescription] = useState(cluster.data.description || '');
  
    useEffect(() => {
      onChange({ ...newCluster, data: { ...newCluster.data, description } });
    }, [newCluster, description]);
  
    return (
      <div className="p-3 h-100 w-100">
        <div className="d-flex flex-row justify-content-between w-100 align-items-center">
          <EditableText 
            editFunc={(e) => setNewCluster({ ...newCluster, data: { ...newCluster.data, name: e }})}
            placeholder={cluster.data.name} 
            />
            
            <BsTrash role='button' className="text-primary" onClick={onDelete}/>
        </div>
        <br />
        <div className="d-flex flex-row gap-2 align-items-center">
          <div
            className="rounded"
            style={{ width: '24px', height: '24px', backgroundColor: newCluster.data.color }}
          />
          <ColorPicker
            value={cluster.data.color}
            onChange={(color) => {
              setNewCluster({ ...newCluster, data: { ...newCluster.data, color } });
            }}
            colors={collaborationColors}
          />
        </div>
  
        {/* Description Text Area */}
        <div className="my-3">
          <b htmlFor="description" className="form-label">
            Description
          </b>
          <textarea
            id="description"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
  
        <div className="d-flex flex-row flex-grow-1 text-center justify-content-center align-items-center h-100">
          <b className="text-gray">
            {tags && tags.length > 0 && <>This cluster has {tags.length} {tags.length > 1 ? 'tags': 'tag'}</>}
          </b>
        </div>
      </div>
    );
  };