import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import Loading, { LoadingSpinner } from "../Elements/LoadingSpinner";
import { SearchInsightsPopup } from "./Insights";
import { BsArrowLeftShort, BsJournalPlus, BsPlus } from "react-icons/bs";
import { EditableText } from "../Elements/Typography";
import { Button, Col, Container, Placeholder, Row } from "react-bootstrap";
import { ROOT_FOLDER, useFolder } from "../Hooks/useFolder";
import { AvatarButton } from "../Elements/Auth/Authentication";
import { SecondaryButton } from "../Elements/Buttons";
import Dismissable from "../Elements/Dismissable";
import { useGuide } from "../Hooks/useGuide";
import { Guide } from "../Elements/Cards/Guide";
import { useFileManager } from "../Contexts/FileStorageContext";

export const GuidesShelf = () => {
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const [loading, setLoading] =  useState(true);
    const { CurrentFolder } = useFolder(folderId, null, setLoading);
    const { UpdateProject } = useFileManager();
    const { Guides, createGuideWithLevels } = useGuide(folderId);

    const CreateGuide = (e) => {
        // Assuming you have a function to create a guide in your hook
        const newGuide = createGuideWithLevels(folderId); // Replace with the actual create function
    }

    return (
        <ProjectSidebar folderId={folderId}>
            <Loading loading={loading}>
                <div className={"w-100 vh-100 bg-white d-flex flex-column"}>
                    <div className="d-flex flex-row justify-content-end align-items-center border-bottom border-1 border-gray p-2 ">
                        <div className={"w-100"}>
                            {/* Search bar for whole folder */}
                            <SearchInsightsPopup/>
                        </div>
                        <AvatarButton className="px-2"/>
                    </div>
                    <div className={"border-bottom border-1 border-gray position-sticky top-0 bg-white"}>
                        {/* Stuff such as names, titles and buttons */}
                        <div className="w-100 h-100">
                        <div className={"d-flex flex-row justify-content-between py-1 px-3 w-100"}>
                            <div className={"d-flex flex-column w-100 p-3"}>
                            {
                                CurrentFolder && CurrentFolder !== null && CurrentFolder !== ROOT_FOLDER? 
                                <>
                                    <Link
                                        to={{
                                            pathname: `/Projects`
                                        }}
                                        state={{ CurrentFolder: null }}>
                                    <div className={"d-flex align-items-center"}><BsArrowLeftShort/>&ensp;Back</div>
                                    </Link>
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <EditableText 
                                        editFunc={(e) => UpdateProject(CurrentFolder, {name: e})}
                                        placeholder={CurrentFolder.name} 
                                        className={'px-5'}
                                        />
                                        <SecondaryButton onClick={CreateGuide}><BsJournalPlus/>&nbsp; Create Guide</SecondaryButton>
                                    </div>
                                </>:
                                <>
                                    <Placeholder xs={12} size="lg" />
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"w-100 flex-grow-1 bg-light overflow-auto"}>
                        <div className="w-100 h-100 p-2">
                            <Dismissable>Here you can create and store interview guides to use in your interviews.</Dismissable>
                            {/* <GuideList CurrentFolder={CurrentFolder} Guides={Guides}/> */}
                            {Guides ? 
                                <GuideList Guides={Guides}/>:
                                <LoadingSpinner/>
                            }
                        </div>
                    </div>
                </div>
            </Loading>
        </ProjectSidebar>
    );
};

const GuideList = ({Guides}) => {
    const { folderId } = useParams();
    const { createGuideWithLevels } = useGuide(folderId);

    const CreateGuide = (e) => {
        // Assuming you have a function to create a guide in your hook
        const newGuide = createGuideWithLevels(folderId); // Replace with the actual create function
    }

    return(
        <Container className={"px-3 py-1 h-100"}>
            {Guides.length > 0 ? (
                    //Map the Guides
                    <Row xs={12} md={6} lg={3}>
                        {Guides.map((guide) =>
                                (
                                    <Col xs={12} md={6} lg={4} className={"p-2"} key={Guide.id}>
                                        <Guide Guide={guide}/>
                                    </Col>
                                )
                            // (<Guide Guide={Guide} onDelete={} key={Guide.name}>{Guide.name}</Guide>)
                        )}
                    </Row>
                ) :
                (
                    //No Guides here
                    <Row className="h-100 d-flex flex-column align-items-center">
                        <Col className={"d-flex flex-column align-items-center h-100 justify-content-center"}>
                            <h1><BsJournalPlus/></h1>
                            <br/>
                            <br/>
                            <h1>Add Guides here</h1>
                            <br/>
                            <SecondaryButton className={"bg-white"} onClick={CreateGuide}><BsJournalPlus/>&nbsp; Create Guide</SecondaryButton>
                        </Col>
                    </Row>
                )
            }
        </Container>
    )
};