import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import { BsLightbulb } from 'react-icons/bs'
import './Insight.scss'

export default () => {
  return (
    <NodeViewWrapper>
      <div className="bg-white rounded border-gray my-3 p-3 d-flex flex-row gap-3 shadow-sm"  contentEditable={false}>
          <div className="bg-light rounded-pill p-3 border-gray d-inline-block text-primary" style={{alignSelf: 'center'}}>
              <BsLightbulb size={32}/>
          </div>
          <div className="d-flex flex-column align-items-center">
              {/* <h5>This is the title</h5> */}
              <NodeViewContent contentEditable={true} className="content small" />
          </div>
      </div>
    </NodeViewWrapper>
  )
}