import { useReducer, useEffect } from "react"
import { useAuth } from "../Contexts/AuthContext"
import {database, storage} from "../Services/Firebase"
import {getDownloadURL, getMetadata, ref} from "firebase/storage";
import {saveAs} from "file-saver";
import { collection, doc, getDoc } from "firebase/firestore";
import { useToast } from "../Contexts/ToastProvider";

const ACTIONS = {
    SELECT_FILE: "select-file",
    UPDATE_FILE: "update-file",
    UPDATE_FILE_START: "update-file-start",
    UPDATE_FILE_FAILED: "update-file-failed",
    UPDATE_FILE_SUCCESS: "update-file-success",
};

export const ROOT_FOLDER = { name: "Root", id: null, path: [] };

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.SELECT_FILE:
            return {
                fileId: payload.fileId,
                CurrentFile: payload.CurrentFile,
                Media: null,
            };
        case ACTIONS.UPDATE_FILE:
            return {
                ...state,
                CurrentFile: payload.CurrentFile,
            };
        case ACTIONS.UPDATE_FILE_SUCCESS:
            return {
                ...state,
                CurrentFile: payload.CurrentFile,
                Media: payload.Media,
                MediaUrl: payload.MediaUrl,
            };
        default:
            return state
    }
}

export function useFile(fileId = null, CurrentFile = null, setLoading = () => {}) {
    
    const [state, dispatch] = useReducer(reducer, {
        fileId: fileId,
        CurrentFile: {},
        Media: null,
        MediaUrl: '',
    });

    const auth = useAuth();
    const {Notify, Error} =  useToast();

    useEffect(() => {
        dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { fileId: fileId, CurrentFile } })
    }, [fileId, CurrentFile]);

    useEffect(() => {
        setLoading(true);
        if (fileId == null) {
            return dispatch({
                type: ACTIONS.UPDATE_FOLDER,
                payload: { CurrentFile: null },
            })
        }

        dispatch({ type: ACTIONS.UPDATE_FILE_START });

        const unsubscribeFileSession = database.files
            .doc(fileId)
            .onSnapshot(async doc => {
                const httpsReference = ref(storage, doc.data().url);
                if(doc.data().url === ""){
                    setLoading(false);
                    return dispatch({
                        type: ACTIONS.UPDATE_FILE_SUCCESS,
                        payload: {
                            CurrentFile: database.formatDoc(doc),
                        },
                    });
                } 
                if(!doc.data().url) return;
                getDownloadURL(httpsReference)
                    .then((url) => {
                        getMetadata(httpsReference).then(function(metadata) {

                            // This can be downloaded directly:
                            const xhr = new XMLHttpRequest();
                            xhr.responseType = 'blob';
                            xhr.onload = (event) => {
                                //You can use the blob to append to images, see
                                //https://firebase.google.com/docs/storage/web/download-files#web-version-9_1
                                const blob = xhr.response;
                                const mediaFile = new File([blob], metadata.name);
                                const mediaUrl = URL.createObjectURL(mediaFile);

                                setLoading(false);

                                dispatch({
                                    type: ACTIONS.UPDATE_FILE_SUCCESS,
                                    payload: {
                                        CurrentFile: database.formatDoc(doc),
                                        Media: mediaFile,
                                        MediaUrl: mediaUrl
                                    },
                                });
                            }
                            xhr.open('GET', url);
                            xhr.send();
                        }).catch(function(error) {
                            setLoading(false);
                            // Handle any errors
                            console.log(error.code)
                        });
                    })
                    .catch((error) => {
                        setLoading(false);
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        console.log(error, auth.currentUser.uid)
                        switch (error.code) {
                            case 'storage/object-not-found':
                                // File doesn't exist
                                break;
                            case 'storage/unauthorized':
                                // User doesn't have permission to access the object
                                break;
                            case 'storage/canceled':
                                // User canceled the upload
                                break;
                            // ...

                            case 'storage/unknown':
                                // Unknown error occurred, inspect the server response
                                break;
                        }
                    });

            });

            return () => {
                unsubscribeFileSession();
              };
    }, [fileId]);

    return state;
}
