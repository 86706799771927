import { useState } from "react";
import "./Overlay.scss"
import { createPortal } from 'react-dom';
import { Outlet } from "react-router-dom";
{/* Use a portal to render the overlay outside the current component */}
export const OverlayPortal = (props) => {
    return(
        <>
        {createPortal (
            <div className="">
                <div onClick={() => props.onClose()} className={`${props.show ? "portal-fade-show show" : "portal-fade-hide"} fade zindex-modal-backdrop position-absolute top-0 end-0 h-100 w-100`}></div>

                <div 
                    className={`${props.show ? "slide-in-left-show" : "slide-in-left-hide"} portal zindex-modal p-3 slide-in-left h-100 bg-white position-absolute top-0 end-0 rounded-start border-1 border-gray border-end-0 border-top-0 border-bottom-0 shadow-sm`}>
                    {props.children}
                </div>
            </div>,
            document.getElementById('overlay-root') // Create a div with this ID in your HTML file
        )}
        <Outlet />
        </>
    )}