import React from "react";
import {Col, Row, Stack} from "react-bootstrap";
import logo from "./Logo.svg";
export function PageNotFound() {
    return (
        <Row className="d-flex p-0 m-0 vh-100 bg-white justify-content-center">
            <Col lg={4} className="d-none d-md-block"/>
            <Col lg={4} className="flex-grow-1 d-flex justify-content-center">
                <Stack gap={4} className="mx-5 my-auto">
                    <a href="/" >
                        <img
                            src={logo}
                            width="125"
                            height="50"
                            alt="Logo"
                        />
                    </a>
                    <div>
                        <h1>404</h1>
                        <h3><b>Oh, No! This Page Does Not Exist</b></h3>
                    </div>
                </Stack>
            </Col>
            <Col lg={4} className="d-none d-md-block"/>
        </Row>
    );
}
