import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Button, Dropdown } from 'react-bootstrap';
import { BsHighlighter, BsHighlights } from "react-icons/bs";
import "./ColorPicker.scss";
export default ({value, onChange = () => {}, colors}) => {
    const [color, setColor] = useState(value || colors[0]);
    const [colorSelected, setColorSelected] = useState(false);

    useEffect(() => {
        if (colorSelected) {
            onChange(color);
            setColorSelected(false); // Reset the flag after calling onChange
        }
    }, [color, colorSelected, onChange]);

    const handleColorChange = (newColor) => {
        setColor(newColor);
        setColorSelected(true); // Set the flag when the color is selected
    };

    return(
        <Dropdown autoClose="outside">
            <Dropdown.Toggle id="dropdown-basic" className="border border-gray border-1 bg-light text-black d-flex align-items-center">
                <BsHighlighter /><div>&ensp;Change color</div>
            </Dropdown.Toggle>

            <Dropdown.Menu className='p-0 m-0 shadow-sm mt-1' style={{ maxWidth: '175px' }}>
                <div className='d-flex flex-column p-2 gap-3'>
                    <div className='d-flex flex-column'>
                        <b>Color Selector</b>
                        <div className='small text-muted'>
                            Select a color
                        </div>
                    </div>
                </div>
                <input type='color' value={color} onChange={(e) => handleColorChange(e.target.value)} className='p-0 m-0 w-100' style={{height: '152px'}}/>
                <div className='p-2'>
                    <div className='d-flex flex-row gap-2'>
                        {/* Map colors */}
                        {colors.map((col, index) => (
                            <div
                                key={index}
                                role='button'
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '12px',
                                    backgroundColor: col,
                                    border: col === color ? '2px solid black' : 'none',
                                }}
                                className='p-0 m-0'
                                onClick={() => handleColorChange(col)} // Use the new handler
                            />
                        ))}
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
};
