import React, { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { LoadingSpinner } from './LoadingSpinner';
import { SecondaryButton } from './Buttons';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { BsChevronDoubleDown, BsChevronDoubleUp, BsChevronDown, BsChevronUp, BsZoomIn, BsZoomOut } from 'react-icons/bs';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl }) => {
    const pdfWrapperRef = useRef(null);
    const pdfNavigatorRef = useRef(null);
    const pdfContainer = useRef(null);
    const pageRef = useRef(null);

    const [wrapperHeight, setWrapperHeight] = useState(null);
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1); // Initial scale
    const [inputPageNumber, setInputPageNumber] = useState(pageNumber); // State for input field
    const [wrapperWidth, setWrapperWidth] = useState(null);
    const [fitToContainer, setFitToContainer] = useState(false);

    const handleFitToContainer = () => {
        setFitToContainer(!fitToContainer);
      };
    
      useEffect(() => {
        const calculateWrapperHeight = () => {
            if (pdfWrapperRef.current && pdfNavigatorRef.current && pdfContainer.current) {
              const totalHeight = pdfContainer.current.getBoundingClientRect().height;
              const headerHeight = pdfNavigatorRef.current.getBoundingClientRect().height;
              const availableHeight = totalHeight - headerHeight;

              console.log(totalHeight, headerHeight, availableHeight)
              setWrapperHeight(availableHeight);
            }
        };

        const setDivSize = () => {
            if (pdfWrapperRef.current) {
                setWrapperWidth(pdfWrapperRef.current.getBoundingClientRect().width);
            }
        };

        calculateWrapperHeight();
        setDivSize();
        window.addEventListener("resize", setDivSize);
    
        return () => {
          window.removeEventListener("resize", setDivSize);
        };
      }, []);

    const onDocumentLoadSuccess = (pdfInfo) => {
        setNumPages(pdfInfo.numPages);
    };

    const handlePageChange = (newPageNumber) => {
        if (newPageNumber >= 1 && newPageNumber <= numPages) {
            setPageNumber(newPageNumber);
            setInputPageNumber(newPageNumber); // Update input field value
        }
    };
    
    const handleInputPageChange = (event) => {
        const newInputPageNumber = parseInt(event.target.value, 10);
        handlePageChange(newInputPageNumber);
    };
    
    const handleInputPageBlur = () => {
        handlePageChange(inputPageNumber);
    };

    const handleZoomIn = () => {
        if(scale + 0.1 < 1.7)
            setScale(scale + 0.1);
    };

    const handleZoomOut = () => {
        if(scale - 0.1 > .6)
            setScale(scale - 0.1);
    };

    return (
    <div className='h-100' ref={pdfContainer}>
        {/* Navigator/Header */}
        <div ref={pdfNavigatorRef} className="bg-light sticky-top py-3 d-flex flex-row justify-content-center align-items-center border-start-0 border-top-0 border-end-0 border-gray border-1 shadow-sm">
            <Stack gap={3} direction={"horizontal"}>
                <Stack direction="horizontal" gap={2}>
                    <SecondaryButton onClick={handleZoomOut}>
                        <BsZoomOut/>
                    </SecondaryButton>
                    <SecondaryButton onClick={handleZoomIn}>
                        <BsZoomIn/>
                    </SecondaryButton>
                </Stack>
                <div className={"border-right border-gray border-1 h-100"} style={{width: "1px"}}/>
                <Stack direction="horizontal" gap={2}>
                    {/* <SecondaryButton onClick={() => handlePageChange(1)}>
                        <BsChevronDoubleDown size={12}/>
                    </SecondaryButton> */}
                    <SecondaryButton onClick={() => handlePageChange(pageNumber - 1)}>
                        <BsChevronDown size={18}/>
                    </SecondaryButton>
                    <div className='d-flex flex-row align-items-center'>
                        <input
                        type="number"
                        value={inputPageNumber}
                        onChange={handleInputPageChange}
                        onBlur={handleInputPageBlur}
                        className="border-none" // This will remove Bootstrap styling
                        min="1"
                        max={numPages}
                        style={{width: "3em"}}
                        /> &nbsp; / {numPages}
                    </div>
                    <SecondaryButton onClick={() => handlePageChange(pageNumber + 1)}>
                        <BsChevronUp  size={18}/>
                    </SecondaryButton>
                    {/* <SecondaryButton onClick={() => handlePageChange(numPages)}>
                        <BsChevronDoubleUp size={12}/>
                    </SecondaryButton> */}
                </Stack>
                <div className={"border-right border-gray border-1 h-100"} style={{width: "1px"}}/>
                <SecondaryButton onClick={handleFitToContainer}>
                    {fitToContainer ? "Fit to Container" : "Fit to Page"}
                </SecondaryButton>
            </Stack>
        </div>

        {/* PDF Container */}
        <div className="overflow-auto w-100 d-flex justify-content-center align-items-center" style={{ height: wrapperHeight }} ref={pdfWrapperRef}>
            <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page ref={pageRef} className={'shadow-sm bg-light'}  loading={<LoadingSpinner />} pageNumber={pageNumber} scale={scale} width={fitToContainer ? wrapperWidth : null} />
            </Document>
        </div>
    </div>
    );
};

export default PdfViewer;
