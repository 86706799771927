import React, {Component, useRef, useState} from "react";
import "./Typography.scss";
import { useEffect } from "react";

export const FadingHeading = ({ maxLength, text }) => {
    const [fade, setFade] = React.useState(false);

    React.useEffect(() => {
        setFade(text.length > maxLength);
    }, [text, maxLength]);

    return (
        <h4>
            {fade? <>{text.substring(0, maxLength)}...</>:<>{text}</>}
        </h4>
    );
};

export const FadingText = (props) => {
    const [fade, setFade] = React.useState(false);

    React.useEffect(() => {
        setFade(props.children.toString().length > props.maxLength);
    }, [props.children, props.maxLength]);

    return (
        <div>
            {fade? <>{props.children.substring(0, props.maxLength)}...</>:<>{props.children}</>}
        </div>
    );
};

export class Mini extends Component {
    render() {
        return (
            <div className={"mini "+this.props.className} {...this.props}>
                {this.props.children}
            </div>
        );
    }
}

export const Cursor = ({blinkDuration = 500}) => {

    return (
          <span
            className="blink"
            style={{ animationDuration: `${blinkDuration}ms` }}
          />
    );
  };

  export const EditableText = (props) => {
    const editFunc = props.editFunc;
    const placeholder = props.placeholder;

    const [text, setText] = useState(placeholder);
    const [editing, setEditing] = useState(false);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef(null);
  
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        if (text.trim().length > 0) {
          editFunc(text);
          setEditing(false);
        }
      }
    };
  
    const handleBlur = () => {
      if (text.trim().length > 0) {
        editFunc(text);
      }
      setEditing(false);
      setFocused(false);
    };
  
    const handleFocus = () => {
      setFocused(true);
    };
  
    const containerStyle = {
        display: "inline-block",
        border: "none",
        margin: 0,
        padding: 0,
        height: "36px",
        borderRadius: 0,
        fontWeight: "500",
        lineHeight: "36px",
        textAlign: "inherit",
        color: "inherit",
        backgroundColor: "transparent",
        cursor: "pointer",
        whiteSpace: "nowrap", // Prevent text from wrapping to the next line
        overflow: "hidden",   // Hide any overflowing text
        textOverflow: "ellipsis", // Show an ellipsis (...) for truncated text
    };
  
    const inputStyle = {
        ...containerStyle,
        border: focused ? "none" : "none",
        width:'100%'
    };
  
    return (
      <h4 style={containerStyle} className={props.className}>
        {editing ? (
          <input
            type="text"
            ref={inputRef}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={inputStyle}
            className={"border-0"+props.className}
          />
        ) : (
          <div onClick={() => {
                setEditing(true);
                inputRef.current.focus();
              }} style={containerStyle}>
            <div style={{ margin: 0 }}>{text}</div>
          </div>
        )}
      </h4>
    );
  };