"use client";

import { useToast } from "../../../../Contexts/ToastProvider.js";
import { useLostConnectionListener } from "../liveblocks.config.ts";
import { useRef } from "react";

export function LostConnectionToasts() {
  const toastId = useRef<string>();
  const { Error, Success } =  useToast();
  useLostConnectionListener((event) => {
    if (event === "lost") {
      // toastId.current = Loading("Still trying to reconnect…");
    } else if (event === "restored") {
      Success("Reconnected", { id: toastId.current });
    } else if (event === "failed") {
      Error("Could not reconnect, please refresh", {
        id: toastId.current,
      });
    }
  });
}