import React, { useState } from 'react';
import {Container, Alert, Button, Col, Form, Row, Stack} from "react-bootstrap";
import {
    registerUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithFacebook,
    signInWithGoogle
} from "../Services/Firebase";
import GoogleButton from 'react-google-button'
import { BsGoogle, BsFacebook } from "react-icons/bs";
import {Mini} from "../Elements/Typography";
import {Logo} from "../Elements/Logo";

const SignIn = () => (
    <Row className="d-flex p-0 m-0 vh-100 bg-white justify-content-center">
        <Col lg={4} className="d-none d-md-block"/>
        <Col lg={4} className="flex-grow-1 d-flex justify-content-center">
            <Stack gap={4} className="mx-5 my-auto">
                <Logo className={"d-flex flex-row justify-content-start align-items-start text-decoration-none p-0"}/>
                <div>
                    <h3><b>Sign in to your account</b></h3>
                </div>
                <Button className="tall-btn" onClick={() => { signInWithGoogle() }}>
                    <BsGoogle/>&ensp; Continue with Google
                </Button>
                <Mini className={"text-muted mx-auto"}>Or sign in with your email</Mini>
                <EmailLogin/>
                <Mini className={"text-muted mx-auto"}>Don't have an account yet? <a href={"/SignUp"}>Get started here</a></Mini>
            </Stack>
        </Col>
        <Col lg={4} className="d-none d-md-block"/>
    </Row>
);

const FacebookLogin = ({label}) => {
    return (
        <Button className="w-50 d-flex align-items-center justify-content-center" style={{background:"#4267B2"}} onClick={()=>signInWithFacebook()}>
            <BsFacebook />&ensp;
            <span>{label?label:'Sign in with Facebook'}</span>
        </Button>
    );
};

const GoogleLogin = ({label}) => {
    return (
        <GoogleButton className="w-50 google brand"
                      type="light" // can be light or dark
                      onClick={() => { signInWithGoogle() }}
                      label={label}
        />
    );
};

const EmailLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [show, setShow] = useState(false);
    const [errorMessage, onErrorMessage] =  useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(email);
        console.log(password);
        signInWithEmailAndPassword(email, password, onErrorMessage);
        if(errorMessage !== ''){
            setShow(true)
        }
    };

    return(
        <Form>
            <Form.Group className="" controlId="loginEmail">
                <Mini>Email address</Mini>
                <Form.Control type="email" placeholder="name@example.com" onChange={e => setEmail(e.target.value)}/>
            </Form.Group>
            <Form.Group controlId="loginPassword">
                <Mini>Password</Mini>
                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
            </Form.Group>
            <Form.Group>
                {show ? (
                    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        {errorMessage}
                    </Alert>
                ):(<div></div>)}
            <Form.Group>
            </Form.Group>
                <Button className="tall-btn mt-5 w-100" type="submit" variant={"light"} onClick={onSubmit} >Sign in</Button>
            </Form.Group>
        </Form>
    );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
