import Dropdown from 'react-bootstrap/Dropdown';
import {useState} from "react";
import { FormControl } from 'react-bootstrap';

const Top10SpokenLanguages = {
    "en-US": "English",
    "cmn-CN": "Mandarin Chinese",
    "es-ES": "Spanish",
    "hi-IN": "Hindi",
    "ar-DZ": "Arabic",
    "ru-RU": "Russian",
    "pt-BR": "Portuguese",
    "id-ID": "Indonesian",
    "bn-BD": "Bengali",
    "ja-JP": "Japanese",
  };

const Languages = {
    "af-ZA": "Afrikaans",
    "sq-AL": "Albanian",
    "am-ET": "Amharic",
    "ar-DZ": "Arabic",
    "az-AZ": "Azerbaijani",
    "eu-ES": "Basque",
    "be-BY": "Belarusian",
    "bn-BD": "Bengali",
    "bs-BA": "Bosnian",
    "br-FR": "Breton",
    "bg-BG": "Bulgarian",
    "ca-ES": "Catalan",
    "hr-HR": "Croatian",
    "cs-CZ": "Czech",
    "da-DK": "Danish",
    "nl-NL": "Dutch",
    "en-US": "English",
    "et-EE": "Estonian",
    "fo-FO": "Faroese",
    "fi-FI": "Finnish",
    "fr-FR": "French",
    "gl-ES": "Galician",
    "ka-GE": "Georgian",
    "de-DE": "German",
    "el-GR": "Greek",
    "gu-IN": "Gujarati",
    "ht-HT": "Haitian Creole",
    "ha-NG": "Hausa",
    "haw-US": "Hawaiian",
    "he-IL": "Hebrew",
    "hi-IN": "Hindi",
    "hu-HU": "Hungarian",
    "is-IS": "Icelandic",
    "id-ID": "Indonesian",
    "it-IT": "Italian",
    "ja-JP": "Japanese",
    "jv-ID": "Javanese",
    "kn-IN": "Kannada",
    "kk-KZ": "Kazakh",
    "km-KH": "Khmer",
    "ko-KR": "Korean",
    "ku-TR": "Kurdish",
    "ky-KG": "Kyrgyz",
    "lo-LA": "Lao",
    "la-001": "Latin",
    "lv-LV": "Latvian",
    "ln-CD": "Lingala",
    "lt-LT": "Lithuanian",
    "lb-LU": "Luxembourgish",
    "mk-MK": "Macedonian",
    "mg-MG": "Malagasy",
    "ms-MY": "Malay",
    "ml-IN": "Malayalam",
    "mt-MT": "Maltese",
    "mi-NZ": "Maori",
    "mr-IN": "Marathi",
    "mn-MN": "Mongolian",
    "my-MM": "Myanmar",
    "ne-NP": "Nepali",
    "nb-NO": "Norwegian Bokmål",
    "nn-NO": "Norwegian Nynorsk",
    "oc-FR": "Occitan",
    "ps-AF": "Pashto",
    "fa-IR": "Persian",
    "pl-PL": "Polish",
    "pt-BR": "Portuguese",
    "pa-IN": "Punjabi",
    "ro-RO": "Romanian",
    "ru-RU": "Russian",
    "sa-IN": "Sanskrit",
    "gd-GB": "Scottish Gaelic",
    "sr-RS": "Serbian",
    "sd-PK": "Sindhi",
    "si-LK": "Sinhala",
    "sk-SK": "Slovak",
    "sl-SI": "Slovenian",
    "so-SO": "Somali",
    "es-ES": "Spanish",
    "su-ID": "Sundanese",
    "sw-KE": "Swahili",
    "sv-SE": "Swedish",
    "tl-PH": "Tagalog",
    "tg-TJ": "Tajik",
    "ta-IN": "Tamil",
    "tt-RU": "Tatar",
    "te-IN": "Telugu",
    "th-TH": "Thai",
    "bo-CN": "Tibetan",
    "tr-TR": "Turkish",
    "tk-TM": "Turkmen",
    "uk-UA": "Ukrainian",
    "ur-PK": "Urdu",
    "uz-UZ": "Uzbek",
    "vi-VN": "Vietnamese",
    "cy-GB": "Welsh",
    "fy-NL": "Western Frisian",
    "xh-ZA": "Xhosa",
    "yi-001": "Yiddish",
    "yo-NG": "Yoruba",
    "zu-ZA": "Zulu",
  };

  function LanguageInput({ disabled, setLang }) {
    const [languageCode, setLanguageCode] = useState('en-US');
    const [filterText, setFilterText] = useState('');
  
    const setNewLanguage = (newLanguageCode) => {
      setLanguageCode(newLanguageCode);
      setLang(newLanguageCode);
    };
  
    const getLanguageName = (langCode) => {
      return Top10SpokenLanguages[langCode] || Languages[langCode];
    };
  
    const filterLanguages = (languages, filterText) => {
      return Object.keys(languages).filter((key) =>
        languages[key].toLowerCase().includes(filterText.toLowerCase())
      );
    };
  
    return (
      <Dropdown className="w-100">
        <Dropdown.Toggle
          disabled={disabled}
          className={'text-capitalize w-100 d-flex flex-row justify-content-between align-items-center'}
          variant="light"
          id="dropdown-basic"
        >
          {getLanguageName(languageCode)}
        </Dropdown.Toggle>
  
        <Dropdown.Menu align="end" className={'w-100 p-2'} style={{ maxHeight: '35vh', overflowY: 'auto' }}>
          <small className="text-muted text-bold px-3">Select a language</small>
  
          {/* Input for filtering */}
          <FormControl
            type="text"
            placeholder="Filter languages"
            className="my-2"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
  
          {filterLanguages(Top10SpokenLanguages, filterText).map((key) => (
            <Dropdown.Item
              className={`text-capitalize ${Top10SpokenLanguages[key] === languageCode ? 'active' : ''}`}
              key={key}
              onClick={() => {
                setNewLanguage(key);
              }}
            >
              {Top10SpokenLanguages[key]}
            </Dropdown.Item>
          ))}
          <hr />
          {filterLanguages(Languages, filterText).map((key) => (
            <Dropdown.Item
              className={`text-capitalize ${Languages[key] === languageCode ? 'active' : ''}`}
              key={key}
              onClick={() => {
                setNewLanguage(key);
              }}
            >
              {Languages[key]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

export default LanguageInput;
