import React, { useEffect, useState } from "react";
import styles from "./Avatar.module.css";
import { getUserById } from "../../../../Hooks/usePublicUser";
/**
 * This file shows how to add live avatars like you can see them at the top right of a Google Doc or a Figma file.
 * https://liveblocks.io/docs/examples/live-avatars
 *
 * The users avatar and name are not set via the `useMyPresence` hook like the cursors.
 * They are set from the authentication endpoint.
 *
 * See pages/api/liveblocks-auth.ts and https://liveblocks.io/docs/api-reference/liveblocks-node#authorize for more information
 */

const IMAGE_SIZE = 48;

export function Avatar({ presence, name }) {
  const [user, setUser] = useState(null);

  const id = presence?.__yjs?.user?.id || null;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUserById(id);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    
    if(id) fetchUser();
  }, [presence, id]);

  if(user == null) return null;
  
  return (
    <div className={styles.avatar} data-tooltip={name ? name : user?.name} style={{ height: IMAGE_SIZE, width: IMAGE_SIZE }}>
      <img
        src={user?.photoURL}
        height={IMAGE_SIZE}
        width={IMAGE_SIZE}
        className={styles.avatar_picture}
        style={presence ? { borderColor: presence?.__yjs?.user?.color, borderWidth: 2, borderStyle: "solid" } : {}}
      />
    </div>
  );
}

export const EmptyAvatar = () => {
  return(
    <div className="px-3">
      <div
        // Set style height and width to image size
        style={{
          height: IMAGE_SIZE,
          width: IMAGE_SIZE,
          // borderColor: "transparent", borderWidth: 2, borderStyle: "solid", 
          // backgroundColor: "lightgray",
          // borderRadius: "9999px"
        }}
      />
    </div>
  )
}