import {Container, Row, Col, Stack, Form, Button, Card, ListGroup, Alert} from 'react-bootstrap';
import React, { useState } from "react";
import { updateEmail } from "../Services/Firebase";
import { useAuth } from "../Contexts/AuthContext"
import { StorageUsage } from "../Elements/Auth/Authentication";
import { FileUploader } from 'react-drag-drop-files';
import { useToast } from '../Contexts/ToastProvider';
import { updateProfilePicture } from '../Hooks/useUser';
import { SettingsSidebar } from '../Elements//Navigation/Navbar/SideBar';
import PlanUsage from '../Elements/Auth/PlanUsage';

const ProfileForm = () => {
    const { currentUser } = useAuth();

    return (
        <SettingsSidebar>
            <Container className={"bg-light m-0"}>
                <Row className="p-0 m-0">
                    <Col id="UpdateStorage" sm={3} className="d-none d-lg-block p-3">
                    </Col>
                    <Col className="flex-grow-1">
                            {/*TODO: Fix height when changing margin here, not fluid with navbar*/}
                            <Stack gap={4} className="my-5 mx-1">
                                <PlanUsage />

                                <hr id="UpdatePhoto"/>

                                <Stack gap={4} className="mx-md-5">
                                    <h3>Storage Usage</h3>
                                    <StorageUsage/>
                                    <p>
                                        Qanda calculates storage based on the number of media files in your account. Each image, video, or audio file adds to your total storage usage, which you can easily track through our interface. Upgrade to receive more storage.
                                    </p>
                                </Stack>

                                <hr id="UpdatePhoto"/>

                                <UpdatePhoto/>

                            </Stack>
                    </Col>
                    <Col sm={3} className="d-none d-lg-block p-3">
                        <ProfileMenu/>
                    </Col>
                </Row>
            </Container>
        </SettingsSidebar>
    );
};

const UpdatePhoto = () => {
    const { UpdateToast } = useToast();
    const fileTypes = ["jpeg", "jpg", "JPG", "png"]; // Accept both image/jpeg and image/jpg files
    const [file, setFile] = useState();
    console.log(file)

    const onChange = (newFile) => {
        // Read the first file from the selected files array
        setFile(newFile);
    };

    const removeFile = () => {
        setFile(null);
    };

    const Upload = async () => {
        UpdateToast(updateProfilePicture(file), {loading: 'Uploading', success: 'Successfully uploaded', error: 'Upload failed'});
    };

    return (
        <Stack gap={4} className="mx-md-5">
            <h3>Update Profile Picture</h3>
            {!file ? (
                <>
                    <div className="mt-3">
                        <FileUploader
                            multiple={false}
                            handleChange={onChange}
                            name="file"
                            types={fileTypes}
                        >
                            <Stack gap={2} className={"p-4 file-upload w-100 rounded border-gray border-dotted d-flex justify-content-center text-center"}>
                                <input type="file" accept={fileTypes.join(',')} onChange={(e) => onChange(e.target.files)}/>
                                <div>Drag and Drop files here</div>
                                <div>or</div>
                                <b className={"text-primary"}>Browse files</b>
                            </Stack>
                        </FileUploader>
                    </div>
                </>
            ) : (
                <div className={"mt-3 d-flex w-100 flex-column justify-content-center"}>
                    <div style={{ height: '200px', width: '200px'}} className="mx-auto align-items-center overflow-hidden rounded">
                        <img className='img-fluid object-fit-cover' src={URL.createObjectURL(file)} alt="Selected" />
                    </div>

                    <div className='d-flex flex-row w-100 py-3 justify-content-center'>
                        <Button variant={'outline-primary'} className={'me-3'} onClick={() => removeFile(null)}>Cancel</Button>
                        <Button onClick={Upload}>Save</Button>
                    </div>
                </div>
            )}
        </Stack>
    );
};


const UpdatePassword = () => {
    const { currentUser } = useAuth();

    const [email, setEmail] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');

    const [show, setShow] = useState(false);
    const [errorMessage, onErrorMessage] =  useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(password);


        updateEmail(currentUser, email, oldPassword, password, onErrorMessage);
        if(errorMessage !== ''){
            setShow(true)
        }
    };

    return(
        <Stack gap={4} className="mx-md-5">
            <Form>

            <h3>Update Password</h3>
            <Form.Group as={Row} className="mb-3">
                <Form.Label>
                    Email
                </Form.Label>
                <Col>
                    <Form.Control type="email" placeholder={currentUser.email} onChange={e => setEmail(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="oldPassword">
                <Form.Label>
                    Old Password
                </Form.Label>
                <Col>
                    <Form.Control type="password" placeholder="Old Password" autoComplete='password' onChange={e => setOldPassword(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="updatePassword">
                <Form.Label>
                    Password
                </Form.Label>
                <Col>
                    <Form.Control type="password" placeholder="Password" autoComplete='' onChange={e => setPassword(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group>
                {show ? (
                    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        {errorMessage}
                    </Alert>
                ):(<div/>)}
            </Form.Group>
            {/*<Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">*/}
            {/*    <Col sm={{ span: 10, offset: 2 }}>*/}
            {/*        <Form.Check label="Sent me a weekly email with the best posts" />*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}
            <Form.Group as={Row} className="mb-3">
                <Col className=" d-flex justify-content-end" sm={{ span: 10, offset: 2 }}>
                    <Button type="submit" onClick={onSubmit}>Save Password</Button>
                </Col>
            </Form.Group>
            </Form>
        </Stack>
    )
};

const UpdateEmail = () => {
    const { currentUser } = useAuth();

    const [email, setEmail] = useState('');

    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');

    const [show, setShow] = useState(false);
    const [errorMessage, onErrorMessage] =  useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(password);

        //TODO: Write update email in firebase

        // updateEmail(currentUser, email, oldPassword, password, onErrorMessage);
        if(errorMessage !== ''){
            setShow(true)
        }
    };

    return(
        <Stack gap={4} className="mx-md-5">
            <Form>
            <h3>Update Email</h3>
            <Form.Group as={Row} className="mb-3">
                <Form.Label>
                    Email
                </Form.Label>
                <Col>
                    <Form.Control type="email" placeholder={currentUser.email} autoComplete="email" onChange={e => setEmail(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label>
                    New Email
                </Form.Label>
                <Col>
                    <Form.Control type="email" placeholder={currentUser.email} autoComplete="" onChange={e => setNewEmail(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                <Form.Label>
                    Password
                </Form.Label>
                <Col>
                    <Form.Control type="password" placeholder="Password" autoComplete='password' onChange={e => setPassword(e.target.value)}/>
                </Col>
            </Form.Group>
            <Form.Group>
                {show ? (
                    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                        {errorMessage}
                    </Alert>
                ):(<div/>)}
            </Form.Group>
            {/*<Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">*/}
            {/*    <Col sm={{ span: 10, offset: 2 }}>*/}
            {/*        <Form.Check label="Sent me a weekly email with the best posts" />*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}
            <Form.Group as={Row} className="mb-3">
                <Col className=" d-flex justify-content-end" sm={{ span: 10, offset: 2 }}>
                    <Button type="submit" onClick={onSubmit}>Save Email</Button>
                </Col>
            </Form.Group>
            </Form>
        </Stack>
    )
};


function ProfileMenu() {
    return (
        <Card style={{position:"sticky", top:"15vh"}}>
            <Card.Header>⚙️&nbsp;Settings</Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item action href="#UpdateEmail">Email</ListGroup.Item>
                <ListGroup.Item action href="#UpdatePassword">Password</ListGroup.Item>
            </ListGroup>
        </Card>
    );
}

export default ProfileForm;
