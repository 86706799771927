import React, {useState, useEffect, useRef} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import Loading from "../Elements/LoadingSpinner";
import {BsFillBookmarkFill, BsBookmark, BsClockHistory, BsX, BsHandThumbsUp, BsHandThumbsDown, BsStars} from "react-icons/bs";
import {useFile} from "../Hooks/useFile";
import {Editor, EditorState, convertToRaw} from 'draft-js';
import { convertFromRaw } from '@wix/draft-js';
import 'draft-js/dist/Draft.css';
import {useToast} from "../Contexts/ToastProvider";
import {FadingText, Cursor, Mini} from "../Elements/Typography";
import {Badge, Col, InputGroup, Modal, Stack, Tabs} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useFolder} from "../Hooks/useFolder";
import {useInsights} from "../Hooks/useInsights";
import "./Insights.scss";
import Form from "react-bootstrap/Form";
import {BsSearch} from "react-icons/bs";
import BarLoader from "react-spinners/BarLoader";
import Collapse from 'react-bootstrap/Collapse';
import { SecondaryButton, SourceButton } from '../Elements/Buttons';
import { SaveInsight, AddFeedback } from "../Hooks/useInsights";
import { useAuth } from "../Contexts/AuthContext";
import { Accordion } from "react-bootstrap";
import Dismissable from "../Elements/Dismissable";
import { SiMiro } from "react-icons/si";
import { useBoard } from "../Contexts/BoardContext";

export function Insights(props) {
    const [loading, setLoading] =  useState(false);
    const { folderId } = useParams();
    const location = useLocation();

    const {CurrentFolder} = useFolder(folderId);

    const { insights } = useInsights(CurrentFolder, 3);
    const { Success, Notify, Error } =  useToast();
    const { currentUser } = useAuth();

    // textInput must be declared here so the ref can refer to it
    let questionInput = React.createRef();

    const [input, setInput] = useState();
    const [fetching, setFetching] = useState(false);
    const [insight, setInsight] = useState();
    const [question, setQuestion] = useState();

    const [suggestedQuestions, setSuggestedQuestions] = useState([
        'What are the main pain points or challenges you encounter while using this product or service?', 
        'How do you typically use this product or service, and what features or functionalities do you use most frequently?', 
        'Can you walk me through a recent experience you had with this product or service? What did you like or dislike about it, and why?']);
    const [recentlyViewed, setRecentlyViewed] = useState([]);

    useEffect(() => {
        if(!insight) return;
        // setFetching(false)
        setInput(insight.query)
    }, [insight]);

    useEffect(() => {
        const queries = insights.map(insight => insight.query);
        setRecentlyViewed(queries)
    }, [insights]);

    const onSubmit = (e) => {
        e.preventDefault();
        askQuestion(input);
    }

    const askQuestion = async (question) => {
        setQuestion(question);
        setFetching(true)

        await fetch(process.env.REACT_APP_API_URL+'api/v1/generateInsight', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                question: question,
                folder: CurrentFolder,
                userId: currentUser.uid,
            }),
        })
        .then(response => response.json())
            .then(res => {
                console.log(res)
            if (res.success) {
                console.log(res.result)
                console.log(res.result.id)
                const mergedData = { id: res.result.id, ...res.result.data };
                console.log(mergedData)
                setInsight(mergedData)
                setFetching(false)
                questionInput.current.focus();
            } else {
                Error(res.error)
                // setInput('');
                setFetching(false)
            }
        })
    }
    
    const onChange = (e) => {
        if(e.target.value === '') { 
            setFetching(false)
            setInsight(null)
        }
        setInput(e.target.value)
    }

    const SuggestedQuestions = () => {
        if(props.showSuggested)
        return(
        <>
            <h4>
                Suggested questions
            </h4>
            <ol>
                <Stack gap={3}>
                {suggestedQuestions.map((suggestedQuestion, index) => (
                <h6 className="cursor-pointer text-primary" onClick={() => askQuestion(suggestedQuestion)} key={index}>
                    <li>{suggestedQuestion}</li>
                </h6>
                ))}
                </Stack>  
            </ol>
        </>
        )
    }

    const SavedQuestions = () => {
        if(props.showSaved)
        return(
            <>
                <h4>
                    Recently viewed
                </h4>
                <ol>
                    <Stack gap={3}>
                    {recentlyViewed.map((recentlyViewed, index) => (
                        <h6 className="cursor-pointer text-primary" onClick={() => setInsight(insights[index])} key={index}>
                            <li>{recentlyViewed}</li>
                        </h6>
                        ))}  
                    </Stack>  
                </ol>
            </>
        )
    }

    return (
        <Loading loading={loading}>
            <div className={props.bg}>
                <Dismissable identifier="insights">
                    Ask questions here and get accurate responses based on the transcriptions made. You can save the answers for later reference, making it easy to find the answers you need whenever you need them.                 
                </Dismissable>
                {!insights ?
                    <>No insights</> :
                    //There are folders and give an overview
                    <Stack gap={2}>
                        {/* <Stack gap={2} direction="horizontal">
                            <Button onClick={() => setAnswer('')}>Clear answers</Button>
                            <Button onClick={() => setAnswer('Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.')}>Add answers</Button>
                            <Button onClick={() => clearDB()}>Clear database</Button>
                            <Button onClick={() => getCollections()}>Get Collections</Button>
                        </Stack> */}
                        <form onSubmit={onSubmit}>
                            <InputGroup className="py-2">
                                <Form.Control
                                    type="search"
                                    placeholder="Ask a question to get insights..."
                                    value={input}
                                    aria-label="Search"
                                    style={{ borderColor: "rgb(222, 226, 230)" }} // set the border color to gray
                                    onChange={(e) => onChange(e)}
                                    onAbort={(e) => onChange(e)}
                                    ref={questionInput}
                                />
                                <Button type="submit" className={'d-flex flex-row justify-content-center align-items-center'}>
                                    <BsSearch/>&ensp;
                                    Search
                                </Button>
                            </InputGroup>
                        </form>
                        <>
                        <div className={`bg-white ${props.showSaved || props.showSuggested ? 'border border-1 border-gray p-3 rounded' : 'rounded'}`}>
                            {fetching && question?
                                <>
                                    <h4>
                                        {question}
                                    </h4>
                                    <p>
                                        Gathering sources...<Cursor/>
                                    </p>
                                    <BarLoader
                                        color={"#001aca"}
                                        loading={fetching}
                                        size={150}
                                        className="w-100"                              
                                        aria-label="Loading"
                                        data-testid="loading"
                                    />
                                </> 
                                :
                                <>
                                    {insight ?
                                    <Insight insight={insight}/>
                                    :
                                    <Stack gap={3}>
                                        <SuggestedQuestions/>
                                        
                                        <SavedQuestions/>
                                    </Stack>
                                    }
                                </>   
                            }
                        </div>
                        </>
                    </Stack>
                }
            </div>
        </Loading>
    );
}

function Answers(props) {
    const { folderId } = useParams();
    const { Files } = useFolder(folderId);

    const [selected, setSelected] = useState(-1);
    const [openText, setOpenText] = useState(false);
    const [answerContent, setAnswerContent] = useState('');

    function truncateText(text, maxLength = 250) {
        if (text.length <= maxLength) {
          return text;
        }
        return text.slice(0, maxLength) + '...';
    }


    const toggleSelected = (index) => {
        if(selected !== index){
            setOpenText(true)
            setSelected(index)
            setAnswerContent(props.sources[index].text)
        } else{
            setOpenText(false)
            setSelected(-1)
        }
    }
    
    const filteredFiles = Files.filter(file => props.sources.some(source => source.file_id === file.id));
    
    return (
      <Stack gap={3}>
        <div className="d-flex flex-row justify-content-between">
          <Stack gap={3} direction="horizontal">
            {filteredFiles.map((file, index) => (
                    <SourceButton key={index} 
                        onClick={(e) => toggleSelected(index)} 
                        aria-expanded={openText}
                        aria-controls="collapse-text"
                        selected={selected === index? true : false}
                        index={index}>
                            <FadingText maxLength={12}>{file.name}</FadingText>
                            <sup>{index}</sup>
                    </SourceButton>
            ))}
          </Stack>
          {props.children}
        </div>
            <Collapse in={openText} dimension="height">
                <div id="collapse-text">
                    {truncateText(answerContent)}
                    {/* {selected > -1 ? <>...{props.sources[selected].text}...</>:<></>} */}
                </div>
            </Collapse>
      </Stack>
    );
  }

const Insight = ({insight}) => {
    const { Success, Notify } =  useToast();
    const { CreateCard } = useBoard();
    if(!insight) return;

    const sources = insight.sources;
    const answer = insight.result;
    const question = insight.query;

    const giveFeedback = (wasUseful) => {
        AddFeedback(insight.id, wasUseful).then(()=>{
            Success('Thank you for you feedback');
        })
    }

    const Save = (e) => {
        e.preventDefault();
        SaveInsight(insight.id).then(()=>{
            Notify(<div className="d-flex flex-row align-items-center justify-content-center"><BsFillBookmarkFill className="text-secondary"/>&ensp;Bookmarked</div>);
        })
    }

    return(
        <>
            <h4>
                {question}
            </h4>
            <p>
                {answer}
            </p>
            <p>
                Was this response useful?
            </p>
            <Stack gap={3} direction="horizontal">
                <Button variant="light" onClick={(e) => giveFeedback(true)}><BsHandThumbsUp size={12}/>&ensp;Yes</Button>    
                <Button variant="light" onClick={(e) => giveFeedback(false)}><BsHandThumbsDown size={12}/>&ensp;No</Button>
            </Stack>
            <hr/>
            <Answers sources={sources}>
                <Stack direction="horizontal" gap={3}>
                    {/* <SecondaryButton
                            onClick={CreateCard} 
                            className="d-flex flex-row align-items-center justify-content-center">
                        <SiMiro/>&ensp;Add
                    </SecondaryButton> */}
                    <SecondaryButton
                            onClick={(e) => Save(e)} 
                            className="d-flex flex-row align-items-center justify-content-center">
                        <BsFillBookmarkFill/>&ensp;Bookmark
                    </SecondaryButton>
                </Stack>
            </Answers>
        </>
    )
  }

export function SavedInsights({CurrentFolder}) {
    const [loading, setLoading] =  useState(false);
    // const { fileId } = useParams();
    // const location = useLocation();
    const { insights } = useInsights(CurrentFolder, null, true);

    return (
        <Loading loading={loading}>
            <div className={'bg-light p-4'} >
            <Stack gap={3}>
                    <h4 className="d-flex flex-row align-items-center px-3">
                        <BsBookmark/>&ensp;Bookmarked
                    </h4>
                    <Stack gap={2}>
                    {insights.map((insight, index) =>(
                        <Accordion defaultActiveKey={-1}>
                            <SavedInsight insight={insight} index={index} key={index}/>
                        </Accordion>
                    ))}
                    </Stack>
                </Stack>
            </div>
        </Loading>
    );
}

export function UnsavedInsights({CurrentFolder}) {
    const [loading, setLoading] =  useState(false);
    // const { fileId } = useParams();
    // const location = useLocation();
    const { insights } = useInsights(CurrentFolder);

    return (
        <Loading loading={loading}>
            <div className={'bg-light p-4'}>
                <Stack gap={3}>
                <h4 className="d-flex flex-row align-items-center px-3">
                        <BsClockHistory/>&ensp;History
                    </h4>
                    <Stack gap={2}>
                    {insights.map((insight, index) =>(
                        <Accordion defaultActiveKey={-1}>
                            <SavedInsight insight={insight} index={index} key={index}/>
                        </Accordion>
                    ))}
                    </Stack>
                </Stack>
            </div>
        </Loading>
    );
}

export const SavedInsight = ({insight, index}) => {
    const { Success, Notify, Error } =  useToast();

    if(!insight) return;

    const sources = insight.sources;
    const answer = insight.result;
    const question = insight.query;

    const giveFeedback = (wasUseful) => {
        AddFeedback(insight.id, wasUseful).then(()=>{
            Success('Thank you for you feedback');
        })
    }

    const Save = (e) => {
        e.preventDefault();
        SaveInsight(insight.id).then(()=>{
            Notify(<div className="d-flex flex-row align-items-center justify-content-center"><BsFillBookmarkFill className="text-secondary"/>&ensp;Bookmarked</div>);
        })
    }

    const OnCopy = (e) => {
        e.preventDefault();
        const answerToCopy = insight.result;
        navigator.clipboard.writeText(answerToCopy).then(() => {
          Success('Copied to clipboard');
        }).catch((error) => {
          Error('Failed to copy answer');
        });
    }

    return(
        <Accordion.Item eventKey={index}>
            <Accordion.Header>
                <div className="text-primary">
                    {question}
                </div>
            </Accordion.Header>
            <Accordion.Body>
            <div className="d-flex flex-row justify-content-between align-items-start">
                <p>
                    {answer}
                </p>
                <Mini className="text-primary cursor-pointer" onClick={OnCopy}>
                    Copy
                </Mini>
            </div>
            <hr/>
            <Answers sources={sources}>
                <SecondaryButton
                        onClick={(e) => Save(e)} 
                        className="d-flex flex-row align-items-center justify-content-center">
                    <BsX size={20}/>&nbsp;Remove
                </SecondaryButton>
            </Answers>
            </Accordion.Body>
        </Accordion.Item>
    )
  }

export const SearchInsightsPopup = () => {
    const [showModal, setShowModal] = useState(false);
    const [input, setInput] = useState('');
  
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === 'k') {
                e.preventDefault()
                handleOpen();
            }
        };
    
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
  
    const handleClose = () => setShowModal(false);
    const handleOpen = () => setShowModal(true);

    return (
      <>
        <form onClick={handleOpen}>
            {/* For screens bigger than medium */}
            <div className="d-none d-lg-block">
                <InputGroup className="py-2 px-4 d-flex flex-row justify-content-between align-items-center cursor-pointer text-muted bg-light w-50 rounded-pill border border-gray text-nowrap overflow-hidden">
                    <div className="d-flex flex-row align-items-center text-nowrap overflow-hidden"><BsStars/>&ensp;&ensp;Ask a question to get insights...</div>
                    <Mini className="px-1 bg-white border-gray rounded d-none d-xl-block">Ctrl K</Mini>
                </InputGroup>
            </div>
            {/* For screens smaller than large */}
            <div className="d-lg-none">
                <InputGroup InputGroup className="py-2 px-4 d-flex flex-row align-items-center cursor-pointer text-muted bg-light w-100 rounded-pill border border-gray text-truncate">
                    <BsStars/>&ensp;Generate insights...
                </InputGroup>
            </div>
        </form>
        <Modal size={'lg'} show={showModal} onHide={handleClose}>
            <Modal.Body>
                <Insights showSuggested input={input} bg={'none'}/>
            </Modal.Body>
        </Modal>
      </>
    );
  };

export default Insights;
