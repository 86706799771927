import React, { useContext, useState, useEffect } from "react"
import { auth, database } from "../Services/Firebase"
import {freePlan} from "../freePlan";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [plan, setPlan] = useState(freePlan);

    useEffect(() => {
        setLoading(true);

        const unsubscribeAuthStateChange = auth.onAuthStateChanged(user => {
            setPlan(freePlan);
            setIsSubscribed(false)
            setCurrentUser(user);
            setLoading(false);
        });

        if(!currentUser){
            setIsSubscribed(false);
            return;
        }

        // Retrieve subscription data from Firestore
        const unsubscribeSubscription = database.users
            .doc(currentUser.uid)
            .collection('subscriptions')
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(subscription => {
                // If there is no subscription attached to the user
                if(subscription.docs.length === 0) {
                    setPlan(freePlan);
                    setIsSubscribed(false);
                    return;
                }
                const data = subscription.docs[0].data();
                // Retrieve the product data from Firestore
                database.products.doc(data.product.id).get().then((product) => {
                    setPlan(product.data()); // set the plan property of the data object to the state variable
                    setIsSubscribed(true);
                    setLoading(false);
                });
            })

        return () => {
            unsubscribeAuthStateChange();
            unsubscribeSubscription();
          };
    }, [currentUser]);

    const value = {
        currentUser,
        plan,
        isSubscribed,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
