import {Card, Col, Modal, Row, ToggleButtonGroup} from 'react-bootstrap';
import React, {Component, useEffect, useState} from "react";
import {useFileManager} from "../../Contexts/FileStorageContext";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { Dropdown, Stack, Spinner } from 'react-bootstrap';
import "react-toggle/style.css" // for ES6 modules
import 'react-circular-progressbar/dist/styles.css';
import {BsSoundwave, BsEye, BsThreeDotsVertical, BsArrowRepeat, BsTrash, BsCloudDownload, BsFillPencilFill, BsBoxArrowUpRight, BsJournalText, BsFileEarmarkPdf} from 'react-icons/bs';
import TranscribeModal from "../Modals/Modals";
import {FadingHeading, Mini} from "../Typography";
import {useToast} from "../../Contexts/ToastProvider";
import './Cards.scss';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useFolder} from "../../Hooks/useFolder";
import {useTranscript} from "../../Hooks/useTranscript";
import { RenameFileModal } from '../Modals/Modals';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { timeAgo } from '../../Hooks/useDate';
export function File ({File, IsGridView}) {
    let navigate = useNavigate();
    const { Error } = useToast();
    const { folderId } = useParams();
    const { location = {} } = useLocation();
    const [loading, setLoading] =  useState(false);
    const { CurrentFolder } = useFolder(folderId, location.folder, setLoading);
    const { DeleteFile, UpdateFile, DownloadFile, DeleteTranscription} = useFileManager();

    const [showError, setShowError] = useState(false);
    const errorModal = () => setShowError(!showError);

    const [showRename, setShowRename] = useState(false);
    const renameModal = () => setShowRename(!showRename);

    const onDelete = (e) => {
        e.preventDefault();
        // console.log("Deleting file: ", File.name);
        // console.log("From folder: ", CurrentFolder.name);
        DeleteFile(File, CurrentFolder);
    };

    
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    const dateToStringFormat = (date) => {
        if(date == undefined) return null

        const dateObj = new Date(date.seconds * 1000);
        // formattedTime = date.toLocaleTimeString("default");

        const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
        const day = dateObj.getDate();
        const month = monthNames[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        return`${month} ${day}${getOrdinalSuffix(day)}, ${year}`;
    }

    function getOrdinalSuffix(day) {
        const suffixes = ["th", "st", "nd", "rd"];
        const relevantDigits = (day < 30) ? day % 20 : day % 30;
        const suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
        return suffix;
    }

    const onOpen = (e) => {
        e.preventDefault();
        navigate(`/Projects/${File.folderId}/file/${File.id}`, { CurrentFolder: CurrentFolder, CurrentFile: File })
    };

    const Icon = () =>{
        if(File?.type == 'text/html') return <BsJournalText />
        if(File?.type == 'application/pdf') return <BsFileEarmarkPdf />
        else return null
    }

    return (
    <>
        <ContextMenuTrigger id={"file_context_menu_"+File.id}>
            {IsGridView ? (
                <Card className={"grid-card border-gray shadow-sm"}>
                    <Card.Body className={'d-flex flex-column justify-content-between'} onClick={(e) => onOpen(e)}>
                        <div className={"d-flex flex-row align-items-baseline justify-content-between"}>
                            <div className={"d-flex flex-column"}>
                                <FadingHeading maxLength={24} text={File.name.replace(/\..+$/, "")}></FadingHeading>
                                <div className="d-flex flex-column align-items-start">
                                    <Mini>Uploaded {timeAgo(File?.createdAt)}</Mini>
                                    {/* { File?.lastEdited ? <></> : <Mini>Edited {timeAgo(File?.lastEdited)}</Mini> } */}
                                </div>
                                {/* <Mini>Format .{parsedFileType.format}</Mini> */}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            ) : (
          <Card className={"border border-1 border-gray shadow shadow-sm cursor-pointer mb-2"} variant={"white"}>
            <div className="d-flex py-3 px-4 flex-row align-items-center justify-content-between" onClick={(e) => onOpen(e)}>
              <div className={"d-flex flex-row align-items-center"}>
                {<Icon/>}<div>&ensp;{File?.name}</div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <Mini>Uploaded {timeAgo(File?.createdAt)}</Mini>
                {/* { File?.lastEdited ? <></> : <Mini>Edited {timeAgo(File?.lastEdited)}</Mini> } */}
              </div>
            </div>
          </Card>
        )}
        </ContextMenuTrigger>
        <RenameFileModal renameModal={renameModal} showRename={showRename} File={File} UpdateFile={UpdateFile} CurrentFolder={CurrentFolder}/>

        <ErrorModal errorModal={errorModal} showError={showError} File={File} onDelete={onDelete}/>

        <ContextMenu id={"file_context_menu_"+File.id} style={{ zIndex:"1080" }}>
            <Dropdown show>
                <Dropdown.Menu style={{ border: "1px solid lightgray" }}>
                    <Dropdown.Item onClick={() => {renameModal()}}>
                        <BsFillPencilFill/>&ensp;Rename
                    </Dropdown.Item>
                    {/* <Dropdown.Item  onClick={() => {DeleteTranscription(File)}}>
                        <BsArrowRepeat/>&ensp;Redo Transcription
                    </Dropdown.Item> */}
                    <Dropdown.Item  onClick={() => {errorModal()}}>
                        <BsTrash/>&ensp;Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </ContextMenu>
    </>
    );
}

export function MoreActionsDropdown({renameModal, errorModal, File}){

    const { DeleteTranscription } = useFileManager();

    return (
        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <Dropdown.Item onClick={() => {renameModal()}}>
                <BsFillPencilFill/>&ensp;Rename
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {DeleteTranscription(File)}}>
                <BsArrowRepeat/>&ensp;Redo Transcription
            </Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item onClick={() => {errorModal()}}>
                <BsTrash/>&ensp;Delete
            </Dropdown.Item>
        </ul>
    )
}

export function ErrorModal ({errorModal, showError, File, onDelete}) {
    return (
        <Modal show={showError} onHide={errorModal} size="md" centered>
            <Form className={"p-4"} onSubmit={onDelete}>
                <Form.Group>
                    <Modal.Title>Delete forever?</Modal.Title>
                    <br/>
                    <div>"<b>{File.name}</b>" will be deleted forever and you won't be able to restore it.</div>
                    <br/>
                    <div className={"d-flex justify-content-end"}>
                        <div className={"d-flex justify-content-end"}>
                            <Button variant={"danger"} type="submit">Delete forever</Button>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    );
}

export default File;
