import { Stack, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Logo } from "../../../Elements/Logo";
import { Header } from "../../../Elements/Navigation/Navbar/Header";
import { Footer } from "../../LandingPage";
import { useState } from "react";
import { BsSlack, BsMedium, BsInstagram, BsTwitter } from "react-icons/bs";
import EmailSignupForm from "../../../Elements/EmailSignupForm";

export default function Contact() {
    return (
        <div className="flex-column d-flex h-100">
            <Header/>
            <Container className="flex-grow-1 d-flex flex-column">
                <Row>
                    <Col md={2}/>
                    <Col md={4}>
                        <EmailForm/>
                    </Col>
                    <Col md={4}>
                        <ContactDetails/>
                    </Col>
                    <Col md={2}/>
                </Row>
            </Container>
            <Footer/>
        </div>
    );
}

const EmailForm = () => {
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      const emailUrl = `mailto:jasperkense@qanda.design?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
  
      window.open(emailUrl, "_blank");
    };
  
    return (
      <Form onSubmit={handleSubmit} className="pt-5">
        <h3>Contact</h3>
        <div>
            We would love to hear from you! We hope to answer you the same day. Would you rather talk? Just give us a call.
        </div>
        <br/>
        <Form.Group controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter subject"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
          />
        </Form.Group>
  
        <Form.Group controlId="body" className="py-4">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter message"
            value={body}
            onChange={(event) => setBody(event.target.value)}
          />
        </Form.Group>
  
        <Button type="submit">Send Email</Button>
      </Form>
    );
  };

  const ContactDetails = () => {
  
    return (
    <Stack gap={2} className={'d-flex flex-column justify-content-start pt-5 px-5'}>
        <Logo/>
        <small>© 2023 Qanda - All Rights Reserved</small>
        <Stack gap={3} direction="horizontal">
            <a href="https://join.slack.com/t/qandadesign/shared_invite/zt-1msw04j9y-8XdnOfI7ZqxtV32lurd4Qw" target="_blank"><BsSlack/></a>
            <a href="https://www.instagram.com/qanda.design" target="_blank"><BsInstagram/></a>
            <a href="https://medium.com/@JasperKense" target="_blank"><BsMedium/></a>
            <a href="https://twitter.com/JasperKense" target="_blank"><BsTwitter/></a>
        </Stack>
        <p><a href={'mailto: jasperkense@qanda.design'}>jasperkense@qanda.design</a></p>
        <h4 className="pt-3">Subscribe</h4>
        <EmailSignupForm/>
    </Stack>
    );
  };