import { useEffect, useState } from "react";
import { useTextEditor } from "../../../Contexts/TextEditorContext";
import TipTap from "./TipTap";
import {Link, useLocation} from "react-router-dom";
import { Mini } from "../../Typography";
import "./styles.scss";
import { BsFile, BsFolder, BsHouse, BsJournalText, BsPencil, BsThreeDots } from "react-icons/bs";
import { Button, Dropdown } from "react-bootstrap";
import { useFolder } from "../../../Hooks/useFolder";
import { useFileManager } from "../../../Contexts/FileStorageContext";
import { RenameFileModal } from "../../Modals/Modals";
import TipTapEditor from "./TipTapEditor";
import { ClientSideSuspense } from "@liveblocks/react";
import { RoomProvider } from "./liveblocks.config.ts";
import AvatarStack from "./AvatarStack/AvatarStack.js";
import { Avatar, EmptyAvatar } from "./Avatar/Avatar.js";
import { Status } from "./Status/Status.tsx";
import Breadcrumbs from "../../Navigation/Breadcrumbs/Breadcrumbs.js";

// For testing, only use in node backend
// TODO: test this in GCF https://liveblocks.io/docs/api-reference/liveblocks-node#Liveblocks-client
// import { Liveblocks } from "@liveblocks/node";

// const liveblocks = new Liveblocks({
//   secret: "sk_dev_13ngGfiQUGNRvINVOGlWpiCEyjxuH8plxCj7t_yYMG2VO4pzOGiqA-RF01g8JAdt",
// });

// The Editor component
export default ({ CurrentFile }) => {
    const location = useLocation();
    const { CurrentFolder } = useFolder(CurrentFile.folderId, location.folder);
    const { UpdateFile } = useFileManager();

    const [showRename, setShowRename] = useState(false);

    const renameModal = () => setShowRename(!showRename);

    if (!CurrentFile) return null; // Render nothing if text is not loaded
  
    const breadcrumbItems = [
      { text: 'Home', link: '/Projects', icon: <BsHouse/> },
      { text: CurrentFolder?.name, link: `/Projects/${CurrentFolder?.id}`, icon: <BsFolder /> },
      { text: CurrentFile?.name, icon: <BsJournalText /> },
  ];

    return(
      <>
        <RenameFileModal renameModal={renameModal} showRename={showRename} File={CurrentFile} UpdateFile={UpdateFile} CurrentFolder={CurrentFolder}/>

        <div className={"border-start-0 border-end-0 border-top-0 border-gray top-0 bg-white"}>
            {/* Stuff such as names, titles and buttons */}
            <div className="py-1 px-4 d-flex flex-row justify-content-between align-items-center">
              <div className={"h-100 d-flex flex-row align-items-center"} style={{minHeight: '48px'}}>
                <Breadcrumbs items={breadcrumbItems} />

                <Dropdown>
                    <Button variant={'white'} className="d-flex flex-row align-items-center" type="button" id="dropdown-fileEdit" data-toggle="dropdown"> 
                        <BsThreeDots/>
                    </Button>

                    <div className="dropdown-menu mt-1" aria-labelledby="dropdown-fileEdit" >
                      <Dropdown.Item className="d-flex flex-row align-items-center" onClick={renameModal}>
                        <BsPencil/><div>&ensp;Rename</div>
                      </Dropdown.Item>
                    </div>
                </Dropdown>
                
              </div>
              <RoomProvider id={CurrentFile?.id}>
                <ClientSideSuspense fallback={<EmptyAvatar />}>
                  {() => 
                  <div>
                    <AvatarStack/>
                    <Status/>
                  </div>}
                </ClientSideSuspense>
              </RoomProvider>
            </div>
        </div>
        <div className={"flex-grow-1 w-100 d-flex flex-column bg-light"}>
          <RoomProvider id={CurrentFile?.id}>
            <ClientSideSuspense fallback="Loading…">
                {() => <TipTapEditor/>}
            </ClientSideSuspense>
          </RoomProvider>
        </div>
        </>
      ) 
  };