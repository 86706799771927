import { useState, useEffect } from 'react';
import { database, app } from '../Services/Firebase'; // Assuming 'database' is your Firebase database instance
import { useAuth } from '../Contexts/AuthContext';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useMutation, useStorage } from '../Elements/Input/ClusterEditor/liveblocks.config.ts';
import { LiveObject } from '@liveblocks/client';

const useTagGroups = (folderId) => {
  const { currentUser } = useAuth();
  const clusters = useStorage((root) => root.clusters);

  const addLBTagGroup = useMutation(({ storage }, id) => {
    console.log("addCluster", id)
    storage.get("clusters").push(new LiveObject({ id }));
  }, []);


  const deleteLBTagGroup = useMutation(({ storage }, id) => {
      // Find index by id in clusters array
      const index = clusters.findIndex(obj => obj.id === id);
      const clusterStorage = storage.get("clusters");
      
      clusterStorage.delete(index);
  }, []);

  // const [tagGroups, setTagGroups] = useState([]);

  // useEffect(() => {
  //   if (!currentUser) {
  //     // User not authenticated, handle accordingly
  //     return;
  //   }

  //   let tagsCollection = database.folders.doc(folderId).collection('tagGroup');
    
  //   const unsubscribe = tagsCollection.onSnapshot((snapshot) => {
  //     const updatedTags = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  //     setTagGroups(updatedTags);
  //   });

  //   // Cleanup function to unsubscribe from the Firestore collection when the component unmounts
  //   return () => unsubscribe();
  // }, [currentUser, folderId, fileId]); // Updated dependency array to re-run the effect when currentUser or folderId changes

  const createTagGroup = async (tagGroupData = {}) => {
    // TODO: Generate random data color for tagData field
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return Promise.reject("User not authenticated");
      }
  
      const tagsCollection = database.folders.doc(folderId).collection('tagGroups');
  
      const newTagRef = tagsCollection.doc();
      const newTagId = newTagRef.id; // Get the document ID
  
      await newTagRef.set({
        userId: currentUser.uid,
        data: tagGroupData,
      });

      addLBTagGroup(newTagId)

      return newTagId; // Resolve with the created tag ID
    } catch (error) {
      console.error('Error creating tag group:', error);
      return Promise.reject(error);
    }
  };

  const deleteTagGroup = async (tagGroupId) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return;
      }

      deleteLBTagGroup(tagGroupId)

      const tagRef = database.folders.doc(folderId).collection('tagGroups').doc(tagGroupId);
      
      // Remove each tag where the tagGroupId is this one
      const tagQuery = database.folders.doc(folderId).collection('tags').where('tagGroupId', '==', tagGroupId);
      const tagSnapshot = await tagQuery.get();
      tagSnapshot.forEach((doc) => {
        // Set the field tagGroupId to null
        doc.ref.update({ tagGroupId: null }, { merge: true });
      });

      await tagRef.delete();
    } catch (error) {
      console.error('Error deleting tag group:', error);
    }
  };

  const getTagGroup = (tagGroupId, callback) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return null;
      }

      const tagGroupRef = database.folders
        .doc(folderId)
        .collection('tagGroups')
        .doc(tagGroupId);

      const unsubscribe = tagGroupRef.onSnapshot((doc) => {
        if (doc.exists) {
          const tagGroupData = doc.data();
          const tagGroup = { id: doc.id, ...tagGroupData };
          callback(tagGroup);
        } else {
          // Document does not exist
          callback(null);
        }
      });

      // Return the unsubscribe function in case you want to stop listening manually
      return unsubscribe;
    } catch (error) {
      console.error('Error while getting tag group:', error);
      return null;
    }
  };

  const updateTagGroup = async (tagGroupId, updatedData) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return Promise.reject('User not authenticated');
      }

      const tagRef = database.folders.doc(folderId).collection('tagGroups').doc(tagGroupId);

      await tagRef.update(updatedData, { merge: true });

      return tagGroupId;
    } catch (error) {
      console.error('Error updating tag:', error);
      return Promise.reject(error);
    }
  };

  return { createTagGroup, deleteTagGroup, getTagGroup, updateTagGroup };
};

export default useTagGroups;