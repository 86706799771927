import React from "react";
import Hero from "./Landing Page/Hero";
import InfoSection from "./Landing Page/InfoSection";
import CookieConsent from "react-cookie-consent";
import {Header} from "../Elements/Navigation/Navbar/Header";
import {Logo} from "../Elements/Logo";
import EmailSignupForm from "../Elements/EmailSignupForm";
import { BsSlack, BsMedium, BsInstagram, BsTwitter, BsStars } from "react-icons/bs";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { FeatureBanner } from "./Landing Page/Features";
import { Link } from "react-router-dom";
import { SignUp } from "../Elements/Auth/Authentication";
import "./LandingPage.scss";

function LandingPage() {
    return (
        <>
            <Header/>
            <Container className="hero-margin">
                <Row className="w-100 m-0 d-flex flex-row jusity-content-center align-items-center g-3">
                    <Col lg={6} className="order-2 order-md-1">
                        <div className="text-purple d-flex flex-row align-items-center">
                            <BsStars/>
                            &ensp;
                            <div>
                                Powered by GPT4
                            </div>
                        </div>
                        <div className="title">Get actionable insights with <b>instant analysis</b></div>
                        <div className="py-3 subtitle">Get quick UX research insights to take to your team. No synthesis required.</div>
                        <SignUp/>
                    </Col>
                    <Col lg={6} className="home-header_image-wrapper  order-1 order-md-2">
                        <div className="home-header_pill d-none d-lg-block">
                            <img className="w-75 object-fit-fill" src="../Images/Insight.png"/>
                        </div>
                        <img className="w-100 hero-image object-fit-fill" src="../Images/UXHero.png"/>
                    </Col>
                    </Row>
            </Container>
            <Container className="d-flex flex-row w-100 hero-margin text-center">
                <div className="title">Kickstart your UX research with <b>AI-powered insights</b></div>
            </Container>
            <FeatureBanner/>
            <InfoSection/>
            {/* <Waveform/> */}
            <Footer/>
            <CookieConsent
                location="bottom"
                buttonText="I agree"
                cookieName="cookie"
                style={{ background: "white", shadow:"0px 0px 1px 1px rgba(0,0,0,0.22)", color:"black", margin:"1% 1%", paddingLeft:"1em", width:"98%", borderRadius:"100px"}}
                buttonStyle={{ color: "white", fontSize: "1em", borderRadius:"100px", background:"#1E43FF"}}
                expires={150}
            >
                Qanda uses cookies to ensure you get the best experience on our website.
            </CookieConsent>
        </>
    );
}

export function Footer() {
    return (
        <Row className={'bg-light p-5 d-flex flex-row justify-content-between m-0'}>
            <Col md={4} className="mx-auto mb-auto mt-5">
                <Stack gap={2} className={'d-flex flex-column justify-content-start'}>
                    <Logo/>
                    <small>© 2023 Qanda - All Rights Reserved</small>
                    <Stack gap={3} direction="horizontal">
                        <a href="https://join.slack.com/t/qandadesign/shared_invite/zt-1msw04j9y-8XdnOfI7ZqxtV32lurd4Qw" target="_blank"><BsSlack/></a>
                        <a href="https://www.instagram.com/qanda.design/" target="_blank"><BsInstagram/></a>
                        <a href="https://medium.com/@JasperKense" target="_blank"><BsMedium/></a>
                        <a href="https://twitter.com/JasperKense" target="_blank"><BsTwitter/></a>
                    </Stack>
                    <p><a href={'mailto: jasperkense@qanda.design'}>jasperkense@qanda.design</a></p>
                </Stack>
            </Col>
            <Col md={4} className="mx-auto mb-auto mt-5">
                <h4>About Us</h4>
                <p>We empower designers to focus on their creative process.</p>
                <Stack gap={5} direction="horizontal">
                    <Link>Privacy policy</Link>
                    <Link>Terms of service</Link>
                    {/* <Link to={'/Pricing'}>Pricing</Link> */}
                </Stack>
            </Col>
            <Col md={4} className="mx-auto mb-auto mt-5">
                <h4>Subscribe</h4>
                <EmailSignupForm/>
            </Col>
        </Row>
    );
}

export default LandingPage;
