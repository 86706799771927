import { BsGripVertical, BsTrash } from "react-icons/bs";
import { Draggable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import { OverlayPortal } from "../Modals/Overlay";
import { Badge } from "react-bootstrap";
import ColorPicker from "../Input/ColorPicker";
import { collaborationColors } from "../../colors";
import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../Contexts/ToastProvider";
import useTags from "../../Hooks/useTags";
import _ from 'lodash';
import { EditableText } from "../Typography";
export const Tag = ({tag, index}) => {
    const { folderId, tagId } = useParams();
    const [showOverlay, setShowOverlay] = useState(tagId !== undefined && tagId === tag.id ? true : false);
    const [newTagData, setNewTagData] = useState(tag);
    const { updateTag, deleteTag } = useTags(folderId);
    const { Success, Error } = useToast();
    const navigate = useNavigate();

    // Update the tag data when the tag changes
    useEffect(() => {
        setShowOverlay(tagId !== undefined && tagId === tag.id ? true : false);
    }, [tagId]);

    // Save the overlay data
    const saveOverlay = (tagData) => {
        // If the tag is not the same as it was before, save it to the DB
        if (!_.isEqual(newTagData.tagData, tag.tagData)) {
            // Call the updateTag function
            updateTag(tag.id, newTagData)
            .then(() => {
                Success(`Tag successfully updated.`);
            })
            .catch((error) => {
                console.error('Error updating tag:', error);
                Error('Error while updating tag, please try again later');
            });
        }
    };

    return(
        <Draggable draggableId={tag.id} index={index}>
        {(provided, snapshot) => (
            <>
                <div className="border-gray border-1 bg-white rounded text-nowrap"
                style={{maxWidth: '100%'}}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                onClick={() => {
                    navigate('/Projects/' + folderId + '/tags/' + tag.id)
                    setShowOverlay(!showOverlay)
                }}>
                    <div className="d-flex flex-row justify-content-between align-items-start p-2" style={{borderLeft: `5px solid ${tag.tagData.color}`}}>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row gap-2 align-items-baseline">
                                <div>
                                {tag.tagData?.name && tag.tagData.name.length > 20
                                    ? `${tag.tagData.name.slice(0, 20)}...`
                                    : tag.tagData.name}
                                </div>
                                <b style={{color: tag.tagData.color}}>
                                    {tag.linkedIds.length}
                                </b>
                            </div>
                            <div className="text-muted small">
                                {tag.tagData?.description && tag.tagData.description.length > 26
                                    ? `${tag.tagData.description.slice(0, 26)}...`
                                    : tag.tagData.description}
                            </div>
                        </div>
                        <div className="text-muted">
                            <BsGripVertical size={'18px'} />
                        </div>
                    </div>
                </div>

                {/* Overlay */}
                <OverlayPortal 
                    show={showOverlay} 
                    onClose={() => {
                        setShowOverlay(false)
                        navigate('/Projects/' + folderId + '/tags')
                        // Call onSave before closing
                        saveOverlay();
                    }}>
                    <TagEditor tag={tag} onChange={setNewTagData} onDelete={() => deleteTag(tag.id)}/>
                </OverlayPortal>
            </>
        )}
        </Draggable>
    )
}

const TagEditor = ({ tag, onChange, onDelete }) => {
    const { folderId, tagId } = useParams();
    const [newTag, setNewTagData] = useState(tag);
    const [description, setDescription] = useState(tag.tagData.description || '');
  

    useEffect(() => {
      onChange({ ...newTag, tagData: { ...newTag.tagData, description } });
    }, [newTag, description]);
  
    return (
      <div className="p-3 h-100 w-100">
        <div className="d-flex flex-row justify-content-between w-100 align-items-center gap-2">
          <EditableText 
            editFunc={(e) => setNewTagData({ ...newTag, tagData: { ...newTag.tagData, name: e }})}
            placeholder={tag.tagData.name} 
            />

            <BsTrash role='button' className="text-primary" onClick={onDelete}/>

        </div>
        <br />
        <div className="d-flex flex-row gap-2 align-items-center">
          <div
            className="rounded"
            style={{ width: '24px', height: '24px', backgroundColor: newTag.tagData.color }}
          />
          <ColorPicker
            value={tag.tagData.color}
            onChange={(color) => {
              setNewTagData({ ...newTag, tagData: { ...newTag.tagData, color } });
            }}
            colors={collaborationColors}
          />
        </div>
  
        {/* Description Text Area */}
        <div className="my-3">
          <b htmlFor="description" className="form-label">
            Description
          </b>
          <textarea
            id="description"
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
  
        <div className="d-flex flex-row flex-grow-1 text-center justify-content-center align-items-center h-100">
          <b className="text-gray">
            Highlighted {tag.linkedIds.length} times
          </b>
        </div>
      </div>
    );
  };
