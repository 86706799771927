"use client";

import styles from "./Status.module.css";
import { useStatus } from "../liveblocks.config.ts";
import { LostConnectionToasts } from "./LostConnectionToasts.tsx";

export function Status() {
  const status = useStatus();
  LostConnectionToasts();

  return (
    <div className={styles.status} data-status={status}>
      <div className={styles.statusCircle} />
      <div className={styles.statusText}>{status}</div>
    </div>
  );
}