import { useState } from 'react';
import { useToast } from "../Contexts/ToastProvider";

const EmailSignupForm = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const { Error, Notify, Success, UpdateToast } =  useToast();

    const addContact = (email) => {
        return new Promise((resolve, reject) => {
            const listId = '08bfa39f-4f88-11ed-afd1-0607cdff6e39'
            const link = process.env.REACT_APP_NODE_API_URL+'addContact?email_address='+email+'&list_id='+listId;
            
            fetch(link, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "Access-Control-Allow-Headers" : "Content-Type",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
                }
            })
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Unable to subscribe. Please try again later.');
                    }
                    resolve('Thank you for subscribing!');
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const handleChange= (e) => {
        setEmail(e.target.value);
        // console.log('value is:', e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        UpdateToast(addContact(email), {loading:'Subscribing...', success: 'Check your email for an invite', error: 'Something went wrong'});
    };

    return (
        <>
            <form
                className="form-inline"
                onSubmit={handleSubmit}
                className="d-flex"
            >
                <div className="d-flex gap-3 flex-row">
                    <input
                        type="email"
                        name="EMAIL"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required={true}
                        placeholder="Email"
                        className="rounded-pill form-control"
                    />
                    <button
                        className="btn btn-primary rounded-pill"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Join'}
                    </button>
                </div>
                <input
                    type="hidden"
                    name="https://hotmail.us17.list-manage.com/subscribe/post?u=260feb88f830c327976e57855&id=46e420511d&f_id=001757e0f0"
                    tabIndex="-1"
                    value="true"
                />
            </form>
        </>
    );
};

export default EmailSignupForm;

