import { Node, mergeAttributes, Range } from "@tiptap/core";
import { Mark as PMMark } from "@tiptap/pm/model";
import TagComponent from "./TagComponent";
import { ReactNodeViewRenderer } from '@tiptap/react'

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tag: {
      /**
       * Set a tag (add)
       */
      setTag: (tagId: string) => ReturnType;
      /**
       * Unset a tag (remove)
       */
      unsetTag: (tagId: string) => ReturnType;
    };
  }
}

export interface MarkWithRange {
  mark: PMMark;
  range: Range;
}

export interface TagOptions {
  HTMLAttributes: Record<string, any>;
  onTagActivated: (tagId: string) => void;
}

export interface TagStorage {
  activeTagId: string | null;
}

export const Tag = Node.create<TagOptions, TagStorage>({
  name: "tag",
  // allows all marks
  marks: '_',
  group: "inline",
  inline: true,
  content: 'text*',
  whitespace: 'pre',

  addOptions() {
    return {
      HTMLAttributes: {},
      onTagActivated: () => {},
    };
  },

  addAttributes() {
    return {
      tagId: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute("tagId"),
        renderHTML: (attrs) => ({ "tagId": attrs.tagId }),
      },
      active: {
        default: false,
      },
    };
  },
    
  addNodeView() {
    return ReactNodeViewRenderer(TagComponent)
  },

  parseHTML() {
    return [{
      tag: 'tag',
      getAttrs: (el) =>
        !!(el as HTMLSpanElement).getAttribute("tagId")?.trim() &&
        null,
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), 0]
  },

  onSelectionUpdate() {
    // If the text is only one character, delete the node
    // if(this.editor.state.selection.$head.parent.content.content[0]?.text?.length === 1){
      // this.editor.commands.deleteSelection()
      // this.editor.commands.deleteNode('tag')
    // }

    // console.log(this.editor.state.selection)
    // Get the node view from the current selection

    // Check if the node view exists and if it is within the specific div
    // This works 
    // if (this.editor.state.selection.$anchor.parent.attrs.tagId) {
      // Add the 'active' class to the node view
      // this.editor.commands.updateAttributes('tag', { active: true })
    // }
    // this.editor.commands.updateAttributes('tag', { active: false })
    
    this.storage.activeTagId = this.editor.state.selection.$anchor.parent.attrs.tagId || null;
    this.options.onTagActivated(this.editor.state.selection.$anchor.parent.attrs.tagId);
  },

  addStorage() {
    return {
      activeTagId: null,
    };
  },

  addCommands() {
    return {
      setTag:
        (tagId) => ({ commands, editor }) => {
          if(!tagId) return
          // const node = commands.setNode(this.name, { tagId })
          const { view, state } = editor
          const { from, to } = editor.state.selection
          const text = state.doc.textBetween(from, to, '')

          const anchorPos = from;
          console.log(anchorPos)
          // const node = commands.toggleNode(editor.state.selection.$head.parent.type.name, this.name, { tagId })
          const node = // HTML with trim white space
          commands.insertContent(`<tag tagId=\"${tagId}\">${text}</tag>`, 
          {
            parseOptions: {
              preserveWhitespace: true,
            }
          })

          // Set the text selection to the specified range
          // editor.commands.deleteRange({ from: from + text.length, to: to + text.length})

          // const node = editor.schema.nodes.tag.create({
          //   tagId: tagId ?? ""
          // });
          // return commands.insertContent(node);
          console.log(node)

          return node
        },
        unsetTag: () => ({ tr, commands, editor }) => {
          const { $from, $to } = editor.state.selection;
          const range = $from.blockRange($to);
          console.log(range)
          if (range) {
            const { start, end } = range;
            const text = editor.state.doc.textBetween(start, end, '\n');
            const tagId = $from.parent.attrs.tagId;
            console.log(start, end)
            console.log(text)

            // Remove the node without removing the text
            tr.deleteRange(start, end);
            commands.insertContentAt(start, `${text}`, {
              updateSelection: true,
              parseOptions: {
                preserveWhitespace: 'full',
              }
            });
    
            // Dispatch the transaction
            editor.view.dispatch(tr);
    
            return true;
          }
          return false;
      },
    };
  },
});