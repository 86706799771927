import React from "react";
import {PricingCards} from "./PricingCards";
import { SettingsSidebar } from "../Elements/Navigation/Navbar/SideBar";

export default function Plans() {
    return (
        <SettingsSidebar>
            <PricingCards/>
        </SettingsSidebar>
    );
}