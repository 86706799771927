import { useState, useEffect } from 'react';
import { database, app } from '../Services/Firebase'; // Assuming 'database' is your Firebase database instance
import { useAuth } from '../Contexts/AuthContext';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useMutation } from '../Elements/Input/ClusterEditor/liveblocks.config.ts';

const useTags = (folderId, clusterId = null, fileId = null) => {
  const { currentUser } = useAuth();
  const [tags, setTags] = useState([]);

  // NOTE TO SELF:
  // This is not finished because it was not necessary for multiplayer experience
  // const addLBTag = useMutation(({ storage }, tagGroupId, tagId) => {
    // const tagGroup = storage.get("clusters").get('0');
    // console.log(tagGroup)
    // tagGroup?.set("tags", [{ id: tagId }]);
  // }, []);

  useEffect(() => {
    if (!currentUser) {
      // User not authenticated, handle accordingly
      return;
    }

    let tagsCollection = database.folders.doc(folderId).collection('tags');

    if (fileId) {
      // Add the fileId query only if fileId is provided
      tagsCollection = tagsCollection.where('fileId', '==', fileId);
    }

    // Add the fileId query only if fileId is provided
    if(clusterId !== 'all') tagsCollection = tagsCollection.where('tagGroupId', '==', clusterId);
    
    const unsubscribe = tagsCollection.onSnapshot((snapshot) => {
      const updatedTags = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTags(updatedTags);
    });

    // Cleanup function to unsubscribe from the Firestore collection when the component unmounts
    return () => unsubscribe();
  }, [currentUser, folderId, fileId, clusterId]); // Updated dependency array to re-run the effect when currentUser or folderId changes

  const createTag = async (linkedIds = [], tagData = {}, tagGroupId = null) => {
    // TODO: Generate random data color for tagData field
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return Promise.reject("User not authenticated");
      }
  
      const tagsCollection = database.folders.doc(folderId).collection('tags');
  
      const newTagRef = tagsCollection.doc();
  
      await newTagRef.set({
        userId: currentUser.uid,
        tagGroupId,
        linkedIds,
        tagData: {description: "", ...tagData},
      });
  
      return linkedIds; // Resolve with the created tag ID
    } catch (error) {
      console.error('Error creating tag:', error);
      return Promise.reject(error);
    }
  };

  const setTagGroupId = async (tagId, tagGroupId) => {
    try{
      const tagRef = database.folders.doc(folderId).collection('tags').doc(tagId);
      await tagRef.update({tagGroupId: tagGroupId}, { merge: true });
      // await addLBTag(tagGroupId, tagId);
      
      return tagId;
    } catch (error) {
      console.error('Error creating tag:', error);
      return Promise.reject(error);
    }
  }

  const addLinkedIdToTag = async (tagId, linkedId) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return Promise.reject('No user logged in');
      }

      const tagRef = database.folders.doc(folderId).collection('tags').doc(tagId);

      await tagRef.update({
        linkedIds: firebase.firestore.FieldValue.arrayUnion(linkedId)
      });

      return tagId;
    } catch (error) {
      console.error('Error adding linked id to tag:', error);
      return Promise.reject(error);
    }
  };

  const removeLinkedIdFromTag = async (tagId, linkedId) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return;
      }

      const tagRef = database.folders.doc(folderId).collection('tags').doc(tagId);

      await tagRef.update({
        linkedIds: firebase.firestore.FieldValue.arrayRemove(linkedId)
      });
    } catch (error) {
      console.error('Error adding linked id to tag:', error);
    }
  };

  const deleteTag = async (tagId) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return;
      }

      const tagRef = database.folders.doc(folderId).collection('tags').doc(tagId);

      await tagRef.delete();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const getTag = async (linkedId, fileId = null) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return null;
      }

      const querySnapshot = await database.folders
        .doc(folderId)
        .collection('tags')
        .where('linkedIds', 'array-contains', linkedId)
        .get();

      if (!querySnapshot.empty) {
        const tagData = querySnapshot.docs[0].data();
        return { id: querySnapshot.docs[0].id, ...tagData };
      }

      return null;
    } catch (error) {
      console.error('Error querying tag by linkedId:', error);
      return null;
    }
  };

  const updateTag = async (tagId, updatedData) => {
    try {
      if (!currentUser) {
        // User not authenticated, handle accordingly
        return Promise.reject('User not authenticated');
      }

      const tagRef = database.folders.doc(folderId).collection('tags').doc(tagId);

      await tagRef.update(updatedData, { merge: true });

      return tagId;
    } catch (error) {
      console.error('Error updating tag:', error);
      return Promise.reject(error);
    }
  };

  return {
    tags,
    createTag,
    addLinkedIdToTag,
    removeLinkedIdFromTag,
    deleteTag,
    getTag,
    setTagGroupId,
    updateTag, // Include the new updateTag function in the returned object
  };
}

export default useTags;