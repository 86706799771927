import {Spinner} from "react-bootstrap";
import React from "react";
import Lottie from 'react-lottie';
import * as animationData from './Loading/Logo_Loading.json'

export const Loading = (props) => {
    const { loading, animation } = props;

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        filterSize: {
          preserveAspectRatio: 'xMidYMid meet'
        }
      }
    };

    return (
      <>
        {loading ? <div className="pe-none vh-100 d-flex justify-content-center align-items-center"><Lottie options={defaultOptions} height={400}/></div> : <>{props.children}</>}
      </>
    );
};

export const LoadingSpinner = ({ animation }) => (
    <div className="d-flex align-items-center justify-content-center vh-100 w-100">
      {animation && (
        <Spinner animation={animation} variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );

export default Loading;
