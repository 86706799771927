import { useReducer, useEffect } from "react";
import { auth, storage, database } from "../Services/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
const ACTIONS = {
    SET_USER: "set-user",
    UPDATE_USER: "update-user",
};

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.SET_USER:
            return {
                User: payload.User
            };
        case ACTIONS.UPDATE_USER:
            return {
                ...state,
                User: payload.User,
            };
        default:
            return state;
    }
}

export function useUser(userId) {
    const [state, dispatch] = useReducer(reducer, {
        User: null
    });

    useEffect(() => {
        if (!userId) {
            dispatch({ type: ACTIONS.SET_USERS, payload: { User: null } });
            return;
        }

        // Use the 'db' reference to subscribe to changes for the user with the specified ID
        const unsubscribeUserSession = database.users.doc(userId)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    dispatch({ type: ACTIONS.UPDATE_USER, payload: { User: doc.data() } });
                } else {
                    // If the user with the specified ID doesn't exist, update the state accordingly
                    dispatch({ type: ACTIONS.SET_USER, payload: { User: null } });
                }
            }, (error) => {
                // Handle error if needed
                console.error("Error fetching user:", error);
            });

        return () => {
            unsubscribeUserSession();
        };
    }, [userId]);

    return state;
}

export const updateProfilePicture = (photoFile) => {
    return new Promise(async (resolve, reject) => {
      try {
        // Step 1: Upload the photo to Firebase Storage
        const userId = auth.currentUser.uid;
        const fileName = photoFile.name;
        const storageRef = ref(storage, `profile_pictures/${userId}/${fileName}`);
        const metadata = {
          userId: userId,
          fileName: fileName,
          createdAt: database.getCurrentTimestamp(), // Use a proper date format or Firestore Timestamp if required
        };
        const snapshot = await uploadBytes(storageRef, photoFile, metadata); // Pass the metadata as the second argument
        
        // Step 2: Get the download URL of the uploaded photo
        const downloadUrl = await getDownloadURL(snapshot.ref);
    
        // Step 3: Update the currentUser document with the download URL
        const userRef = database.users.doc(userId);
        await updateDoc(userRef, { photoURL: downloadUrl });
      
        // Step 4: Resolve the promise with the success message
        resolve('Profile picture updated successfully.');
      } catch (error) {
        console.error('Error updating user data:', error);
        reject('An error occurred while updating the profile picture.');
      }
    });
  };