import { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { database } from '../Services/Firebase';

// Listen to all folders associated with this user
const useFolders = (folderId = null) => {
    const [folders, setFolders] = useState([]);
    const [sharedFolders, setSharedFolders] = useState([]);
    
    // Return the folders and shared folders
    const [allFolders, setAllFolders] = useState([]);

    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            // User not authenticated, handle accordingly
            setFolders([]);
            setSharedFolders([]);
            return;
        }

        const ownedFoldersQuery = database.folders
            .where("parentId", "==", folderId)
            .where("userId", "==", currentUser.uid);

        const memberFoldersQuery = database.members
            .where('userId', "==", currentUser.uid);

        const ownedUnsubscribe = ownedFoldersQuery.onSnapshot(ownedSnapshot => {
            const ownedDocs = ownedSnapshot.docs.map(doc => database.formatDoc(doc));
            setFolders(ownedDocs);
        });

        const memberUnsubscribe = memberFoldersQuery.onSnapshot(memberSnapshot => {
            const memberRefs = memberSnapshot.docs.map(doc => doc.ref.parent);
            const memberParentDocsPromises = memberRefs.map(ref => ref.parent.get());

            Promise.all(memberParentDocsPromises)
                .then(memberParentDocs => {
                    const combinedDocs = memberParentDocs.concat(folders);
                    setSharedFolders(combinedDocs.map(doc => database.formatDoc(doc)));
                })
                .catch(error => {
                    console.error("Error fetching shared folders:", error);
                });
        });

        // Cleanup the listeners when the component unmounts
        return () => {
            ownedUnsubscribe();
            memberUnsubscribe();
        };

    }, [folderId, currentUser]);

    useEffect(() => {
        setAllFolders([...folders, ...sharedFolders]);
    }, [folders, sharedFolders]);

    return { folders, sharedFolders, allFolders };
};

export default useFolders;