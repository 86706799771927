import { useCurrentEditor } from "@tiptap/react"
import { useCallback, useEffect } from "react"
import { useState } from "react"
import { Button, Dropdown, InputGroup, Form, Modal, Stack } from "react-bootstrap"
import { BsBlockquoteRight, BsCardText, BsEraser, BsLightningCharge, BsLightbulb, BsCloudUpload, BsFillCloudUploadFill, BsLink45Deg, BsListOl, BsListUl, BsParagraph, BsThreeDots, BsTypeBold, BsTypeH1, BsTypeH2, BsTypeH3, BsTypeItalic, BsTypeStrikethrough, BsTypeUnderline, BsSoundwave, BsStars } from "react-icons/bs"
import { useToast } from "../../../Contexts/ToastProvider"
import { useLocation, useParams } from 'react-router-dom';
import { useFolder } from "../../../Hooks/useFolder"
import { File } from "../../UploadButton"
import { FileUploader } from 'react-drag-drop-files';
import { useFile } from "../../../Hooks/useFile"
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage"

export const MenuBar = ({editor}) => {
    const [lastSelected, setLastSelected] = useState({
      label: "Heading 1",
      icon: <BsTypeH1 />,
    });
    const [linkInput, setlinkInput] = useState('');
    const [fetching, setFetching] = useState(false);
    const { UpdateToast, Error } = useToast();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const toggleShowUploadModal = () => setShowUploadModal(!showUploadModal);

    const setLink = useCallback((e) => {
      e.preventDefault();
      const previousUrl = editor.getAttributes('link').href
      const url = linkInput;
      console.log(url)
  
      // cancelled
      if (url === null) {
        return
      }
  
      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink()
          .run()
  
        return
      }
  
      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run()
    }, [editor])
  
    if (!editor) {
      return null
    }
    
    const UpdateLastSelected = () => {
      if (editor?.isActive("paragraph")) {
        setLastSelected({ label: "Text", icon: <BsCardText /> });
      } else if (editor?.isActive("heading", { level: 1 })) {
        setLastSelected({ label: "Heading 1", icon: <BsTypeH1 /> });
      } else if (editor?.isActive("heading", { level: 2 })) {
        setLastSelected({ label: "Heading 2", icon: <BsTypeH2 /> });
      } else if (editor?.isActive("heading", { level: 3 })) {
        setLastSelected({ label: "Heading 3", icon: <BsTypeH3 /> });
      } else if (editor?.isActive("blockquote")) {
        setLastSelected({ label: "Quote", icon: <BsBlockquoteRight /> });
      }
    }

    editor.on("selectionUpdate", ({ editor }) => {
      UpdateLastSelected()
    });

    editor.on("update", ({ editor }) => {
      UpdateLastSelected()
    });

    const getSelection = () => {
      const { state } = editor;
      const { from, to } = state.selection;
      const text = state.doc.textBetween(from, to, ' ');
      return {text, from, to}
    }

    const largeSelection = (threshold = 100) => {
      const { text, from, to } = getSelection();
      return(text && text?.length > threshold)
    }

    const Summarize = () => {
      setFetching(true);
      const { text, from, to }= getSelection();
      
      if(text.length < 100){
        Error("Please select a longer text")
        return;
      }
      UpdateToast(fetchSummary(text, to), {loading: 'Summarizing', success: 'Summarization added', error: 'Error when summarizing'})
    };

    const Insight = () => {
      setFetching(true);
      const { text, from, to }= getSelection();

      if(text.length < 100){
        Error("Please select a longer text")
        return;
      }
      UpdateToast(fetchInsight(text, to), {loading: 'Analyzing', success: 'Insight added', error: 'Error when analyzing'})
    }

    const fetchSummary = (text, insertAt) => {
      return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + 'api/v1/summarizeText', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          text: text,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          setFetching(false);
          if (res.success) {
            editor.commands.insertContentAt(insertAt, `<blockquote>${res.result}</blockquote>`);
            resolve(res.result); // Resolve the promise with the result
          } else {
            reject(res.error); // Reject the promise with the error
          }
        })
        .catch((error) => {
          console.error(error);
          setFetching(false);
          reject(error); // Reject the promise if there is a network error
        });
    });
  }

  const fetchInsight = (text, insertAt) => {
    return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_API_URL + 'api/v1/textToInsight', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text: text,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res.success);
        setFetching(false);
        if (res.success) {
          editor.commands.setInsight(insertAt, res.result);
          resolve(res.result); // Resolve the promise with the result
        } else {
          reject(res.error); // Reject the promise with the error
        }
      })
      .catch((error) => {
        console.error(error);
        setFetching(false);
        reject(error); // Reject the promise if there is a network error
      });
  });
}
    return (
      <div className="d-flex flex-row justify-content-between align-items-center px-4 py-2 w-100 border-bottom border-1 shadow-sm bg-white" style={{minHeight: '56px'}}>
        <Stack direction={'horizontal'} gap={'2'}>
          
        <Dropdown>
            <Button variant={'white'} className="d-flex flex-row align-items-center" style={{minWidth: '140px'}} type="button" id="dropdown-basic" data-toggle="dropdown"> 
                <div className="d-flex flex-row align-items-center">
                  {lastSelected.icon} <div>&ensp; {lastSelected.label}</div>
                </div>
            </Button>

            <div className="dropdown-menu mt-1" aria-labelledby="dropdown-basic" >
                <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className="d-flex flex-row align-items-center">
                    <BsTypeH1/>
                    <div>
                        &ensp;Heading 1
                    </div>
                </Dropdown.Item>
                
                <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className="d-flex flex-row align-items-center">
                    <BsTypeH2/>
                    <div>
                        &ensp;Heading 2
                    </div>
                </Dropdown.Item>

                <Dropdown.Item onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className="d-flex flex-row align-items-center">
                    <BsTypeH3/>
                    <div>
                        &ensp;Heading 3
                    </div>
                </Dropdown.Item>

                <Dropdown.Divider/>

                <Dropdown.Item onClick={() => editor.chain().focus().setParagraph().run()} className="d-flex flex-row align-items-center">
                    <BsCardText/>
                    <div>
                        &ensp;Text
                    </div>
                </Dropdown.Item>

                <Dropdown.Item           
                  onClick={() => editor.chain().focus().toggleBlockquote().run()}
                  className={editor.isActive('blockquote') ? 'is-active d-flex flex-row align-items-center' : 'd-flex flex-row align-items-center'}>
                    <BsBlockquoteRight/>
                    <div>
                        &ensp;Quote
                    </div>
                </Dropdown.Item>
            </div>
        </Dropdown>
        <div className="h-100 border-gray"/>
        <Button
          variant={'white'}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'is-active' : ''}
          >
          <BsTypeBold/>
        </Button>
        <Button
            variant={'white'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <BsTypeItalic/>
        </Button>

        <Dropdown>
            <Button
            variant={'white'}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .toggleItalic()
                .run()
            }
            className={editor.isActive('italic') ? 'is-active' : ''}
            type="button" id="dropdown-link" data-toggle="dropdown" >
            <BsLink45Deg/>
          </Button>
          <div className="dropdown-menu mt-1 p-2" aria-labelledby="dropdown-link" style={{width: '300px'}}>
              <div className="d-flex flex-column gap-2"></div>
                <h6>Insert link</h6>
                <form onSubmit={setLink}>
                    <InputGroup>
                      <Form.Control
                          type="search"
                          placeholder="Link"
                          onChange={(e)=>setlinkInput(e.value)}
                          aria-label="Search"
                          style={{ borderColor: "rgb(222, 226, 230)" }} // set the border color to gray
                      />
                      <Button type="submit" className={'d-flex flex-row justify-content-center align-items-center'}>
                          <BsLink45Deg/>
                      </Button>
                  </InputGroup>
                </form>
            </div>
        </Dropdown>

        <div className="h-100 border-gray"/>
        
        <Button
            variant={'white'}
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <BsEraser/>
        </Button>
        <Dropdown className="h-100">
            <Button variant={'white'} className="d-flex flex-row align-items-center h-100" type="button" id="dropdown-basic" data-toggle="dropdown"> 
                <BsThreeDots/>
            </Button>

            <div className="dropdown-menu mt-1" aria-labelledby="dropdown-basic" >
              
            <Dropdown.Item
                className="d-flex flex-row align-items-center"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={
                    !editor.can()
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                }
            >
              <BsTypeStrikethrough/><div>&ensp;Strikethrough</div>
            </Dropdown.Item>
            <Dropdown.Item
                className="d-flex flex-row align-items-center"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={
                  !editor.can()
                  .chain()
                  .focus()
                  .toggleUnderline()
                  .run()
                }
            >
              <BsTypeUnderline/><div>&ensp;Underline</div>
            </Dropdown.Item>
            <Dropdown.Divider/>
                <Dropdown.Item className="d-flex flex-row align-items-center"
                              onClick={() => editor.chain().focus().toggleBulletList().run()}>
                  <BsListUl/>
                    <div>
                        &ensp;Bullet list
                    </div>
                </Dropdown.Item>
                
                <Dropdown.Item onClick={() => editor.chain().focus().toggleOrderedList().run()} className="d-flex flex-row align-items-center">
                    <BsListOl/>
                    <div>
                        &ensp;Numbered List
                    </div>
                </Dropdown.Item>
            </div>
        </Dropdown>
        <div className="h-100 border-gray"/>
        <Dropdown className="h-100 me-2">
            <Button variant={'secondary'} 
                    className={`d-flex flex-row align-items-center h-100`}
                    disabled={!largeSelection()}
                    type="button" id="dropdown-basic" data-toggle="dropdown"> 
                <BsStars/>
            </Button>

            <div className="dropdown-menu mt-1" aria-labelledby="dropdown-basic" >
              <Dropdown.Item
                  className="d-flex flex-row align-items-center"
                  onClick={Insight}
              >
                <BsLightbulb/>
                <div>&ensp; Insight</div>
              </Dropdown.Item>
              <Dropdown.Item
                  className="d-flex flex-row align-items-center"
                  onClick={Summarize}
              >
                <BsLightningCharge/>
                <div>&ensp; Summarize</div>
              </Dropdown.Item>
            </div>
        </Dropdown>
        </Stack>
        <Stack direction={'horizontal'} gap={'2'}>
            <Button className="d-flex flex-row align-items-center no-wrap h-100" onClick={toggleShowUploadModal}>
                <BsCloudUpload/>
                <div className="d-none d-lg-block">
                    &ensp;Upload audio
                </div>
            </Button>
            <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} size="md" aria-labelledby="contained-modal-title-vcenter"  centered>
              <AudioUploadModal setShow={setShowUploadModal} editor={editor}/>
            </Modal>
        </Stack>
      </div>
    )
  }
  
export const PopoverMenu = () => {
    const { editor } = useCurrentEditor()

    return(
      <Button
          variant={'white'}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={editor.isActive('bold') ? 'is-active' : ''}
          >
          <BsTypeBold/>
     </Button>
    )
}


const fileTypes = ["mp3", "wav"];

function AudioUploadModal (props) {
    // State to store uploaded file
    const [files, setFiles] = useState([]);
    const hasFiles = files.length > 0;
    // progress
    const [percent, setPercent] = useState([]);
    
    const { fileId } = useParams();
    const { location = {} } = useLocation();
    const { CurrentFile } = useFile( fileId )
    const { Error } = useToast();

    const editor = props.editor;
    if(!editor) return;
    console.log(files[0])

    const UploadFile = (files) => {
      const file = files[0];

      if( CurrentFile ){
        const storage = getStorage();
        const filePath = `/files/${CurrentFile.userId}/${CurrentFile.folderId}/${CurrentFile.id}/${file.name}`;
        const storageRef = ref(storage, filePath);
        const metadata = {
          type: file.type,
          fileId: CurrentFile.id
        };
        const uploadTask = uploadBytesResumable(storageRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on('state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPercent([progress])
          }, 
          (error) => {
            Error(error)
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(downloadURL)
              InsertAudio(downloadURL)

            });
          }
        );
      }
    }
    
    function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }

    const InsertAudio = (downloadURL) => {
      const { state } = editor;
      const { from, to } = state.selection;

      editor.commands.setAudio({ src: downloadURL, uid: uuidv4()});
    };

    function handleUpload(e) {
      e.preventDefault();
      UploadFile(files)
    }

    if (percent.length > 0 && percent.every(p => p === 100)) {
      props.setShow(false);
      setFiles([]);
      setPercent([]);
    }

    const onChange = (newFiles) => {
      setFiles([... [newFiles]]);
    };

    const removeFile = (index) => {
      let filteredArr = files.filter((file, num) => num !== index);
      setFiles(filteredArr);
    };

    return (
        <Form className={"p-4"}>
          <Modal.Title>Upload audio</Modal.Title>
          {!hasFiles ?
              <>
                  <div className="mt-3">
                      <FileUploader
                          multiple={false}
                          handleChange={(e) => onChange(e)}
                          name="file"
                          types={fileTypes}
                      >
                          <Stack gap={2} className={"p-4 file-upload w-100 rounded border-gray border-dotted d-flex justify-content-center text-center"}>
                              <input type="file" accept={fileTypes} onChange={(e) => onChange(e)}/>

                              <h1><BsFillCloudUploadFill/></h1>
                              <div>Drag and Drop audio here</div>
                              <div>or</div>
                              <b className={"text-primary"}>Browse audio files</b>
                          </Stack>
                      </FileUploader>
                  </div>
                  <div className={"pt-2 text-muted"}>Accepted file types: {fileTypes.map(item => `.${item}, `)}</div>
              </>
              :
              <div className={"mt-3"}>
                  {files.map((file, index) =>
                      <File
                        removeFile={removeFile}
                        file={file}
                        index={index}
                        key={index}
                        percentCompleted={percent[index]} // pass percent for the current file as prop
                      />                          
                  )}
              </div>
              }
          <div className={"pt-3"}>
            <Button
              variant={"light"}
              className={"w-100 rounded border border-gray border1 d-flex flex-row align-items-center justify-content-center"}
              onClick={handleUpload}
            >
              Add to document
            </Button>
          </div>
        </Form>
    )
}