import React, { useEffect, useState } from 'react';
import { NodeViewContent, NodeViewWrapper, useEditor } from '@tiptap/react';
import { SecondaryButton } from '../../../Buttons';
import { BsChevronDown, BsChevronUp, BsCloudUpload, BsFillCloudUploadFill, BsSoundwave, BsTrash } from 'react-icons/bs';
import "./Audio.scss";
import { Button, Form, Modal, ProgressBar, Spinner, Stack } from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";
import { database, functions } from '../../../../Services/Firebase';
import LanguageInput from '../../LanguageInput';
import SpeakerInput from '../../SpeakerInput';
import Toggle from 'react-toggle';
import { Link, useLocation, useParams } from 'react-router-dom';
import { collection, doc, getDoc } from "firebase/firestore";
import { useUser } from '../../../../Hooks/useUser';
import { useAuth } from '../../../../Contexts/AuthContext';
import { Mini } from '../../../Typography';
  
const AudioNode = ({ node }) => {
  const [openTranscript, setOpenTranscript] = useState(true);
  const toggleOpenTranscript = () => setOpenTranscript(!openTranscript);

  const [src, setSrc] = useState(null);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    // Fetch the src attribute when the component is mounted
    setSrc(node?.attrs?.src);
    setUid(node?.attrs?.uid);
  }, [node]);

  return (
    <NodeViewWrapper >
      <div className="d-flex flex-column border-gray rounded">
        <div className="d-flex flex-row justify-content-between align-items-center p-3 gap-3" contentEditable={false} >
        <audio src={src} controls preload="none" contentEditable={false} >
          Your browser does not support the audio element.
        </audio>
        <ActionButton src={src} uid={uid}/>
        </div>
        <hr className="m-0"  contentEditable={false} />
        <div className="bg-light py-3 px-4">
          <div className='d-flex flex-row justify-content-between aligg-items-center'  contentEditable={false} >
            <h5>Transcript</h5>
            <Chevron isOpen={openTranscript} onToggle={toggleOpenTranscript}/>
          </div>
          {openTranscript && (
            <div className="transcript" contentEditable suppressContentEditableWarning={true}>
              <NodeViewContent className="content small" />
            </div>
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};


const ActionButton = ({src, uid, onTranscribe}) => {
  const { currentUser, plan, isSubscribed } = useAuth();
  const { User } = useUser(currentUser?.uid);

  // Get File ID from params
  const { fileId } = useParams();
  const location = useLocation();

  const [showTranscribe, setShowTranscribe] = useState(false);
  const [language, setLanguage] = useState('en-US');
  const [status, setStatus] = useState('LOADING');

  const openTranscribeModal = () => {
    setShowTranscribe(true);
  };

  const closeTranscribeModal = () => {
    setShowTranscribe(false);
  };

  const transcribeModal = () => {
    setShowTranscribe(!showTranscribe);
  }

  // Create snapshot listener for subcollection 'Transcriptions' of file
  useEffect(() => {
    if(!uid) return;
    // Create a reference to the 'Transcriptions' subcollection
    // Create a snapshot listener
    const unsubscribe = database.files
      .doc(fileId)
      .collection('Transcriptions').doc(uid)
      .onSnapshot(async doc => {
        setStatus(doc.data()?.status)
      });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [fileId, uid]);

  // Cloud Function
  const OnTranscribe = async (e) => {
    e.preventDefault()
    if (!src) return;

    const transcribe_audio = httpsCallable(functions, 'transcribe_audio');
    transcribe_audio({ audioUrl: src, audioId: uid, fileId: fileId, languageCode: language, userId: currentUser.uid})
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const response = result.data;
        onTranscribe(response)
      })
      .catch((error) => {
      });
    transcribeModal();
  };

  // OnDelete
  const onDelete = async (e) => {
    e.preventDefault()
    database.files
      .doc(fileId)
      .collection('Transcriptions').doc(uid).delete()
  }

  const used_transcription_minutes = () => {
    return User?.usage_data?.used_transcription_minutes ? User?.usage_data?.used_transcription_minutes : 0
  }
  const transcriptionHours = (maxHours, minutesUsed) =>{
    return ((maxHours * 60) - minutesUsed)
  }

  if(src && status === 'PROCESSING') return(
    <Spinner variant={"primary"} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  )
  if(src && status === 'DONE') return(
    <div contentEditable={false} >
      {/* TODO: Implement deletion logic */}
      <SecondaryButton onClick={(e) => onDelete(e)}>
        <BsTrash />
        <div className="d-none d-sm-block">
          &ensp;Delete
        </div>
      </SecondaryButton>
    </div>
  )
  if(src && !status || status === 'FAILED') return(
    <>
      <SecondaryButton onClick={openTranscribeModal} className="d-flex flex-row align-items-center text-nowrap h-100">
        <BsSoundwave />
        <div className="d-none d-sm-block">
          &ensp;Transcribe
        </div>
      </SecondaryButton>
      <Modal show={showTranscribe} onHide={transcribeModal} size="md" centered>
            <Form className={"p-4"} onSubmit={OnTranscribe}>
                <Form.Group>
                    <h6>Transcribe</h6>
                    <br/>
                    <Stack gap={2} >
                        <b>Spoken language</b>
                        <LanguageInput setLang={setLanguage}>lang</LanguageInput>
                    </Stack>
                    <br/>
                    <div>
                    <Stack gap={2} >
                      <b>Transcription minutes</b>
                      <div className="d-flex align-items-center justify-content-between gap-2">
                        <Mini>{transcriptionHours(plan?.metadata.transcription_hours, used_transcription_minutes())} minutes remaining</Mini>
                        <Mini><Link to={"/Plans"}>Get more minutes</Link></Mini>
                      </div>
                      {/* TODO: Fix wrong progress bar */}
                      <ProgressBar variant="primary" style={{ width: '100%', height: '10px' }} now={transcriptionHours(plan?.metadata.transcription_hours, used_transcription_minutes())/(plan?.metadata.transcription_hours * 60)*100} />
                    </Stack>
                    <br />
                    </div>
                    <div className={"d-flex justify-content-end w-100"}>
                        {/* If transcriptionHours(plan?.metadata.transcription_hours, User?.usage_data?.used_transcription_minutes) > 0 make button active */}
                        <Button size={'sm'} variant={"primary"} type="submit" className="d-flex flex-row align-items-center text-nowrap h-100" disabled={transcriptionHours(plan?.metadata.transcription_hours, User?.usage_data?.used_transcription_minutes) <= 0}>
                          <BsSoundwave />
                          <div className="d-none d-sm-block">
                            &ensp; Start Transcription
                          </div>
                        </Button>
                    </div>
                </Form.Group>
            </Form>
        </Modal>
    </>

  )
}

const Chevron = ({isOpen, onToggle}) => {

  const onClick = (e) => {
    e.preventDefault();
    onToggle();
  }

  return(
    <>
      {
        isOpen?
        <Button variant={'light'} onClick={onClick}>
          <BsChevronDown />
        </Button>
        :
        <Button variant={'light'} onClick={onClick}>
          <BsChevronUp />
        </Button>
      }
    </>
  )
}

export default AudioNode;
