import React, { useContext } from "react";
import { storage } from "../Services/Firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuth } from "./AuthContext";
import { useToast } from "./ToastProvider";
import { useFile } from "../Hooks/useFile";
import { useParams } from "react-router-dom";
import { database } from "../Services/Firebase";

const TextEditorContext = React.createContext();

export function useTextEditor() {
  return useContext(TextEditorContext);
}

export function TextEditorProvider({ children }) {
    const { currentUser } = useAuth();

    const LoadText = (file) => {
        return new Promise((resolve, reject) => {
          if (file == null || !file.url) {
            reject("file or url is missing");
            return;
          }
      
          const url = file.url;
      
          fetch(url)
            .then((response) => {
              if (!response.ok) {
                reject("Failed to fetch text data");
                return;
              }
              return response.text();
            })
            .then((textData) => {
              // You can now use 'textData' which contains the loaded text
              resolve(textData);
            })
            .catch((error) => {
              reject("Failed to retrieve text data");
            });
        });
    };

    const UpdateText = (file, htmlData) => {
        return new Promise((resolve, reject) => {
        if (htmlData == null) {
            reject("No data received");
            return;
        }

        const CurrentFolderId = file.folderId;
        if (CurrentFolderId == null || file == null) {
            reject("CurrentFolderId or file is null");
            return;
        }

        const filePath = `/files/${currentUser.uid}/${CurrentFolderId}/${file.id}/${file.id}.html`;
        // console.log(filePath)
        const fileData = new Blob([htmlData], { type: "text/html" });
        const storageRef = ref(storage, filePath);

        const uploadTask = uploadBytesResumable(storageRef, fileData);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log(`Upload progress: ${progress}%`);
            },
            () => {
              reject("Upload failed");
            },
            () => {
            getDownloadURL(uploadTask.snapshot.ref)
                .then((url) => {
                // Update the file data in the database with the download URL and timestamp
                // Replace 'database' with your actual database reference
                // Assuming you have a 'files' collection and 'update' method
                // Make sure to import the necessary database functions
                database.files.doc(file.id).update({
                    lastEdited: database.getCurrentTimestamp(), // You may need to adjust this to your timestamp format
                    url: url,
                })
                .then(() => {
                  resolve("File saved successfully");
                })
                .catch((error) => {
                  reject("Failed to update file data in the database");
                });
                })
                .catch((error) => {
                  reject("Failed to get download URL");
                });
            }
        );
        });
    };

    const value = {
        LoadText,
        UpdateText,
    };

    return (
        <TextEditorContext.Provider value={value}>
        {children}
        </TextEditorContext.Provider>
    );
}
