import React, { forwardRef, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGuide } from "../Hooks/useGuide";
import { ProjectSidebar } from "../Elements/Navigation/Navbar/SideBar";
import { EditableText } from "../Elements/Typography";
import { Placeholder } from "react-bootstrap";
import { AvatarButton } from "../Elements/Auth/Authentication";
import { ROOT_FOLDER, useFolder } from "../Hooks/useFolder";
import Loading from "../Elements/LoadingSpinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './GuideView.scss';
import { BsGripVertical } from "react-icons/bs";

export default function GuideView() {
    const [loading, setLoading] =  useState(true);
    const [folderId, setFolderId] =  useState(null);

    const { guideId } = useParams();
    const location = useLocation();

    const { Guide } = useGuide(null, guideId);
    // console.log(Guide)

    const { CurrentFolder } = useFolder(folderId, null, setLoading)

    useEffect(() => {
        setFolderId(Guide ? Guide.folderId : null);
    }, [Guide, folderId]);

    if(Guide && CurrentFolder)

    return(
        <ProjectSidebar folderId={folderId}>
            <Loading loading={loading}>
                <div className={"w-100 vh-100 bg-white d-flex flex-column"}>
                    <div className="d-flex flex-row justify-content-end align-items-center border-bottom border-1 border-gray p-2 ">
                        <div className={"w-100"}>
                            {/* Search bar for whole folder */}
                            {/* <SearchInsightsPopup/> */}
                        </div>
                        <AvatarButton className="px-2"/>
                    </div>
                    <div className={"border-bottom border-1 border-gray position-sticky top-0 bg-white"}>
                        {/* Stuff such as names, titles and buttons */}
                        <div className="w-100 h-100">
                        <div className={"d-flex flex-row justify-content-between py-1 px-3 w-100"}>
                            <div className={"d-flex flex-column w-100 p-3"}>
                            {
                                CurrentFolder && CurrentFolder !== null && CurrentFolder !== ROOT_FOLDER? 
                                <>
                                    {/* <Link
                                        to={{
                                            pathname: `/Projects`
                                        }}
                                        state={{ CurrentFolder: null }}>
                                    <div className={"d-flex align-items-center"}><BsArrowLeftShort/>&ensp;Back</div>
                                    </Link> */}
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <EditableText 
                                        editFunc={(e) => console.log(CurrentFolder, {name: e})}
                                        placeholder={Guide.name} 
                                        className={'px-5'}
                                        />
                                        {/* <SecondaryButton onClick={CreateGuide}><BsJournalPlus/>&nbsp; Create Guide</SecondaryButton> */}
                                    </div>
                                </>:
                                <>
                                    <Placeholder xs={12} size="lg" />
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"w-100 flex-grow-1 bg-light overflow-auto"}>
                        <div className="w-100 h-100 p-2">
                            <GuideEditor guide={Guide}/>
                        </div>
                    </div>
                </div>
            </Loading>
        </ProjectSidebar>
    )
}


const GuideEditor = ({ guide }) => {
    const { updateGuideContent } = useGuide();
    const [editedGuide, setEditedGuide] = useState(guide);
    const [contentList, setContentList] = useState(editedGuide.content);
    const [autosaveTimeout, setAutosaveTimeout] = useState(null);
    
    useEffect(() => {
        if (guide.id !== editedGuide.id) {
            console.log("saving")
          // Only update if the guide has changed
          updateGuideContent(guide.id, contentList);
        }
    }, [guide, contentList])
  
    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...contentList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setContentList(updatedList);
    };
  
    const AddQuestion = () => {
      const newQuestion = {
        question: "New question...",
        indent: 0,
        metadata: {
          questionType: "",
          difficulty: "",
          tags: [],
        },
      };
  
      const updatedList = [...contentList, newQuestion];
      setContentList(updatedList);
    };
  
    const UpdateQuestion = (index, data) => {
      // Create a copy of the current content list
      const updatedContentList = [...contentList];
  
      // Update the question at the specified index with the provided data
      updatedContentList[index] = {
        question: data.question || "New question...",
        indent: data.indent || updatedContentList[index].indent,
        metadata: data.metadata || updatedContentList[index].metadata,
      };
  
      // Update the state with the updated content list
      setContentList(updatedContentList);
    };
  
    return (
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="p-0 m-0"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {contentList.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <DraggableQuestionItem
                        key={index}
                        item={contentList[index]}
                        provided={provided} // Pass the Draggable provided object as a prop
                        // Pass the updateIndent function to DraggableQuestionItem
                        updateIndent={(newIndent) => {
                            const updatedList = [...contentList];
                            updatedList[index].indent = newIndent;
                            setContentList(updatedList);
                        }}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <AddQuestionButton/>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const DraggableQuestionItem = forwardRef((props, ref) => {
    const { item, provided, updateIndent } = props; // Destructure the props
    const iconSize = 16;

    const [currentIndent, setCurrentIndent] = useState(item.indent);
    const [dragStartX, setDragStartX] = useState(null);

    // Add event listeners when dragging starts
    const handleDragStart = (e) => {
        setDragStartX(e.clientX);
    };

  // Remove event listeners and update indent when dragging ends
  const handleDragEnd = (e) => {
    if (dragStartX !== null) {
      const currentX = dragStartX + item.indent * 32; // Calculate the initial X position
      const deltaX = e.clientX - currentX;

      // Calculate the number of indents based on deltaX (assuming 1em = 16px)
      const indents = Math.floor(deltaX / 32); // You may adjust this value

      // Calculate the new indent, ensuring it's between 0 and 2
      const newIndent = Math.min(2, Math.max(0, item.indent + indents));

      // Update the indent only if it changed
      if (newIndent !== item.indent) {
        updateIndent(newIndent);
      }

      setDragStartX(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleDragEnd);

    return () => {
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragStartX]);
  
    return (
      <div
        ref={provided.innerRef}
        className={`indent-${item.indent} bg-white p-2 mb-2 border-1 border-gray rounded d-flex flex-row justify-content-between align-items-center`}
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        onMouseDown={handleDragStart}
      >
        <div className="d-flex flex-row align-items-center">
          <BsGripVertical size={iconSize} />
          &emsp;
          <div>{item.question}</div>
        </div>
      </div>
    );
});
  
// TODO: Add question, remove question, change question and autosave
const AddQuestionButton = forwardRef((props, ref) => {
    return (
      <div
        className={`bg-white p-2 mb-2 border-1 border-gray rounded d-flex flex-row justify-content-between align-items-center`}
      >
        <div className="d-flex flex-row align-items-center text-gray">
          &emsp;
          <input placeholder="Add question"></input>
        </div>
      </div>
    );
});
  